import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { WebUrl } from "../../util/Constant";
import { useSelector } from 'react-redux';

const TnC = (props) => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    return (
        <>
            <Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('TNC')} />
            <div className={"tnc-container" + (activeTheme === 0 ? " classic" : " modern")}>
                <img className="tnc-logo" src={require('../../assets/img/icon/i8-logo.png')}/>
                <div className="tnc-top-text" style={{ fontSize: "20px" }}>{t("TERMS_OF_USE")}</div>
                <div className="tnc-content">
                    <div className="tnc-content-header">
                        <div>{t("TERMS_OF_USE_1")}</div>
                        <div>{t("TERMS_OF_USE_2")}</div>
                    </div>
                    <hr />
                    <div className="tnc-content-body">
                        <label htmlFor="tnc-general" className="tnc-label" style={{ marginTop: "0" }}>{t("GENERAL")}</label>
                        <div id="tnc-general">
                            <div className="tnc-list"> 1.<div className="col-12">{t("GENERAL_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("GENERAL_2")}</div></div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-memberAcc">{t("MEMBER_ACCOUNT")}</label>
                        <div id="tnc-memberAcc">
                            <div className="tnc-list"> 1.<div className="col-12">{t("MEMBER_ACCOUNT_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("MEMBER_ACCOUNT_2")}</div></div>
                            <div className="tnc-list"> 3.<div className="col-12">{t("MEMBER_ACCOUNT_3")}</div></div>
                            <div className="tnc-list"> 4.<div className="col-12">{t("MEMBER_ACCOUNT_4")}</div></div>
                            <div className="tnc-list"> 5.<div className="col-12">{t("MEMBER_ACCOUNT_5")}</div></div>
                            <div className="tnc-list"> 6.<div className="col-12">{t("MEMBER_ACCOUNT_6")}</div></div>
                            <div className="tnc-list"> 7.<div className="col-12">{t("MEMBER_ACCOUNT_7")}</div></div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-depositNwithdrawal">{t("DEPOSIT_AND_WITHDRAWAL")}</label>
                        <div id="tnc-depositNwithdrawal">
                            <div className="tnc-list"> 1.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_2")}</div></div>
                            <div className="tnc-list"> 3.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_3")}</div></div>
                            <div className="tnc-list"> 4.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_4")}</div></div>
                            <div className="tnc-list"> 5.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_5")}</div></div>
                            <div className="tnc-list"> 6.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_6")}</div></div>
                            <div className="tnc-list"> 7.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_7")}</div></div>
                            <div className="tnc-list"> 8.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_8")}</div></div>
                            <div className="tnc-list"> 9.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_9")}</div></div>
                            <div className="tnc-list">10.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_10")}</div></div>
                            <div className="tnc-list">11.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_11")}</div></div>
                            <div className="tnc-list">12.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_12")}</div></div>
                            <div className="tnc-list">13.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_13")}</div></div>
                            <div className="tnc-list">14.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_14")}</div></div>
                            <div className="tnc-bullets-wrapper">
                                <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_1")}</div>
                                <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_2")}</div>
                                <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_3")}</div>
                                <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_4")}</div>
                                <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_5")}</div>
                            </div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-bettingTransactions" style={{ fontSize: "13px" }}>{t("MANAGEMENT_OF_BETTING_TRANSACTIONS")}</label>
                        <div id="tnc-bettingTransactions">
                            <div className="tnc-list"> 1.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_2")}</div></div>
                            <div className="tnc-list"> 3.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_3")}</div></div>
                            <div className="tnc-list"> 4.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_4")}</div></div>
                            <div className="tnc-list"> 5.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_5")}</div></div>
                            <div className="tnc-list"> 6.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_6")}</div></div>
                            <div className="tnc-list"> 7.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_7")}</div></div>
                            <div className="tnc-list"> 8.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_8")}</div></div>
                            <div className="tnc-list"> 9.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_9")}</div></div>
                            <div className="tnc-list">10.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_10")}</div></div>
                            <div className="tnc-list">11.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_11")}</div></div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-collusion">{t("COLLUSION_AND_ABUSE_ACTIVITIES")}</label>
                        <div id="tnc-collusion">
                            <div className="tnc-list"> 1.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_2")}</div></div>
                            <div className="tnc-list"> 3.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_3")}</div></div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-interruptions" style={{ fontSize: "13px" }}>{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE")}</label>
                        <div id="tnc-interruptions">
                            <div className="tnc-list"> 1.<div className="col-12">{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_2")}</div></div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-administrations">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE")}</label>
                        <div id="tnc-administrations">
                            <div className="tnc-list"> 1.<div className="col-12">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_1")}</div></div>
                            <div className="tnc-list"> 2.<div className="col-12">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_2")}</div></div>
                        </div>
                        <label className="tnc-label" htmlFor="tnc-customerService">{t("CUSTOMER_SERVICE")}</label>
                        <div id="tnc-customerService">
                            <div className="tnc-list"> 1.<div className="col-12">{t("CUSTOMER_SERVICE_1")}</div></div>
                        </div>
                        <div>
                            <div className="tnc-list" style={{ marginTop: "20px" }}><div>{t("ADDITIONAL_TNC_1")}</div></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
}

export default TnC;