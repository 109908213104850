import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from "react-redux";

const DateRangeFilter = (props) => {
    let _history = useHistory();
    let activeTheme = useSelector(state => state.authState.userData.selectedTheme);
    const { t, i18n } = useTranslation();

    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();
    const [selectedOption, setSelectedOption] = useState({});
    const [openCalendar, setOpenCalendar] = useState(false);
    const [optionClicked, setOptionClicked] = useState(false);
    var _dateRef = useRef(null);

    const {
        startDate = sDate,
        endDate = eDate,
        setStartDate,
        setEndDate,
        onStartDateChange,
        onEndDateChange,
        onSave,
        options = false,
        ...rest
    } = props;

    const _PREV_DAY = "PREV";
    const _NEXT_DAY = "NEXT";

    const _DATE_SELECTIONS = [
        {
            label: t("PREV_DAY"),
            from: _PREV_DAY,
        },
        {
            label: t("TODAY"),
            from: moment(),
        },
        {
            label: t("YESTERDAY"),
            from: moment().subtract(1, 'day'),
            to: moment().subtract(1, 'day')
        },
        {
            label: t("THIS_WEEK"),
            from: moment().startOf('isoWeek'),
        },
        {
            label: t("LAST_WEEK"),
            from: moment().subtract(1, 'week').startOf('isoWeek'),
            to: moment().subtract(1, 'week').endOf('isoWeek')
        },
        {
            label: t("THIS_MONTH"),
            from: moment().startOf('month'),
        },
        {
            label: t("LAST_MONTH"),
            from: moment().startOf('month').subtract(1, 'month'),
            to: moment().startOf('month').subtract(1, 'month').endOf('month')
        },
        {
            label: t("NEXT_DAY"),
            from: _NEXT_DAY
        },
    ];

    useEffect(() => {
        let startDateMoment;
        let endDateMoment;
        if (selectedOption.from == _PREV_DAY) {
            startDateMoment = moment(startDate).subtract(1, 'day');
            endDateMoment = moment(startDate).subtract(1, 'day');
        } else if (selectedOption.from == _NEXT_DAY) {
            startDateMoment = moment(startDate).add(1, 'day');
            endDateMoment = moment(startDate).add(1, 'day');
        } else {
            startDateMoment = selectedOption.from ? selectedOption.from : moment();
            endDateMoment = selectedOption.to ? selectedOption.to : moment();
        }

        if (startDate === undefined || optionClicked) {
            setSDate(startDateMoment);
            if (typeof (setStartDate) !== "undefined") {
                props.setStartDate(startDateMoment);
            }
        }
        else
            setSDate(startDate);
        if (endDate === undefined || optionClicked) {
            setEDate(endDateMoment);
            if (typeof (setEndDate) !== "undefined") {
                props.setEndDate(endDateMoment);
            }
        }
        else
            setEDate(endDate);

        setOptionClicked(false);
    }, [selectedOption]);

    useEffect(() => {
        if (openCalendar) {
            _dateRef.current.openCalendar();
        }
    }, [openCalendar])

    function handleStartDate(date) {
        setSDate(date);
        props.setStartDate(date);
        setOpenCalendar(true);
        if (onStartDateChange) {
            onStartDateChange(date);
        }
    }

    function handleEndDate(date) {
        setEDate(date);
        props.setEndDate(date);
        if (onEndDateChange) {
            onEndDateChange(date);
        }
    }

    function handleSave() {
        if (onSave) {
            onSave(sDate, eDate);
        }
    }

    return (
        <div id={activeTheme === 0 ? "date-range-filter" : "date-range-filter-modern"}>
            <div className="filter-grid-wrapper" style={{ "marginBottom": "0.5em" }}>
                <DateTime
                    inputProps={{ className: 'form-control leftAlignPicker', readOnly: true }}
                    name="startDate"
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect={true}
                    value={sDate}
                    timeFormat={false}
                    onChange={handleStartDate} />
                <span className="text">{t("TO")}</span>
                <DateTime
                    inputProps={{ className: 'form-control', readOnly: true }}
                    name="endDate"
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect={true}
                    timeFormat={false}
                    value={eDate}
                    ref={_dateRef}
                    onChange={handleEndDate} />
                <button className="btn btn-brand text-nowrap" onClick={() => { handleSave() }}>{t("SEARCH")}</button>
            </div>

            {
                props.options &&
                <div className="date-selection-wrapper">
                    {
                        _DATE_SELECTIONS.map((selection) => {
                            return <div key={selection.label}
                                onClick={() => { setOptionClicked(true); setSelectedOption(selection) }}
                                className={classNames("selection-item", { active: (selection.from !== _PREV_DAY && selection.from !== _NEXT_DAY && selectedOption.label == selection.label) })}
                            >
                                {selection.label}
                            </div>
                        })
                    }
                </div>
            }
        </div>
    );
};

export default DateRangeFilter;