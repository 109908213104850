import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl, TransactionType, BankingChannel, PanelType, _MAIN_WALLET_ID } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, createFormBody, numberWithCurrencyFormat } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import 'react-owl-carousel2/src/owl.carousel.css';
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import moment from "moment";
import Numpad from "../../components/custom/Numpad";

/// <summary>
/// Author: -
/// </summary>
const Transfer = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    let _userData = useSelector(state => state.authState.userData);
    let _dispatch = useDispatch();
    const { handleSubmit, register, control, watch, getValues, setValue, errors, reset } = useForm();
    const [walletList, setWalletList] = useState([]);
    const [fromOptions, setFromOptions] = useState([]);
    const [selectedFromOptions, setSelectedFromOptions] = useState("");
    const [toOptions, setToOptions] = useState([]);
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [isTransferSuccess, setIsTransferSuccess] = useState(false);
    const [paidAmount, setPaidAmount] = useState();
    const [deductedAmount, setDeductedAmount] = useState();
    const [transferDate, setTransferDate] = useState();
    const [currentInput, setCurrentInput] = useState();
    const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] = useState(false);
    const [showTaxWarning, setShowTaxWarning] = useState(false);
    const [bonus, setBonus] = useState(0);
    

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, [])

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {

        let responseJson = await ApiEngine.get(ApiUrl._API_GET_FLAG_TRANSFER_BACK_MAIN_WALLET);
        if (responseJson[ApiKey._API_DATA_KEY] == 1) {
            setIsTransferBackToMainWallet(true);
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                    let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId, bonus: product.bonus };
                    tempFromOptions.push(option);
                    //if (product.productId !== selectedFromOptions) {
                    //    tempToOptions.push(option);
                    //}
                })

                setFromOptions(tempFromOptions);
            }
        }
        else {
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];
                let tempToOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                    let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                    if (product.category == "Lottery") {
                        tempToOptions.push(option);
                        console.log("to");
                        console.log(option);
                    } else {
                        tempFromOptions.push(option);
                        console.log("from");
                        console.log(option);
                    }
                })

                /*
                 foreach (var product in responseJson[ApiKey._API_DATA_KEY])
                 {
                 //code
                 }
                 */

                setFromOptions(tempFromOptions);
                setToOptions(tempToOptions);
            }
        }

        _dispatch(checkIsLoggedIn());
    }

    /// <summary>
    /// Author : -
    /// </summary>
    function resetFormField() {
        setValue("fromWallet", null);
        setValue("toWallet", null);
        setCurrentInput();
    }

    
    function setTransfertoToption(option) {
        let tempToOptions = [];
        if (isTransferBackToMainWallet) {
            if (option == 0) {
                walletList.map((product, index) => {
                    if (product.productId !== option) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                        tempToOptions.push(option);
                    }
                });
            }
            else {
                walletList.map((product, index) => {
                    if (product.productId == 0) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                        tempToOptions.push(option);
                    }
                });
            }
        }
        else {
            setShowTaxWarning(false);
            console.log(option);
            if (option == 0) {
                walletList.map((product, index) => {
                    if (product.productId !== option) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                        tempToOptions.push(option);
                    }
                });
            }
            else
            {
                walletList.map((product, index) => {
                    if (product.productId == 0) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                        tempToOptions.push(option);
                    }
                });
            }
        }
        setToOptions(tempToOptions);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function onSubmit(data, e) {
        _dispatch(setBusy());
        let params = {
            "fromWallet": data.fromWallet,
            "toWallet": data.toWallet,
            "amount": data.amount
        }

        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await init();

            setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
            setDeductedAmount(responseJson[ApiKey._API_DATA_KEY].deductedAmount);
            setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
            setIsTransferSuccess(true);
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);
           
            e.target.reset();
            resetFormField();
        } else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        _dispatch(setIdle());
    }

    return (
        <div id={_userData.selectedTheme === 0 ? "transfer" : "transfer-modern"} className="deposit-inner-panel" style={{ paddingBottom: '30px' }}>
            <div className="row">
                <div className="col-xs-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("TRANSFER_FROM")}</b></label>
                            <div>
                                <Controller
                                    control={control}
                                    name="fromWallet"
                                    defaultValue={null}
                                    render={({ onChange, value }) => {
                                        return (
                                            <StyledSelect options={fromOptions}
                                                placeholder={t("PLEASE_SELECT_WALLET")}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e.value);
                                                    setSelectedFromOptions(e.value);
                                                    setBonus(e.bonus);
                                                    setTransfertoToption(e.value);
                                                }} />
                                        )
                                    }}
                                    rules={{
                                        validate: {
                                            required: value => value !== null || "PLEASE_SELECT_WALLET"
                                        }
                                    }} />
                                {errors.fromWallet && <div className="invalid-feedback">{t(errors.fromWallet.message)}</div>}
                            </div>
                        </div>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("TRANSFER_TO")}</b></label>
                            <div>
                                <Controller
                                    control={control}
                                    name="toWallet"
                                    defaultValue={null}
                                    render={({ onChange, value }) => {
                                        return (
                                            <StyledSelect options={toOptions}
                                                placeholder={t("PLEASE_SELECT_WALLET")}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e.value)
                                                }} />
                                        )
                                    }}
                                    rules={{
                                        validate: {
                                            required: value => value !== null || "PLEASE_SELECT_WALLET"
                                        }
                                    }} />
                                {errors.toWallet && <div className="invalid-feedback">{t(errors.toWallet.message)}</div>}
                            </div>
                        </div>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("AMOUNT")}</b></label>
                            <div>
                                <input type="number" className="form-control bg-white" readOnly placeholder={t("PLEASE_ENTER_AMOUNT")} name="amount"
                                    style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }} 
                                    onClick={(e) => { setCurrentInput(e.target) }}
                                    ref={register({
                                        required: "PLEASE_ENTER_AMOUNT",
                                        validate: {
                                            minValue: value => parseFloat(value) > 1 || ('PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE', {value: 1})
                                        }
                                    })}
                                />
                                {errors.amount ? <div className="invalid-feedback">{t(errors.amount.message)}</div> : (showTaxWarning && <div className="invalid-feedback" style={{ fontSize: "13px" }}>{t("TRANSFER_TRANSACTION_HAS_TAX", { bonusValue: numberWithCurrencyFormat(parseFloat(bonus), 2, true) })}</div>)}
                            </div>
                        </div>
                        <div style={{ margin: "1rem 0px" }}>
                            <button type="submit" className="btn btn-block btn-sticky-bottom btn-brand-gradient">{t("SUBMIT")}</button>
                        </div>
                    </form>
                </div>
            </div>
            <BottomSuccessModal
                isOpen={isTransferSuccess}
                setIsOpen={setIsTransferSuccess}
                title={"TRANSFER_SUCCESS"}
                amount={paidAmount}
                deductedAmount={deductedAmount}
                bonus={bonus}
                date={transferDate}
                transactionNumber={transcactionNumber}

            />
            {
                (getValues('toWallet') > 0 || currentInput) &&
                <Numpad currentBalance={typeof (walletList?.find(op => op.productId == getValues('toWallet'))?.balance) !== "undefined" ? walletList?.find(op => op.productId == getValues('toWallet'))?.balance : 0} currentInput={currentInput} setCurrentInput={(e) => { setCurrentInput(e) }} />
            }
        </div>
    );
}

export default Transfer;