import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from "../util/Constant";

import Login from '../pages/Login';
import Register from './../pages/Register';

import Downline from '../pages/home/Downline';
import DownlineDetails from '../pages/home/DownlineDetails';
import EditDownline from '../pages/home/EditDownline';
import Settings from '../pages/home/Settings';
import Dashboard from './../pages/home/Dashboard';
import DashboardV2 from './../pages/home/DashboardV2';
import Kpi from '../pages/home/Kpi';
import News from '../pages/home/News';
import VideoTutorial from '../pages/home/VideoTutorial';
import NewsDetails from '../pages/home/NewsDetails';

import Transaction from './../pages/transaction/Transaction';
import Withdrawal from '../pages/transaction/WithdrawalMobile';
import WithdrawalRecord from './../pages/transaction/WithdrawalRecord';
import Deposit from '../pages/transaction/DepositMobile';
import DepositRecord from './../pages/transaction/DepositRecord';

import PoolResult from "./../pages/game/PoolResult";
import GameLobby from "./../pages/game/GameLobby";
import UnderMaintenance from "./../pages/game/UnderMaintenance";

import ReportMenu from './../pages/report/ReportMenu';
import WalletHistory from "./../pages/report/WalletHistory";
import WalletHistoryMobile from "./../pages/report/WalletHistoryMobile";
import CommissionHistory from "./../pages/report/CommissionHistory";
import BonusHistory from "./../pages/report/BonusHistory";
import InternaTransferHistory from "./../pages/report/InternaTransferHistory";
import RolloverHistory from "./../pages/report/RolloverHistory";
import TransactionHistory from "./../pages/report/TransactionHistory";
import GameHistory from "./../pages/report/GameHistory";
import GameDetails from '../pages/report/GameDetails';

import ManageReferralCode from "./../pages/member/ManageReferralCode";
import ReferralCodeDetail from "./../pages/member/ReferralCodeDetail";
import ForgetPin from "./../pages/member/ForgetPin";

import DownlineGroupDetail from '../pages/member/DownlineGroupDetail';

import UnclaimCommissionHistory from '../pages/member/UnclaimCommissionHistory';

import Maintenance from "./../pages/Maintenance";
import ContactUs from './../pages/home/ContactForm';
import LiveChatPage from './../pages/home/LiveChat';

import Shareholder from '../pages/shareholder/Shareholder';
import ShareholderDetails from '../pages/shareholder/ShareholderDetails';
import EditShareholder from '../pages/shareholder/EditShareholder';
import TnC from '../pages/home/TnC';
import ClaimRecord from '../pages/shareholder/ClaimRecord';
import SummaryDownlineSales from '../pages/shareholder/SummaryDownlineSales';

import DownlineProductRateDetail from '../pages/member/DownlineProductRateDetail';

import Rewards from '../pages/home/Rewards';
import RewardsHistory from '../pages/report/RewardsHistory';
import RewardsDetail from '../pages/report/RewardsDetail';
import GamePage from '../pages/home/GamePage';
import CamoHomePage from '../pages/home/CamoHomePage';
import CamoRegister from '../pages/home/CamoRegister';

/// <summary>
/// Author : 
/// </summary>
export const _LOGIN_ROUTE =
{
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
}

export const _ROUTES = [
    {
        path: WebUrl._URL_LOGIN,
        exact: true,
        title: 'Login',
        authRequired: false,
        component: () => <Login />
    },
    {
        path: WebUrl._URL_UNDER_MAINTENANCE,
        exact: true,
        title: 'Under Maintenance',
        authRequired: false,
        component: () => <UnderMaintenance />
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        authRequired: true,
        component: () => <Redirect to={WebUrl._URL_DASHBOARD} />
    },
    {
        path: WebUrl._URL_DASHBOARD,
        exact: true,
        title: 'Dashboard',
        authRequired: true,
        component: () => <Dashboard />
    },
    {
        path: WebUrl._URL_DASHBOARD_V2,
        exact: true,
        title: 'DashboardV2',
        authRequired: true,
        component: () => <DashboardV2 />
    },
    {
        path: WebUrl._URL_SETTINGS,
        exact: true,
        title: 'Settings',
        authRequired: true,
        component: () => <Settings />
    },
    {
        path: WebUrl._URL_REGISTER,
        exact: true,
        title: 'Register',
        authRequired: false,
        component: () => <Register />
    },
    {
        path: WebUrl._URL_DOWNLINE,
        exact: true,
        title: 'Downline',
        authRequired: true,
        component: () => <Downline />
    },
    {
        path: WebUrl._URL_DOWNLINE_DETAILS,
        exact: true,
        title: 'Downline Details',
        authRequired: true,
        component: () => <DownlineDetails />
    },
    {
        path: WebUrl._URL_EDIT_DOWNLINE,
        exact: true,
        title: 'Edit Downline',
        authRequired: true,
        component: () => <EditDownline />
    },
    {
        path: WebUrl._URL_REPORTS,
        exact: true,
        title: 'Reports',
        authRequired: true,
        component: () => <ReportMenu />
    },
    {
        path: WebUrl._URL_TRANSACTION,
        exact: true,
        title: 'Transaction',
        authRequired: true,
        component: () => <Transaction reload={false} />
    },
    {
        path: WebUrl._URL_RELOAD_TRANSACTION,
        exact: true,
        title: 'Reload',
        authRequired: true,
        component: () => <Transaction reload={true} />
    },
    {
        path: WebUrl._URL_WITHDRAWAL_RECORD,
        exact: true,
        title: 'Withdrawal Record',
        authRequired: true,
        component: () => <WithdrawalRecord />
    },
    {
        path: WebUrl._URL_DEPOSIT_RECORD,
        exact: true,
        title: 'Deposit Record',
        authRequired: true,
        component: () => <DepositRecord />
    },
    {
        path: WebUrl._URL_WALLET_HISTORY,
        exact: true,
        title: 'Wallet History',
        authRequired: true,
        component: () => <WalletHistory />
    },
    {
        path: WebUrl._URL_WALLET_HISTORY_MOBILE,
        exact: true,
        title: 'Wallet History',
        authRequired: true,
        component: () => <WalletHistoryMobile />
    },
    {
        path: WebUrl._URL_TRANSACTION_HISTORY,
        exact: true,
        title: 'Transaction History',
        authRequired: true,
        component: () => <TransactionHistory />
    },
    {
        path: WebUrl._URL_BONUS_HISTORY,
        exact: true,
        title: 'Bonus History',
        authRequired: true,
        component: () => <BonusHistory />
    },
    {
        path: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
        exact: true,
        title: 'Internal Transfer History',
        authRequired: true,
        component: () => <InternaTransferHistory />
    },
    {
        path: WebUrl._URL_COMMISSION_HISTORY,
        exact: true,
        title: 'Commission History',
        authRequired: true,
        component: () => <CommissionHistory />
    },
    {
        path: WebUrl._URL_ROLLOVER_HISTORY,
        exact: true,
        title: 'Rollover History',
        authRequired: true,
        component: () => <RolloverHistory />
    },
    {
        path: WebUrl._URL_GAME_HISTORY,
        exact: true,
        title: 'Game History',
        authRequired: true,
        component: () => <GameHistory />
    },
    {
        path: WebUrl._URL_GAME_RESULT,
        exact: true,
        title: 'Result',
        authRequired: true,
        component: () => <PoolResult />
    },
    {
        path: WebUrl._URL_MANAGE_REFERRAL_CODES,
        exact: true,
        title: 'Referral Codes',
        authRequired: true,
        component: () => <ManageReferralCode />
    },
    {
        path: WebUrl._URL_REFERRAL_CODE_DETAIL,
        exact: true,
        title: 'Referral Code Detail',
        authRequired: true,
        component: () => <ReferralCodeDetail />
    },
    {
        path: WebUrl._URL_FORGET_PIN,
        exact: true,
        title: 'Forget PIN',
        authRequired: true,
        component: () => <ForgetPin />
    },
	{
        path: WebUrl._URL_GAME_LOBBY,
        exact: true,
        title: 'Game Lobby',
        authRequired: true,
        component: () => <GameLobby />
    },
    {
        path: WebUrl._URL_KPI,
        exact: true,
        title: 'KPI',
        authRequired: true,
        component: () => <Kpi />
    },
    {
        path: WebUrl._URL_SHAREHOLDER,
        exact: true,
        title: 'Shareholder',
        authRequired: true,
        component: () => <Shareholder />
    },
    {
        path: WebUrl._URL_SHAREHOLDER_DETAIL,
        exact: true,
        title: 'Shareholder Detail',
        authRequired: true,
        component: () => <ShareholderDetails />
    },
    {
        path: WebUrl._URL_EDIT_SHAREHOLDER,
        exact: true,
        title: 'Edit Shareholder',
        authRequired: true,
        component: () => <EditShareholder />
    },
    {
        path: WebUrl._URL_NEWS,
        exact: true,
        title: 'News',
        authRequired: true,
        component: () => <News />
    },
    {
        path: WebUrl._URL_NEWS_DETAILS,
        exact: true,
        title: 'Detail',
        authRequired: true,
        component: () => <NewsDetails />
    },
    {
        path: WebUrl._URL_MAINTENANCE,
        exact: true,
        title: 'Maintenance',
        component: () => <Maintenance />
    },
    {
        path: WebUrl._URL_GAME_DETAILS,
        exact: true,
        title: 'Game Detail',
        authRequired: true,
        component: () => <GameDetails />
    },
    {
        path: WebUrl._URL_CONTACT_US,
        exact: true,
        title: 'Contact Us',
        component: () => <ContactUs />
    },
    {
        path: WebUrl._URL_LIVE_CHAT,
        exact: true,
        title: 'Live Chat',
        component: () => <LiveChatPage />
    },
    {
        path: WebUrl._URL_CLAIM_RECORD,
        exact: true,
        title: 'Claim History',
        component: () => <ClaimRecord />
    },
    {
        path: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
        exact: true,
        title: 'Group Details',
        component: () => <DownlineGroupDetail />
    },
    {
        path: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY,
        exact: true,
        title: 'Unclaim Commission History',
        component: () => <UnclaimCommissionHistory />
    },
    {
        path: WebUrl._URL_TNC,
        exact: true,
        title: 'Terms & Conditions',
        component: () => <TnC />
    },
    {
        path: WebUrl._URL_VIDEO_TUTORIAL,
        exact: true,
        title: 'Video Tutorial',
        authRequired: true,
        component: () => <VideoTutorial />
    },
    {
        path: WebUrl._URL_WITHDRAWAL,
        exact: true,
        title: 'Withdrawal',
        authRequired: true,
        component: () => <Withdrawal />
    },
    {
        path: WebUrl._URL_DEPOSIT,
        exact: true,
        title: 'Deposit',
        authRequired: true,
        component: () => <Deposit />
    },
    {
        path: WebUrl._URL_SUMMARY_DOWNLINE_SALES,
        exact: true,
        title: 'Summary Downline',
        authRequired: true,
        component: () => <SummaryDownlineSales />
    },
    {
        path: WebUrl._URL_DOWNLINE_PRODUCT_RATE_DETAIL,
        exact: true,
        title: 'Downline Rates',
        authRequired: true,
        component: () => <DownlineProductRateDetail />
    },
    {
        path: WebUrl._URL_REWARDS,
        exact: true,
        title: 'Rewards',
        authRequired: true,
        component: () => <Rewards />
    },
    {
        path: WebUrl._URL_REWARDS_HISTORY,
        exact: true,
        title: 'Rewards History',
        authRequired: true,
        component: () => <RewardsHistory />
    },
    {
        path: WebUrl._URL_REWARDS_DETAIL,
        exact: true,
        title: 'Rewards Detail',
        authRequired: true,
        component: () => <RewardsDetail />
    },
    {
        path: WebUrl._URL_GAME_PAGE,
        exact: true,
        title: 'Game',
        authRequired: true,
        component: () => <GamePage />
    },
    {
        path: WebUrl._URL_CAMO_HOMEPAGE,
        exact: true,
        title: 'Home Page',
        authRequired: true,
        component: () => <CamoHomePage />
    },
    {
        path: WebUrl._URL_CAMO_REGISTER,
        exact: true,
        title: 'Register',
        authRequired: true,
        component: () => <CamoRegister />
    }
];