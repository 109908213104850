import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DateTime from "react-datetime";
import { ApiKey, ApiUrl, WebUrl, TransactionType, TransactionStatus } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine"
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { stringIsNullOrEmpty } from "../../util/Util";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

const CommissionHistory = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const [transactionData, setTransactionData] = useState([]);
    const { handleSubmit, register, control, watch, setValue, errors, reset } = useForm();
    const [products, setProducts] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
    const [commissionUrl, setCommissionUrl] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [queryProduct, setQueryProduct] = useState("");
    const [searchTrigger, setSearchTrigger] = useState(Date.now());

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
        getCommissionData(startDate, endDate);
    }, [])

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`);

        const productList = [{
            label: t("ALL"),
            value: null
        }];

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson[ApiKey._API_DATA_KEY].products.forEach(function (product) {
                productList.push({ label: product.productName, value: product.productId });
            });
        }

        setProducts(productList);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function getCommissionData(startDate, endDate, selected = '') {
        var apiUrl = ApiUrl._API_GET_COMMISSION_HISTORY + "?StartDate=" + moment(startDate).format('YYYY-MM-DD') + "&EndDate=" + moment(endDate).format('YYYY-MM-DD') + "&v=" + Date.now();

        if (!stringIsNullOrEmpty(watch()["productId"])) {
            apiUrl += "&productId=" + watch()["productId"];
        }

        if (!stringIsNullOrEmpty(selected)) {
            apiUrl += "&SelectedDate=" + moment(selected).format('YYYY-MM-DD');
        }

        setValue("productId", stringIsNullOrEmpty(queryProduct) ? null : queryProduct);
        setCommissionUrl(apiUrl);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getCommissionData(startDate, endDate, selectedDate)
        }
    }, [selectedDate])

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getCommissionData(startDate, endDate);
    }, [searchTrigger])

    /// <summary>
    /// Author : -
    /// </summary>
    function getRecordFromDynamicLoad(data) {
        setTransactionData(data);
    }

    return (
        <>
            <Header history={_history} url={WebUrl._URL_UNCLAIM_COMMISSION_HISTORY} title={t('COMMISSION_STATEMENT_SHORT')} />
            <div className="container mt-3" style={{ "marginBottom": "10px" }}>
                <DateRangeFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} onSave={(start, end) => {
                    setQueryProduct(watch()["productId"]);
                    setSelectedDate("");
                    setSearchTrigger(Date.now());
                }} options={true} />
                <div className="form-group m-b-15">
                    <label className="col-form-label"><b>{t("GAME")}</b></label>
                    <div>
                        <Controller
                            control={control}
                            name="productId"
                            render={({ onChange, value }) => {
                                return (
                                    <StyledSelect options={products}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.value)
                                        }} />
                                )
                            }} />
                    </div>
                </div>
                <div className="card card-package">
                    <div className="card-body p-0" style={{ "overflowX": "auto" }}>
                        <table className="table reportTable">
                            <thead>
                                <tr>
                                    <th>{t("DATE")}</th>
                                    <th>{t("USERNAME")}</th>
                                    <th>{t("GAME")}</th>
                                    <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                    <th style={{ textAlign: "right" }}>{t("CLUB_FEE")}</th>
                                    <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <DynamicLoadTable returnRecord={getRecordFromDynamicLoad} pageSize={20} apiQuery={commissionUrl} isTable={true} searchEnabled={true} render={(item, i) => {
                                    let isGrouped = moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days') >= 1 && stringIsNullOrEmpty(selectedDate);
                                    return (<tr key={i}>
                                        <td className={isGrouped ? "hoverable" : ""} onClick={() => {
                                            if (isGrouped) {
                                                setSelectedDate(item["parsedTime"]);
                                            }
                                        }}>
                                            {item["parsedTime"]}
                                        </td>
                                        <td>{item["username"]}</td>
                                        <td>{item["remark"]}</td>
                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["turnover"]), 3)}</td>
                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["clubFee"]), 3)}</td>
                                        <td style={{ textAlign:"right" }}>{numberWithCurrencyFormat(parseFloat(item["amount"]), 3)}</td>
                                    </tr>)
                                }} />
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="3"><b>{t("TOTAL")}</b></td>
                                    <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(transactionData.reduce((totalvt, data) => totalvt + data.turnover, 0)), 3)}</b></td>
                                    <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(transactionData.reduce((totalcf, data) => totalcf + data.clubFee, 0)), 3)}</b></td>
                                    <td style={{ textAlign: "right" }}><b>{numberWithCurrencyFormat(parseFloat(transactionData.reduce((total, data) => total + data.amount, 0)), 3)}</b></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommissionHistory;