import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { stringIsNullOrEmpty } from "../../util/Util";

/// <summary>
/// Author: -
/// </summary>
const ContactForm = props => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();

    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState();
    const [toggleModal, setToggleModal] = useState();

    useEffect(() => {
        if (selectedChannel) {
            if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
                setToggleModal(true);
            }
        }
    }, [selectedChannel]);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        fetch(ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true", {
            method: ApiKey._API_GET,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setChannelData(responseJson[ApiKey._API_DATA_KEY]);
            }
        });
    }, []);

    function setChannelData(data) {
        var channels = [];
        data.map((d, index) => {
            var channel =
            {
                name: d.channelDescription,
                description: "",
                actionUrl: d.cta,
                image: d.channelImage,
                attachment: d.attachment
            };

            channels.push(channel);
        });
        setChannels(channels);
    }

    function handleClick(channel) {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: "chat",
                    url: `${channel.actionUrl}`,
                })
            );
        } else {
            window.location.href = channel.actionUrl;
        }
    }

    return (
        <>
            <Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('CONTACT_US')} />
            <div id="contact-us" className="container mt-3" style={{ paddingBottom: "10px" }}>
                <div className="contact-us-overlay"></div>
                <ul className="ul-brand contact-list">
                    {
                        channels.map((channel) => {
                            return <li onClick={() => { !stringIsNullOrEmpty(channel.actionUrl) ? handleClick(channel) : setSelectedChannel(channel); }}>
                                <div className="channel-content">
                                    <img className="channel-logo" src={channel.image} />
                                    {channel.name}<br />
                                    {channel.description}
                                </div>
                            </li>
                        })
                    }
                </ul>
            </div>
            <Modal fade={false} isOpen={toggleModal} toggle={() => { setToggleModal(false) }} centered>
                <ModalHeader toggle={() => { setToggleModal(false) }}>{selectedChannel?.name}</ModalHeader>
                <ModalBody>
                    <img src={selectedChannel?.attachment} className="w-100" />
                </ModalBody>
            </Modal>
        </>
    )

}

export default ContactForm;