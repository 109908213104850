import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import moment from "moment";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";

const WalletHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const [walletUrl, setWalletUrl] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [selectedDate, setSelectedDate] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getWalletStatement(startDate, endDate);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getWalletStatement(start, end, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_MEMBER_WALLET_STATEMENT +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&v=" +
      Date.now();
    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setWalletUrl(apiUrl);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getWalletStatement(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
        title={t("WALLET_STATEMENT")}
      />
      <div className="container mt-3" style={{ marginBottom: "10px" }}>
        <DateRangeFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSave={(start, end) => {
            getWalletStatement(start, end);
            setSelectedDate("");
          }}
          options={true}
        />
        <div className="card card-package">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table reportTable">
                <thead>
                  <tr>
                    <th>{t("DATE")}</th>
                    <th>{t("REMARK")}</th>
                    <th style={{ textAlign: "right" }}>
                      {t("PRE_MEMBER_CREDIT")}
                    </th>
                    <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                    <th style={{ textAlign: "right" }}>
                      {t("POST_MEMBER_CREDIT")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <DynamicLoadTable
                    pageSize={20}
                    apiQuery={walletUrl}
                    searchEnabled={true}
                    isTable={true}
                    render={(item, i) => {
                      let isGrouped =
                        moment(endDate, "YYYY-MM-DD").diff(
                          moment(startDate, "YYYY-MM-DD"),
                          "days"
                        ) >= 1 && stringIsNullOrEmpty(selectedDate);
                      return (
                        <tr key={i}>
                          <td
                            className={isGrouped ? "hoverable" : ""}
                            onClick={() => {
                              if (isGrouped) {
                                setSelectedDate(item["parsedTime"]);
                              }
                            }}
                          >
                            {item["parsedTime"]}
                          </td>
                          <td>
                            {item["remark"]}
                            {item["serviceCharge"] != "0"
                              ? " - Fees " +
                                (!isGrouped
                                  ? "@" + item["serviceChargePercentage"] + "%"
                                  : "") +
                                "= " +
                                numberWithCurrencyFormat(
                                  parseFloat(item["serviceCharge"]),
                                  3
                                )
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(item["preMemberCredit"]),
                              3
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color:
                                item["postMemberCredit"] >
                                item["preMemberCredit"]
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {numberWithCurrencyFormat(
                              parseFloat(item["amount"]),
                              3
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(item["postMemberCredit"]),
                              3
                            )}
                          </td>
                        </tr>
                      );
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletHistory;
