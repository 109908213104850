import React, { useState, useEffect } from "react";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Header from "../../components/custom/Header";
import moment from "moment";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { numberWithCurrencyFormat } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";

const ClaimRecord = () => {
  let _history = useHistory();
  var _location = useLocation();
  const { t, i18n } = useTranslation();
  const [claimData, setClaimData] = useState([]);
  const [claimDataUrl, setClaimDataUrl] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [initPage, setInitPage] = useState(true);
  const [companyTimeZone, setCompanyTimeZone] = useState(0);
  const [loginHideSummary, setLoginHideSummary] = useState(false);

  useEffect(() => {
    if (_location["state"]) {
      setLoginHideSummary(_location.state.hideSummary);
    }
  }, []);

  async function getClaimData(start, end, selectedDateTime = "") {
    let apiUrl =
      ApiUrl._API_GET_CLAIM_HISTORY + "?startDate=" + start + "&endDate=" + end;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setClaimData(data["data"]);
    }
    setClaimDataUrl(apiUrl);
    setInitPage(false);
  }

  return (
    <>
      <Header
        history={_history}
        url={
          !window.ReactNativeWebView
            ? WebUrl._URL_SHAREHOLDER +
              "?GAzobE73Vse4nr8WpufA=" +
              loginHideSummary
            : null
        }
        title={t("CLAIM_HISTORY")}
      />
      <div className="container mt-3" style={{ marginBottom: "10px" }}>
        <DateRangeFilter
          onSave={(start, end) => {
            setStartDate(moment(start));
            setEndDate(moment(end));
            getClaimData(
              moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
              moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
            );
          }}
          options={true}
        />
        <div className="card card-package">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table reportTable" style={{ color: "#002E6C" }}>
                <thead>
                  <tr>
                    <th>{t("DATE_AND_TIME")}</th>
                    <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                  </tr>
                </thead>
                {claimData.length > 0 ? (
                  <tbody>
                    <DynamicLoadTable
                      pageSize={20}
                      apiQuery={claimDataUrl}
                      searchEnabled={true}
                      isTable={true}
                      render={(item, i) => {
                        return (
                          <tr key={i}>
                            <td>{moment(item["date"]).format("DD-MM-YYYY")}</td>
                            <td
                              className={
                                item["netProfit"] < 0 ? "text-red" : ""
                              }
                              style={{ textAlign: "right" }}
                            >
                              {numberWithCurrencyFormat(
                                parseFloat(item["netProfit"]),
                                3,
                                true
                              )}
                            </td>
                          </tr>
                        );
                      }}
                    />
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        {initPage
                          ? t(
                              'Please select date and press "Search" to continue'
                            )
                          : t("NO_DATA_FOUND")}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimRecord;
