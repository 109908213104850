import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { showResponseMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";

/// <summary>
/// Author: -
/// </summary>
const VideoTutorial = props => {
    var { t } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    var activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    const [video, setVideo] = useState([]);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VIDEO);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVideo(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    return (
        <>
            <Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('VIDEO_TUTORIAL')} />
            <div id={activeTheme === 0 ? "video-tutorial" : "video-tutorial-modern"}className="container mt-3" style={{ paddingBottom: '30px' }}>
                {video.map((video, index) => {
                    return (
                        <div>
                            <iframe className="video"
                                src={video['content']}
                                allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"                            >
                            </iframe><br></br>
                            <span className="video-name">{video['title']}</span>
                            <hr />
                         </div>
                    ) 
                })}
            </div>
        </>
    );
}

export default VideoTutorial;