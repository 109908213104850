import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import { createMultiPartFormBody } from "../../util/Util";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  Alert,
} from "reactstrap";
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";


const Rewards = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var { t } = useTranslation(LanguageKey._PRIMARY);


  const { handleSubmit, control, watch, setValue } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [bannerData, setBannerData] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rewardsData, setRewardData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [activeKeyList, setActiveKeyList] = useState([]);
  const [dropdownDataList, setDropdownDataList] = useState([]);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const _DAY_OF_WEEK = [
    { label: "M", value: 1 },
    { label: "T", value: 2 },
    { label: "W", value: 3 },
    { label: "T", value: 4 },
    { label: "F", value: 5 },
    { label: "S", value: 6 },
    { label: "S", value: 0 },
  ];

  const DEFAULT_CAROUSEL_ITEMS = [
    {
      src: require("../../assets/img/banner.png"),
      altText: "",
      caption: "",
      header: "",
      key: "1",
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === bannerData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? bannerData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  async function init() {
    let categoryData = [];
    fetch(ApiUrl._API_GET_USER_REWARD_BANNER, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBannerData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });

    fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          categoryData = responseJson[ApiKey._API_DATA_KEY];
          let tempActiveKeyList = [];
          categoryData.map((category, index) => {
            tempActiveKeyList.push({ id: category.id, active: false });
          });
          setActiveKeyList(tempActiveKeyList);
          setGameCategories(categoryData);
        }
      });

    fetch(ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_APPLICATION_JSON,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let dateData = responseJson[ApiKey._API_DATA_KEY]["currentDateTime"];
          setCurrentDateTime(moment(dateData));
          let rewData = responseJson[ApiKey._API_DATA_KEY]["rewardsData"];
          setRewardData(rewData);
          let claimData = responseJson[ApiKey._API_DATA_KEY]["claimData"];
          setClaimData(claimData);
          let data = responseJson[ApiKey._API_DATA_KEY];
          let tempDropdownDataList = {};
          Object.keys(rewData).map((date, index) => {
            if (!tempDropdownDataList[date]) {
              tempDropdownDataList[date] = {};
            }

              let tempObjList = [];
              
              data['productRewardsData'].map((rewardObj, index) => {

                  if (!moment(rewardObj.date).isSame(moment(date).startOf('day')))
                      return;

                  if (!tempDropdownDataList[date][rewardObj.categoryId]) {
                      tempDropdownDataList[date][rewardObj.categoryId] = { productList: [] };
                  }

                  tempDropdownDataList[date][rewardObj.categoryId]['productList'].push({
                      productId: rewardObj.productId, //for filter purpose
                      productImage: data['data'].find(x => x['id'] == rewardObj.productId).productImage, //to show each masterProduct icon
                      productReward: rewardObj.comm //to show the rewards rates under each masterProduct icon
                  });// = tempObjList;
              });
          });
          setDropdownDataList(tempDropdownDataList);
        }
      });
  };

  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_REWARD,
      createMultiPartFormBody({ date: selectedDate.clone().format("YYYY-MM-DD") })
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  const handleSelectedDate = (days) => {
    _dispatch(setBusy());

    let tempSelectedDate = selectedDate.clone().add(days, "days");
    if (rewardsData[tempSelectedDate.format("YYYY-MM-DD")] !== undefined) {
      if (
        tempSelectedDate.isSameOrBefore(
          currentDateTime.clone().add(2, "weeks")
        ) &&
        tempSelectedDate.isSameOrAfter(currentDateTime.clone().add(-2, "weeks"))
      ) {
        setSelectedDate(tempSelectedDate);
      }
    }
    _dispatch(setIdle());
  };

  const handleAccordionClick = (categoryId) => {
    let tempActiveKeyList = activeKeyList;
    let tempRow = tempActiveKeyList.find(x => x.id === categoryId);
    let tempActive = tempRow.active;
    tempRow.active = !tempActive;
    setActiveKeyList([...tempActiveKeyList]);
  };

  return (
    <>
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("Confirm Claim?")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <Header
        history={_history}
        url={activeTheme === 0 ? WebUrl._URL_DASHBOARD : WebUrl._URL_DASHBOARD_V2}
        title={t("PROMOTION")}
        hasBackButton={!window.ReactNativeWebView}
      />
      <div id="rewards-homepage">
        <div className={"banner " /* + (_isLoggedIn ? "is-loggedin" : "234")*/}>
          {bannerData.length > 0 ? (
            <ReactCarousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              indicator={true}
            >
              <CarouselIndicators
                items={bannerData}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {bannerData.map((item, index) => (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={index}
                >
                  {/*{item.url = item.url.toLowerCase().indexOf("http://") == 0 || item.url.toLowerCase().indexOf("https://") == 0 ? item.url : "http://" + item.url}*/}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!window.ReactNativeWebView) {
                        window.open(item.url);
                        return;
                      }
                    }}
                  >
                    <img
                      className="carousel-img"
                      src={item.mobileImage /*desktop=classic,mobile=modern*/}
                      alt={item.content}
                      style={{ width: "100%" }}
                    />
                  </a>
                  {/*<CarouselCaption captionText={item.content} />*/}
                </CarouselItem>
              ))}
            </ReactCarousel>
          ) : (
            <UncontrolledCarousel items={DEFAULT_CAROUSEL_ITEMS} />
          )}
        </div>

        <div style={{ padding: "20px" }}>
          <div className="rewards-calendar card card-package" style={{ marginBottom: "20px" }}>
            <div className="card-body p-0">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 15px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="rewards-calendar-week"
                    onClick={() => handleSelectedDate(-7)}
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-7, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-7, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                  >
                    {t("LAST_WEEK")}
                  </div>
                  <div className="rewards-calendar-month">
                    {selectedDate.format("MMMM")}
                  </div>
                  <div
                    className="rewards-calendar-week"
                    onClick={() => handleSelectedDate(7)}
                    style={{
                      cursor: selectedDate
                        .clone()
                        .add(7, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks")
                          || rewardsData[selectedDate
                            .clone()
                            .add(7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: selectedDate
                        .clone()
                        .add(7, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks")
                          || rewardsData[selectedDate
                            .clone()
                            .add(7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                  >
                    {t("NEXT_WEEK")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div
                    className="rewards-calendar-day" //selectedDay - 3 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-3, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-3, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(-3)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 3)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-3, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay - 2 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-2, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-2, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(-2)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 2)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-2, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay - 1 day
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-1, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-1, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(-1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(-1)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 1)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-1, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day"
                    /*selectedDay*/ style={{
                      background: "linear-gradient(180deg, #FE9F00, #FFCF7D)",
                    }}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + selectedDate.day()) % 7
                        ).label
                      }
                    </div>
                    <div>{moment(selectedDate).format("DD")}</div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 1 day
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(1, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(1, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(1)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 1)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(1, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 2 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(2, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(2, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(2)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 2)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(2, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 3 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(3, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(3, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                        || rewardsData[selectedDate
                          .clone()
                          .add(3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(3)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 3)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(3, "days").format("DD")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: "20px" }}>
          {claimData[selectedDate.format("YYYY-MM-DD")] !== undefined && <div
            className="card card-package"
            style={{ backgroundColor: "#03306D", marginBottom: "20px" }}
          >
            <div className="card-body p-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  color: "#ffffff",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Grand Total Rewards Earn:</div>
                  <div>
                    <span></span>
                    <span style={{ fontSize: "25px" }}>{claimData[selectedDate.format("YYYY-MM-DD")] > 0 ? claimData[selectedDate.format("YYYY-MM-DD")].toFixed(3) : "-"}</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to={WebUrl._URL_REWARDS_HISTORY}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div
                        style={{
                          marginRight: "5px",
                          color: "#ffffff",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {t("REWARDS_RECORD")}{" "}
                      </div>
                      <Icon
                        path={mdiHistory}
                        title={t("REWARDS_RECORD")}
                        size={1}
                        color={"#ffffff"}
                      />
                    </div>
                  </Link>
                  {claimData[selectedDate.format("YYYY-MM-DD")] !== undefined && claimData[selectedDate.format("YYYY-MM-DD")] > 0 && <button className="btn btn-block btn-brand-gradient"
                    style={{
                      width: "100px",
                      borderRadius: "10px",
                      marginTop: "5px",
                    }}
                    disabled={claimData[selectedDate.format("YYYY-MM-DD")] <= 0}
                    onClick={() => claimData[selectedDate.format("YYYY-MM-DD")] > 0 && preSubmit()}
                  >
                    {t("CLAIM")}
                  </button>
                  }

                </div>
              </div>
            </div>
          </div>
          }
          <div>
            <div className="card card-package" style={{ backgroundColor: "#ffe5bf", marginBottom: "20px" }}>
              <div className="card-body p-0">
                <div style={{ color: "#663c00", padding: "10px", textAlign: "center" }}>
                  {t("NOTICE_REWARDS", {startHour: 15, endHour: 16})}
                </div>
              </div>
            </div>
          </div>
          {rewardsData[selectedDate.format("YYYY-MM-DD")] != undefined && gameCategories.length > 0 && Object.entries(rewardsData[selectedDate.format("YYYY-MM-DD")]).map((category, index) => {
            return (<>
              {<Accordion defaultActiveKey={['-1']}>
                <Accordion.Item eventKey={category.id} onClick={() => handleAccordionClick(parseInt(category[0]))}>
                  <Accordion.Header>
                    <div className="rewards-value left">{category[1].split("|||")[0]}</div>
                    <div
                      className="rewards-value right"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 20px",
                      }}
                    >

                      <> {gameCategories.filter(x => x.originalTitle !== "V.Sports").map((item, index) => (
                        item.id.toString() == category[0] && <img
                          className="category-icon"
                          src={require("../../assets/img/icon/In" + item.originalTitle + ".svg")} />
                      ))
                      }
                      </>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "-50px",
                        }}
                      >
                        <div style={{ fontSize: "15px" }}>{
                          gameCategories.map((item, index) => (
                            item.id.toString() == category[0] && <>{item.originalTitle}</>))
                        }</div>
                        <div style={{ fontSize: "12px", color: "#FFC159" }}>
                          {"Earned : " + category[1].split("|||")[1]}
                        </div>
                      </div>
                      <div className={"dropdown-arrow" + (activeKeyList.length > 0 && activeKeyList.find(x => x.id === parseInt(category[0])).active ? " active" : "")}></div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="master-product-grid">
                      {dropdownDataList &&
                        dropdownDataList[selectedDate.format("YYYY-MM-DD")] &&
                        dropdownDataList[selectedDate.format("YYYY-MM-DD")][category[0]] &&
                        dropdownDataList[selectedDate.format("YYYY-MM-DD")][category[0]]['productList'].length > 0 ?
                        dropdownDataList[selectedDate.format("YYYY-MM-DD")][category[0]]['productList'].map((oneProduct, index) => {
                          return (
                            <>
                              <div className="card-wrapper">
                                <div className="card card-package">
                                  <div className="card-body p-0">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div className="product-image-wrapper">
                                        <div
                                          className="product-image"
                                          style={{
                                            backgroundImage: `url(${oneProduct.productImage})`,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="product-rate">{oneProduct.productReward + "%"}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }) :
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>}
            </>);
          })}
        </div>
      </div>
    </>
  )
}


export default Rewards;
