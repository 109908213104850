import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine.js";
import moment from "moment";
import DateTime from "react-datetime";
import { useDispatch } from "react-redux";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author: -
/// </summary>
const ManageReferralCode = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();

  const [referralCodes, setReferralCodes] = useState([]);
  const _dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState({});

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodes(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  async function deleteReferral(id) {
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_DEL_REFERRAL + "?id=" + id
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        init();
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (errorMessage) {
      _dispatch(showMessage(false, t(errorMessage)));
    } finally {
      _dispatch(setIdle());
    }
  }

  return (
    <div>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_DASHBOARD : null}
        title={t("REFERRAL_CODE")}
      />
      <div className="container mt-3" style={{ paddingBottom: "70px" }}>
        <SweetAlert
          show={confirmDelete}
          type="warning"
          title={t("DELETE_REFERRAL", selectedReferral.code) + "?"}
          showCancel
          cancelBtnText={t("CANCEL")}
          confirmBtnText={t("CONFIRM")}
          onConfirm={() => {
            deleteReferral(selectedReferral.id);
            setConfirmDelete(false);
          }}
          onCancel={() => {
            setConfirmDelete(false);
          }}
        />
        {referralCodes.length == 0 && (
          <div className="card">
            <div className="card-body">{t("NO_REFERRAL_CODE_YET")}</div>
          </div>
        )}
        {referralCodes.length > 0 && (
          <ul className="ul-news-list">
            {referralCodes.map((referralCode, index) => {
              return (
                <li key={index}>
                  <i
                    id="delReferral"
                    className="fas fa-trash d-flex align-items-center"
                    style={{ color: "#002e6c" }}
                    onClick={() => {
                      setSelectedReferral(referralCode);
                      setConfirmDelete(true);
                    }}
                  ></i>
                  <Link
                    to={{
                      pathname: WebUrl._URL_REFERRAL_CODE_DETAIL,
                      state: { id: referralCode.id },
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <strong
                        className="d-block"
                        style={{
                          paddingLeft: "15px",
                          color:
                            /*moment().startOf('day').isAfter(moment(referralCode["expiryDate"])) ? "red" : */ "#002E6C",
                        }}
                      >
                        {referralCode.code}
                        {referralCode.remark ? ` - ${referralCode.remark}` : ""}
                      </strong>
                    </div>
                    <i className="fas fa-chevron-right"></i>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div
        className="content content-full-width"
        style={{ position: "fixed", bottom: "20px", right: "0", width: "100%" }}
      >
        <div className="container">
          <button
            type="button"
            className="btn btn-block btn-brand"
            onClick={() =>
              _history.push({
                pathname: WebUrl._URL_REFERRAL_CODE_DETAIL,
              })
            }
          >
            {t("CREATE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageReferralCode;
