import React, { useState, useEffect } from "react";
import NormalDeposit from "./NormalDeposit";
import TNGDeposit from "./TNGDeposit";
import InstantDeposit from "./InstantDeposit";
import IBayarDeposit from "./IBayarDeposit";
import SPGDeposit from "./SPGDeposit";
import StyledSelect from "../../components/custom/StyledSelect";
import ApiEngine from "../../util/ApiEngine";
import Icon from '@mdi/react'
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { mdiHistory } from '@mdi/js'
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody } from "reactstrap";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { showResponseMessage } from "../../redux/AppAction";
import classnames from "classnames";
import TngIcon from './../../assets/img/icon/TnG.png';
import PayTrustIcon from './../../assets/img/icon/PayTrust.png';
import Header from "../../components/custom/Header";

/// <summary>
/// Author: -
/// </summary>
const Deposit = props => {
    const { t, i18n } = useTranslation();
    const _history = useHistory();
    const _dispatch = useDispatch();
    const { handleSubmit, control, setValue } = useForm();
    const {
        handleSubmit: handleFullNameSubmit,
        register: registerFullName,
        getValues: getValuesFullName,
        errors: errorsFullName,
        setValue: setValueFullName
    } = useForm();
    const activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    const _NORMAL_DEPOSIT = -1;
    const _TNG_DEPOSIT = -2;
    const _INSTANT_PAYMENT = 1;
    const _NORMAL_UPLOAD_DEPOSIT = 2;
    const [instantPayOption, setInstantPayOption] = useState([]);
    const [tempInstantPayOption, setTempInstantPayOption] = useState([]);
    //const [tempNormalPayOption, setTempNormalPayOption] = useState([]);
    const [paymentOptionList, setPaymentOptionList] = useState([]);
    const [paymentOption, setPaymentOption] = useState(_NORMAL_DEPOSIT);
    const [activeTab, setActiveTab] = useState(_INSTANT_PAYMENT);
    const [showFullnamePopup, setShowFullnamePopup] = useState(false);
    const [memberId, setMemberId] = useState("");
    const [activePaymentOption, setActivePaymentOption] = useState("SPG");
    const currentHourInUTC0 = moment();
    const currentMinuteInUTC0 = moment.utc().get('minutes');
    var TNG_START_HOUR_LIMIT_IN_UTC0 = 0;
    var TNG_END_HOUR_LIMIT_IN_UTC0 = 23;
    var TNG_START_MINUTE_LIMIT_IN_UTC0 = 0;
    var TNG_END_MINUTE_LIMIT_IN_UTC0 = 59;

    /// <summary>
    /// Author : -
    /// </summary>
    const toggleTab = async (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        //await togglePaymentOptionList(tab);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, [])

    const init = () => {
        (async function () {
            let memberJson = await ApiEngine.get(ApiUrl._API_GET_USER_FULLNAME);
            if (memberJson[ApiKey._API_SUCCESS_KEY]) {
                setMemberId(memberJson[ApiKey._API_DATA_KEY]["memberId"]);
                if (stringIsNullOrEmpty(memberJson[ApiKey._API_DATA_KEY]["userFullName"])) {
                    setShowFullnamePopup(true);
                }
            }
            let tempOptions = [];
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setInstantPayOption(responseJson[ApiKey._API_DATA_KEY]);
                setPaymentOption(0);
                responseJson[ApiKey._API_DATA_KEY].map((option, index) => {
                    tempOptions.push({ key: index, value: option.id, label: option.instantPayGatewayString, image: option.image, fee: option.serviceCharge });
                })
                setTempInstantPayOption(tempOptions);
                //let tempNormalOptions = [];
                let TnGresponseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_SYSTEM_SETTINGS);
                let startTimeStr = "";
                let endTimeStr = "";
                let TnGStatus = false;
                if (TnGresponseJson[ApiKey._API_SUCCESS_KEY] && TnGresponseJson[ApiKey._API_DATA_KEY].length > 0) {
                    let data = TnGresponseJson[ApiKey._API_DATA_KEY];
                    data.map((keyValuePair, index) => {

                        if (keyValuePair['key'] === 'TnGStartTime') {
                            startTimeStr = keyValuePair['value'];
                            TNG_START_HOUR_LIMIT_IN_UTC0 = moment(moment().format("YYYY-MM-DD") + " " + startTimeStr, 'YYYY-MM-DD HH:mm:ss');
                        }
                        if (keyValuePair['key'] === 'TnGEndTime') {
                            endTimeStr = keyValuePair['value'];
                            TNG_END_HOUR_LIMIT_IN_UTC0 = moment(moment().format("YYYY-MM-DD") + " " + endTimeStr, 'YYYY-MM-DD HH:mm:ss');
                        }
                        if (keyValuePair['key'] === 'TnGStatus') {
                            TnGStatus = (keyValuePair['value'] === 'true');
                        }
                    });
                }
                if (moment(TNG_END_HOUR_LIMIT_IN_UTC0).isBefore(TNG_START_HOUR_LIMIT_IN_UTC0)) {
                    TNG_END_HOUR_LIMIT_IN_UTC0 = moment(TNG_END_HOUR_LIMIT_IN_UTC0).add(1, "day");
                }
                if (TnGStatus && (currentHourInUTC0.isAfter(TNG_START_HOUR_LIMIT_IN_UTC0)) && (currentHourInUTC0.isBefore(TNG_END_HOUR_LIMIT_IN_UTC0))) {
                    //tempNormalOptions.push({ key: _TNG_DEPOSIT, value: _TNG_DEPOSIT, label: "Touch N Go (" + (moment(TNG_START_HOUR_LIMIT_IN_UTC0).format("HH:mm")) + "- " + (moment(TNG_END_HOUR_LIMIT_IN_UTC0).format("HH:mm")) + ") GMT+8", image: null, fee: 0 });
                    tempOptions.push({ key: _TNG_DEPOSIT, value: _TNG_DEPOSIT, label: "TnG", image: null, fee: 0 });
                }
                tempOptions.push({ key: _NORMAL_DEPOSIT, value: _NORMAL_DEPOSIT, label: "Normal", image: null, fee: 0 });
                //setTempNormalPayOption(tempNormalOptions);
                setValue("paymentMethod", tempOptions[0].value);
                setPaymentOptionList(tempOptions);
            }
        })();
    };

    const togglePaymentOptionList = async (tab) => {
        console.log(tempInstantPayOption);
        //console.log(tempNormalPayOption);
        if (tab === _INSTANT_PAYMENT) {
            setValue("paymentMethod", tempInstantPayOption[0].value);
            setPaymentOption(0);
            setPaymentOptionList(tempInstantPayOption);
        }
        else if (tab === _NORMAL_UPLOAD_DEPOSIT) {
            //setValue("paymentMethod", tempNormalPayOption[0].value);
            //setPaymentOption(tempNormalPayOption[0].value);
            //(tempNormalPayOption);
        }
    }

    const toggleActivePaymentOption = (selectedPaymentOption, tab) => {
        toggleTab(tab);
        setActivePaymentOption(selectedPaymentOption);
        let temp = paymentOptionList.find(x => x.label === selectedPaymentOption).value;
        if (tab === _INSTANT_PAYMENT) {
            let tempId = instantPayOption.findIndex(x => x.id === temp);
            setPaymentOption(tempId);
        }
        else {
            setPaymentOption(temp);
        }
        setValue("paymentMethod", temp);
    }

    async function submitFullName(data) {
        let params = {
            "userFullName": data.userFullName,
            "id": memberId
        };
        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER, createFormBody(params));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            setShowFullnamePopup(false);
        }
        init();
    }

    return (
        <><Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('DEPOSIT')} hasBackButton={false} />
        <div className="col-xs-12">
        <div id={activeTheme === 0 ? "deposit" : "deposit-modern"} className="deposit-inner-panel" style={{ paddingBottom: '30px' }}>
            <Link to={WebUrl._URL_DEPOSIT_RECORD}>
                <div className="d-flex justify-content-end align-items-center deposit-record-btn">
                    <div style={{ marginRight: "5px" }}> {t("DEPOSIT_RECORD")} </div>
                    <Icon path={mdiHistory}
                        title="User Profile"
                        size={1}
                        color={activeTheme === 0 ? "#002C68" : "#ffffff"} />
                </div>
            </Link>
            <div>
                <label className="col-form-label" style={{ fontSize: "16px", paddingLeft: "20px" }}><b>{t("PAYMENT_METHOD")}</b></label>
                <Nav style={{ justifyContent: "space-around" }}>
                    <NavLink
                        className={classnames({ active: activeTab === _INSTANT_PAYMENT })}
                    >
                        <NavItem>
                            <div className="payment-option-card">
                                <div style={{ marginBottom: "10px" }}>{t("INSTANT_PAYMENT")}</div>
                                <div className="instant-deposit options">
                                    <div className={"option option-1 " + classnames({ active: activePaymentOption === "SPG" })} onClick={() => toggleActivePaymentOption("SPG", _INSTANT_PAYMENT)}>
                                        <div className="option-image">SPG</div>
                                        <span className="option-text">SPG</span>
                                    </div>
                                    <div className={"option option-2 " + classnames({ active: activePaymentOption === "PayTrust" })} onClick={() => toggleActivePaymentOption("PayTrust", _INSTANT_PAYMENT)}>
                                        <div className="option-image" style={{ backgroundImage: `url(${PayTrustIcon})` }}></div>
                                        <span className="option-text">PayTrust</span>
                                    </div>
                                    <div className={"option option-3 " + classnames({ active: activePaymentOption === "iBayar" })} onClick={() => toggleActivePaymentOption("iBayar", _INSTANT_PAYMENT)}>
                                        <div className="option-image">ibayar</div>
                                        <span className="option-text">ibayar</span>
                                    </div>
                                </div>
                            </div>
                        </NavItem>
                    </NavLink>
                    <NavLink
                        className={classnames({ active: activeTab === _NORMAL_UPLOAD_DEPOSIT })}
                    >
                        <NavItem>
                            <div className="payment-option-card">
                                <div style={{ marginBottom: "10px" }}>{t("NORMAL_UPLOAD_DEPOSIT")}</div>
                                <div className="normal-deposit options">
                                    <div className={"option option-1 " + classnames({ active: activePaymentOption === "TnG" })} onClick={() => toggleActivePaymentOption("TnG", _NORMAL_UPLOAD_DEPOSIT)}>
                                        <div className="option-image" style={{ backgroundImage: `url(${TngIcon})` }}></div>
                                        <span className="option-text" style={{ fontSize: "12px", letterSpacing: "-0.7px" }}>Touch N Go</span>
                                    </div>
                                    <div className={"option option-2 " + classnames({ active: activePaymentOption === "Normal" })} onClick={() => toggleActivePaymentOption("Normal", _NORMAL_UPLOAD_DEPOSIT)}>
                                        <div className="option-image">Normal</div>
                                        <span className="option-text">Normal</span>
                                    </div>
                                </div>
                            </div>
                        </NavItem>
                    </NavLink>
                </Nav>
            </div>
            {/*<div className="form-group m-b-15">
                <label className="col-form-label"><b>{t("PAYMENT_METHOD")}</b></label>
                <Controller
                    className="dropdown-ctrl"
                    control={control}
                    name="paymentMethod"
                    defaultValue={"SPG"}
                    render={({ onChange, value }) => {
                        return (
                            <StyledSelect options={paymentOptionList}
                                value={value}
                                onChange={(e) => {
                                    onChange(e.value);
                                    setPaymentOption(e.key);
                                }} />
                        )
                    }} />
            </div>*/}
            <>
                {paymentOption == _NORMAL_DEPOSIT ? <NormalDeposit /> : paymentOption == _TNG_DEPOSIT ? <TNGDeposit /> : (
                    instantPayOption[paymentOption].instantPayGatewayString == "iBayar" ?
                        <IBayarDeposit gatewayOption={instantPayOption[paymentOption]} />
                        : instantPayOption[paymentOption].instantPayGatewayString == "SPG" ?
                            <SPGDeposit gatewayOption={instantPayOption[paymentOption]} />
                            :
                            <InstantDeposit gatewayOption={instantPayOption[paymentOption]} />
                )
                }
            </>
            {
                //CREATE NEW USER FULL NAME
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showFullnamePopup} centered>
                <ModalBody>
                    <form onSubmit={handleFullNameSubmit(submitFullName)}>
                        <div className="display-wrapper">
                            <strong className="reload-title text-yellow d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_FULL_NAME")}</strong>
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{t("IN_YOUR_BANK_ACCOUNT")}</strong>
                            <input
                                type="text"
                                name="userFullName"
                                className="withdrawal-fullName-input"
                                ref={registerFullName({
                                    required: 'FIELD_REQUIRED',
                                    pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" }
                                })}
                                onChange={(e) => setValueFullName('userFullName', e.target.value)}
                            />
                            {errorsFullName.userFullName && <div className="invalid-feedback">{t(errorsFullName.userFullName.message)}</div>}
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "200px" }}>{t("SUBMIT")}</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div></div></>
    );
}

export default Deposit;