import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { WebUrl, ApiUrl, ApiKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { showResponseMessage, setBusy, setIdle, setY, setPageSize } from "../../redux/AppAction";
import { numberWithCurrencyFormat } from "../../util/Util";

/// <summary>
/// Author: -
/// </summary>
const DownlineDetails = props => {
    var _history = useHistory();
    var { t } = useTranslation();
    var _dispatch = useDispatch();
    var _location = useLocation();

    const [downlineData, setDownlineData] = useState({});
    const [products, setProducts] = useState([]);
    const [nickname, setNickname] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_location['state']) {
            init();
            _dispatch(setPageSize(_location["scrollIndex"]));
        }
        else {
            _history.goBack();
        }
    }, [_location['state']]);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        try {
            var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=${_location['state']['id']}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setDownlineData(responseJson[ApiKey._API_DATA_KEY]);

            responseJson = await ApiEngine.get(`${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?memberId=${_location['state']['id']}&ignoreAccountProductStatus=true`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setProducts(responseJson[ApiKey._API_DATA_KEY].products);
            setNickname(responseJson[ApiKey._API_DATA_KEY].nickname);
        }
        catch (errorMessage) {
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        finally {
            _dispatch(setIdle());
        }
    }

    return (
        <>
            <Header history={_history} url={WebUrl._URL_DOWNLINE} title={t('DOWNLINE_DETAILS')} />
            <div className="container mt-3">
                <div className="card mb-4">
                    <div className="card-body">
                        <table className="table table-description text-brand">
                            <tbody>
                                <tr>
                                    <th>{t("USERNAME")}</th>
                                    <td>{downlineData['username']}</td>
                                </tr>
                                {downlineData['nickname'] != "" ?
                                    <tr>
                                        <th>{t("NICKNAME")}</th>
                                        <td>{downlineData['nickname']}</td>
                                    </tr>
                                    :""
                                }
                                <tr>
                                    <th>{t("CREATED_DATE")}</th>
                                    <td>{downlineData['createdDate']}</td>
                                </tr>
                                <tr>
                                    <th>{t("COMM_RATE")}</th>
                                    <td><a style={{ color: "red" }} onClick={() => { setIsModalVisible(true) }}>{t("SEE_MORE")}</a></td>
                                </tr>
                                <tr>
                                    <th>{t("POINTS")}</th>
                                    <td className="text-yellow">0</td>
                                </tr>
                                <tr>
                                    <th>{t("DIRECT_DOWNLINES")}</th>
                                    <td className="text-yellow">{downlineData['totalDirectDownlines']}</td>
                                </tr>
                                <tr>
                                    <th>{t("TOTAL_DOWNLINES")}</th>
                                    <td className="text-yellow">{downlineData['totalDownlines']}</td>
                                </tr>
                                <tr>
                                    <th>{t("LEVEL")}</th>
                                    <td>
                                        <span className="badge d-block bg-yellow-gradient mb-1">{t("BONUS")}: 0%</span>
                                        <span className="badge d-block bg-yellow-gradient">{t("SYSTEM")}: 0%</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="text-center">
                            <Link className="brand-link" to={{ pathname: WebUrl._URL_EDIT_DOWNLINE, state: { id: downlineData['id'] } }}>
                                {t("EDIT_DOWNLINE_DETAILS")} <i className="fas fa-chevron-right ml-3"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isModalVisible} toggle={() => { setIsModalVisible(!isModalVisible) }} contentClassName="downline-detail modal-bottom" centered={true} fade={false}>
                    <ModalHeader toggle={() => { setIsModalVisible(!isModalVisible) }}>{t("PRODUCT_COMM_RATE")}</ModalHeader>
                    <ModalBody className="p-0">
                        {
                            products.filter(x => !x['useGroupRate']).map((product, index) => {
                                return <div key={index}>
                                    <table  className="table table-borderless table-description m-15 notGroupRate">
                                        <tbody>
                                            <tr>
                                                <th width='50%'>{t("PRODUCT_NAME")}</th>
                                                <td>{product['productName']}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("BONUS_RATE")} (%)</th>
                                                <td>{numberWithCurrencyFormat(product['bonus'], 2)}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("COMM_RATE")} (%)</th>
                                                <td>{numberWithCurrencyFormat(product['commission'], 2)}</td>
                                            </tr>
                                            {product.productName === "D2 Poker" && <tr>
                                                <th>{t("CLUBFEE_RATE")} (%)</th>
                                                <td>{numberWithCurrencyFormat(product['clubFee'], 2)}</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                    {(index + 1) < products['length'] && <hr />}
                                </div>
                            })
                        }
                        {
                            products.filter(x => x['useGroupRate'])['length'] > 0 &&
                            <div className="row groupRates">
                                <div style={{ width: '100%' }}>
                                    <div>
                                        <label className="col-6 col-form-label">{t("PRODUCT_NAME")}</label>
                                        <span>{t("SLOT_GAMES")}</span>
                                    </div>
                                    <div>
                                        <label className="col-6 col-form-label">{t("COMM_RATE")} (%)</label>
                                        <span>{products.filter(x => x['useGroupRate'])[0]['commission']}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalBody>
                </Modal>
                <div style={{ display: 'none' }} className="card-brand text-center">
                    October
                <h1 className="title title-xl">0.00</h1>
                </div>
            </div>
        </>
    );
}

export default DownlineDetails;