import React from 'react';
import { PageSettings } from './config/page-settings.js';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { updateLoginUser, resetLogin, performLogout, checkIsLoggedIn } from "./redux/AuthAction.js";
import classNames from 'classnames';
import { isObjectEmpty, stringIsNullOrEmpty } from './util/Util.js';
import { withTranslation } from 'react-i18next';
import ApiEngine from './util/ApiEngine.js';
import { ApiKey, ApiUrl } from "./util/Constant";
import modernBackground from './assets/img/icon/blue-background-modern.svg';
import modernBackgroundXmas from './assets/img/icon/xmas-background-modern.svg';
import modernBackgroundXmaslowerOpac from './assets/img/icon/xmas-background-modern-lowerOpac.svg';
import modernBackgroundCNY from './assets/img/icon/Background_Modern_2x.png';
import modernBackgroundMonoGram from './assets/img/Monogram_Background@2x.png';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        }
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        }
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        }
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        }
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        }
        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        }
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        }

        this.state = {
            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: false,
            pageContentFullWidth: true,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: false,
            handleSetPageFooter: this.handleSetPageFooter,
            error: null,
            errorInfo: null,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout
        };
    }

    /// <summary>
    /// Author: -
    /// </summary>
    componentDidMount() {
        //navigator.serviceWorker.getRegistrations().then(function (registrations) { for (let registration of registrations) { registration.unregister(); } });
        this.setState({
            hasGetSkinSettings: false,
            hasCustomSkin: false,
            activeTheme: 0,
            backgroundImage: ""
        });
        this.props.checkIsLoggedIn();
    }

    /// <summary>
    /// Author: -
    /// </summary>
    componentDidUpdate(prevProps) {
        if (this.props.languageState.languageId != prevProps.languageState.languageId) {
            this.props.i18n.changeLanguage(this.props.languageState.languageId);
        }

        if (!this.state.hasCustomSkin) {
            let activeTheme = this.props.authState.userData.selectedTheme;
            if (activeTheme === 0) {
                this.getSkinSettings();
            }
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    async getSkinSettings() {
        if (!window.location.pathname.match(/login/)) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_SKIN_SETTINGS);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                this.state.hasCustomSkin = true;
                this.state.backgroundImage = responseJson[ApiKey._API_DATA_KEY]["backgroundImage"];
            }
            this.state.hasGetSkinSettings = true;
        }
        else {
            this.state.hasGetSkinSettings = false;
        }
    }

    render() {
        {/*return (
            <span style={{ color: "black" }}>App</span>
        )*/}
        if (this.state.errorInfo) {
            return (
                <div className="error" style={{ "padding": "20px" }}>
                    <img src={require("./assets/img/error.png")} style={{ "maxWidth": "100px", "objectFit": "contain", "marginLeft": "auto", "marginRight": "auto" }} />
                    <h3>Something went wrong, please contact admin for help</h3>
                    <div className="m-b-30">
                        {window.navigator.userAgent}
                    </div>
                </div>
            );
        }
        else {
            return (
                <PageSettings.Provider value={this.state}>
                    <LoadingOverlay
                        active={this.props.appState.isBusy || !this.props.authState.loginStateInitialized}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                zIndex: '10000',
                                position: 'fixed',
                            })
                        }}
                        className={classNames('loading-overlay')}
                    >
                        <img src={require('../src/assets/img/loading.gif')} />
                        <p>{this.props.appState.message + '...'}</p>
                    </LoadingOverlay>
                    {
                        !isObjectEmpty(this.props.appState.alert) && <div className="sweet-alert-container"><SweetAlert {...this.props.appState.alert}>
                            {!stringIsNullOrEmpty(this.props.appState.alert.content) && (this.props.appState.alert.html ? this.props.appState.alert.content : this.props.appState.alert.content.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            }))}
                        </SweetAlert>
                        </div>
                    }
                    {
                        this.props.authState.loginStateInitialized &&
                        <div className={classNames(
                            'fade page-sidebar-fixed show page-container', {
                            'page-content-full-height': this.state.pageContentFullHeight,
                        })
                        } style={{
                                minHeight: "100vh",
                                height: "100%",
                            backgroundImage: this.props.authState.userData.selectedTheme === 0 && this.state.hasCustomSkin === true ? `url(${this.state.backgroundImage})`
                                : this.props.authState.userData.selectedTheme === 1 ? `url(${/*modernBackground*/modernBackgroundMonoGram})`
                                    : `unset`,
                            backgroundPosition: "bottom",
                            backgroundAttachment: "fixed",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                            }}>
                            <div onClick={() => {
                                if (this.state.pageSidebarToggled) {
                                    this.toggleMobileSidebar();
                                }
                            }}>
                                {this.state.pageContent && (<Content />)}
                                {this.state.pageFooter && (<Footer />)}
                            </div>
                        </div>
                    }
                </PageSettings.Provider>
            )
        }
    }
}

const mapStateToProps = state => ({
    ...state,
});

export default
    connect(
        mapStateToProps,
        {
            updateLoginUser: updateLoginUser,
            resetLogin: resetLogin,
            performLogout: performLogout,
            checkIsLoggedIn: checkIsLoggedIn
        }
    )(withTranslation()(App));