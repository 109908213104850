import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import Claim from "./Claim";
import Report from "./Report";
import Summary from "./Summary";
import Downline from "./Downline";

import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useForm, Controller } from "react-hook-form";
import { Nav, NavItem, NavLink } from "reactstrap";

const Shareholder = () => {
  const { t, i18n } = useTranslation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const hideSummary = params.get("GAzobE73Vse4nr8WpufA");
  let _history = useHistory();
  let _location = useLocation();
  var _dispatch = useDispatch();
  var activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const _MENU_OPTION = {
    Summary: 1,
    Shareholders: 2,
    Claim: 3,
  };
  const [activeMenu, setActiveMenu] = useState(
    hideSummary === "true" ? _MENU_OPTION.Shareholders : _MENU_OPTION.Summary
  );

  const [renderComponent, setRenderComponent] = useState();
  const [headerMsg, setHeaderMsg] = useState("");

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    switch (activeMenu) {
      case _MENU_OPTION.Summary:
        setRenderComponent(<Summary hideSummary={hideSummary} />);
        break;
      case _MENU_OPTION.Shareholders:
        setRenderComponent(<Downline hideSummary={hideSummary} />);
        break;
      case _MENU_OPTION.Claim:
        setRenderComponent(<Claim hideSummary={hideSummary} />);
        break;
      default:
        setRenderComponent();
        break;
    }

    init();

    /// used to persist the current tab when refresh
    if (
      _location.state &&
      !stringIsNullOrEmpty(_location.state.menu) &&
      activeMenu != _location.state.menu
    ) {
      _history.replace({
        pathname: _location.pathname,
        state: {
          menu: activeMenu,
        },
      });
    }
  }, [activeMenu]);

  async function init() {
    setHeaderMsg("");
    if (
      activeMenu == _MENU_OPTION.Withdrawal ||
      activeMenu == _MENU_OPTION.Deposit
    ) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PENDING_TRANSACTION
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] == true) {
          setHeaderMsg(t("NEW_TXN_PROHIBITED"));
        }
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_DASHBOARD : null}
        title={t("SHAREHOLDER")}
      />
      <Nav
        className={
          "shareholder-nav" +
          (activeTheme === 0 ? "" : " modern") +
          " brand-nav"
        }
      >
        {hideSummary !== "true" && (
          <NavItem>
            <NavLink
              active={activeMenu == _MENU_OPTION.Summary}
              onClick={(e) => {
                e.preventDefault();
                setActiveMenu(_MENU_OPTION.Summary);
              }}
            >
              <span>{t("SUMMARY")}</span>
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            active={activeMenu == _MENU_OPTION.Shareholders}
            onClick={(e) => {
              e.preventDefault();
              setActiveMenu(_MENU_OPTION.Shareholders);
            }}
          >
            <span>{t("SHAREHOLDERS")}</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeMenu == _MENU_OPTION.Claim}
            onClick={(e) => {
              e.preventDefault();
              setActiveMenu(_MENU_OPTION.Claim);
            }}
          >
            <span>{t("CLAIM")}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="container">
        {!stringIsNullOrEmpty(headerMsg) && (
          <div className="alert alert-warning">{headerMsg}</div>
        )}
        <div className="row">
          <div className="col-xs-12"></div>
          <div className="col-xs-12">{renderComponent}</div>
        </div>
      </div>
    </>
  );
};

export default Shareholder;
