import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  TransactionStatus,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";

const RolloverHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  const [transactionData, setTransactionData] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getBonusData();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBonusData() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setTransactionData(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
        title={t("ROLLOVER_STATEMENT")}
      />
      <div
        id="rollover"
        className="container mt-3"
        style={{ marginBottom: "10px" }}
      >
        {transactionData.map((item, i) => {
          var statusHtml = "";

          statusHtml =
            item["isLatestRecord"] == 1 ? (
              <span
                className={
                  item["RolloverProgress"] == item["RolloverTarget"]
                    ? "badge badge-secondary badge-green"
                    : "badge badge-secondary badge-danger"
                }
              >
                {t(item["status"])}
              </span>
            ) : (
              <span className="badge badge-secondary badge-warning">
                {t(item["status"])}
              </span>
            );

          return (
            <div className="card card-rollover mb-2" key={i}>
              <table
                className="table mb-0 table-rollover table-valign-middle"
                style={{ borderCollapse: "collapse" }}
              >
                <tbody>
                  <tr>
                    <td style={{ fontSize: "13px" }}>{item["date"]}</td>
                    <td>{statusHtml}</td>
                  </tr>
                  <tr>
                    <td>{t("AMOUNT")}</td>
                    <th style={{ paddingRight: "4px" }}>{item["amount"]}</th>
                  </tr>
                  <tr>
                    <td>{t("ROLLOVER_PROGRESS")}</td>
                    <th style={{ paddingRight: "4px" }}>
                      {item["isLatestRecord"] == 1
                        ? item["rolloverProgress"]
                        : item["rolloverTarget"]}{" "}
                      / {item["rolloverTarget"]}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RolloverHistory;
