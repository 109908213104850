import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, Status } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { Nav, NavItem, NavLink } from "reactstrap";
import Deposit from "./Deposit";
import Withdrawal from "./Withdrawal";
import Transfer from "./Transfer";
import InternalTransfer from "./InternalTransfer";
import { showResponseMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import ApiEngine from "../../util/ApiEngine";
import moment from "moment";

/// <summary>
/// Author: -
/// </summary>
const Transaction = ({ reload = true }) => {
  const { t, i18n } = useTranslation();
  let _history = useHistory();
  let _location = useLocation();
  let _dispatch = useDispatch();
  let activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const _TRUE_STRING = "true";
  const _FALSE_STRING = "false";
  const _MENU_OPTION = {
    Deposit: 1,
    Withdrawal: 2,
    Transfer: 3,
    InternalTransfer: 4,
  };
  const [activeMenu, setActiveMenu] = useState(
    reload ? _MENU_OPTION.Deposit : _MENU_OPTION.Transfer
  );
  const [renderComponent, setRenderComponent] = useState();
  const [headerMsg, setHeaderMsg] = useState("");
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [hasPendingTxn, setHasPendingTxn] = useState(false);
  const [countdownStr, setCountdownStr] = useState("");
  const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
  const [transType, setTransType] = useState("");
  const [countdownTime, setCountdownTime] = useState();
  const _SECONDS_IN_MS = 1000;

  /// <summary>
  /// Author: Wong
  /// </summary>
  useEffect(() => {
    if (countdownPeriod > 0) {
      clearTimeout(countdownTime);
      setCountdownTime(
        setTimeout(() => {
          let tempPeriod = countdownPeriod - 1;
          setCountdownPeriod(tempPeriod);
          let tempX = moment.duration(tempPeriod);
          let tempMM = Math.floor(tempX["_milliseconds"] / 60);
          let tempSS = tempX["_milliseconds"] - tempMM * 60;
          setTimeDiffSeconds(tempX["_milliseconds"]);
          let tempStr =
            (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
            " " +
            t("MINUTES") +
            " " +
            (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
            " " +
            t("SECOND");
          setCountdownStr(tempStr);
        }, _SECONDS_IN_MS)
      );
    }
  }, [countdownPeriod]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const success = params.get("instantTransferSuccess");

    const BillId = params.get("BillId");
    const Paid = params.get("Paid");
    if (!stringIsNullOrEmpty(BillId) && !stringIsNullOrEmpty(Paid)) {
      iBayar(BillId, Paid);
    }
    const OrderNo = params.get("OrderNo");
    const Status = params.get("Status");

    if (!stringIsNullOrEmpty(OrderNo) && !stringIsNullOrEmpty(Status)) {
      SPG(OrderNo, Status);
    }
    if (_location.state && !stringIsNullOrEmpty(_location.state.menu)) {
      setActiveMenu(_location.state.menu);
    } else if (params) {
      const transType = params.get("transaction");
      switch (transType) {
        case "deposit":
          setActiveMenu(_MENU_OPTION.Deposit);
          break;
        case "withdrawal":
          setActiveMenu(_MENU_OPTION.Withdrawal);
          break;
        case "normaltransfer":
          setActiveMenu(_MENU_OPTION.Transfer);
          break;
        case "internaltransfer":
          setActiveMenu(_MENU_OPTION.InternalTransfer);
          break;
        default:
          break;
      }
      if (success != null && !stringIsNullOrEmpty(success)) {
        if (success == _TRUE_STRING) {
          _dispatch(showResponseMessage(true, t("DEPOSIT_SUCCESS")));
          _dispatch(checkIsLoggedIn());
        } else if (success == _FALSE_STRING) {
          _dispatch(showResponseMessage(false, t("DEPOSIT_FAILED")));
        }
        setActiveMenu(_MENU_OPTION.Deposit);

        /// remove the instantTransferSuccess tag from url
        _history.replace({
          pathname: _location.pathname,
          state: {
            menu: _MENU_OPTION.Deposit,
          },
        });
      }
    } else {
      _history.goBack();
    }
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    setHeaderMsg("");
    if (
      activeMenu == _MENU_OPTION.Withdrawal ||
      activeMenu == _MENU_OPTION.Deposit
    ) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PENDING_TRANSACTION
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          clearTimeout(countdownTime);
          let tempS = parseInt(responseJson[ApiKey._API_DATA_KEY]["timeSpan"]);
          setTransType(responseJson[ApiKey._API_DATA_KEY]["transType"]);
          setTimeDiffSeconds(tempS);
          setCountdownPeriod(tempS < 0 ? 0 : tempS);
          setHasPendingTxn(true);
          setHeaderMsg(t("NEW_TXN_PROHIBITED"));
        }
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function iBayar(BillId, Paid) {
    setHeaderMsg("");

    if (Paid == "True") {
      let params = {
        BillId: BillId,
        Paid: Paid,
      };
      let formBody = createFormBody(params);
      var responseJson = await ApiEngine.post(
        ApiUrl._API_IBAYAR_CHECK,
        formBody
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] == true) {
          _dispatch(showResponseMessage(true, t("DEPOSIT_SUCCESS")));
          _dispatch(checkIsLoggedIn());
        } else {
          _dispatch(showResponseMessage(false, t("DEPOSIT_FAILED")));
        }
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    } else {
      _dispatch(showResponseMessage(false, t("DEPOSIT_FAILED")));
    }
    _history.push("/reloadTransaction");
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function SPG(OrderNo, Status) {
    setHeaderMsg("");

    if (Status == "3") {
      _dispatch(showResponseMessage(true, t("DEPOSIT_SUCCESS")));
      /* let params = {

                OrderNo: OrderNo,
                Status: Status
            };
            let formBody = createFormBody(params);
            var responseJson = await ApiEngine.post(ApiUrl._API_SPG_CHECK, formBody);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {

                if (responseJson[ApiKey._API_DATA_KEY] == true) {
                    _dispatch(showResponseMessage(true, t("DEPOSIT_SUCCESS")));
                    _dispatch(checkIsLoggedIn());
                }
                else {
                    _dispatch(showResponseMessage(false, t("DEPOSIT_FAILED")));
                }
            }
            else {
                _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
            }*/
    } else if (Status == "4") {
      _dispatch(showResponseMessage(false, t("DEPOSIT_FAILED")));
    } else {
      _dispatch(showResponseMessage(true, t("DEPOSIT_CURRENTLY_IN_PROGRESS")));
    }
    _history.push("/reloadTransaction");
  }

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    switch (activeMenu) {
      case _MENU_OPTION.Deposit:
        setRenderComponent(<Deposit parentInit={init} />);
        _history.push({
          pathname: "/reloadTransaction",
          search: "?transaction=deposit",
          state: _location.state,
        });
        break;
      case _MENU_OPTION.Withdrawal:
        setRenderComponent(<Withdrawal parentInit={init} />);
        _history.push({
          pathname: "/reloadTransaction",
          search: "?transaction=withdrawal",
          state: _location.state,
        });
        break;
      case _MENU_OPTION.Transfer:
        setRenderComponent(<Transfer />);
        _history.push({
          pathname: "/transaction",
          search: "?transaction=normaltransfer",
          state: _location.state,
        });
        break;
      case _MENU_OPTION.InternalTransfer:
        setRenderComponent(<InternalTransfer />);
        _history.push({
          pathname: "/transaction",
          search: "?transaction=internaltransfer",
          state: _location.state,
        });
        break;
      default:
        setRenderComponent();
        break;
    }

    init();

    /// used to persist the current tab when refresh
    if (
      _location.state &&
      !stringIsNullOrEmpty(_location.state.menu) &&
      activeMenu != _location.state.menu
    ) {
      _history.replace({
        pathname: _location.pathname,
        state: {
          menu: activeMenu,
        },
      });
    }
  }, [activeMenu]);

  const generateTitle = () => {
    if (_location.state && _location.state.fromDashboard) {
      return t(reload ? "RELOAD" : "TRANSFER");
    }

    if (!window.ReactNativeWebView) {
      return t(reload ? "RELOAD" : "TRANSFER");
    }

    /**
     * Determine title for mobile app
     */
    const { search } = _location;
    const urlParams = new URLSearchParams(search);
    const type = urlParams.get("transaction");

    if (type) {
      return t(
        type.match(/deposit/gi)
          ? "DEPOSIT"
          : type.match(/withdrawal/gi)
          ? "WITHDRAWAL"
          : type.match(/normaltransfer/gi)
          ? "TRANSFER"
          : type.match(/internaltransfer/gi)
          ? "INTERNAL_TRANSFER"
          : ""
      );
    }

    return "";
  };

  const showNav = () => {
    /**
     * If it is navigate from dashboard, always show navigation bar
     */
    if (_location.state && _location.state.fromDashboard) {
      return true;
    }

    /**
     * If it is not from dashboard, then check it is from mobile app or not(Deposit and Withdrawal tab)
     */
    if (!window.ReactNativeWebView) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_DASHBOARD_V2}
        title={generateTitle()}
        hasBackButton={showNav()}
        fromDashboard={_location.state.fromDashboard}
      />
      {showNav() ? (
        <Nav
          id={activeTheme === 0 ? "classic" : "modern"}
          className="transaction-nav brand-nav"
        >
          {reload && (
            <NavItem>
              <NavLink
                active={activeMenu == _MENU_OPTION.Deposit}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.Deposit);
                }}
              >
                <span>{t("DEPOSIT")}</span>
              </NavLink>
            </NavItem>
          )}
          {reload && (
            <NavItem>
              <NavLink
                active={activeMenu == _MENU_OPTION.Withdrawal}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.Withdrawal);
                }}
              >
                <span>{t("WITHDRAWAL")}</span>
              </NavLink>
            </NavItem>
          )}
          {!reload && (
            <NavItem>
              <NavLink
                active={activeMenu == _MENU_OPTION.Transfer}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.Transfer);
                }}
              >
                <span>{t("TRANSFER")}</span>
              </NavLink>
            </NavItem>
          )}
          {!reload && (
            <NavItem>
              <NavLink
                active={activeMenu == _MENU_OPTION.InternalTransfer}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenu(_MENU_OPTION.InternalTransfer);
                }}
              >
                <span>{t("INTERNAL_TRANSFER")}</span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      ) : null}

      <div className="container">
        {hasPendingTxn && (
          <div className="alert alert-warning">
            {timeDiffSeconds > 0
              ? t("PLEASE_WAIT") +
                " " +
                countdownStr +
                " " +
                (transType === "Deposit"
                  ? t("FOR_DEPOSIT_APPROVAL")
                  : t("FOR_WITHDRAWAL_APPROVAL"))
              : headerMsg}
          </div>
        )}
        <div className="row">
          <div className="col-xs-12"></div>
          <div className="col-xs-12">{renderComponent}</div>
        </div>
      </div>
    </>
  );
};

export default Transaction;
