import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import Header from "../../components/custom/Header";
import {
  setBusy,
  setIdle,
  showResponseMessage,
  setDownlineSearchText,
  setDownlineQuery,
  setPageSize,
  setY,
  setActiveTab,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import ApiEngine from "../../util/ApiEngine.js";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import StyledSelect from "../../components/custom/StyledSelect";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { createFormBody, createMultiPartFormBody } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import ToggleButton from "react-toggle-button";

/// <summary>
/// Author: -
/// </summary>
const Downline = (props) => {
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();

  const [memberData, setMemberData] = useState({});
  let _userData = useSelector((state) => state.authState.userData);
  let _savedData = useSelector((state) => state.appState.savedText);
  let _savedQuery = useSelector((state) => state.appState.savedQuery);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  const [initCheck, setInitCheck] = useState(false);
  const [categories, setCategories] = useState([]);
  const [productStatus, setProductStatus] = useState(false);
  const [products, setProducts] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [username, setUsername] = useState([]);
  const [downlineGroups, setDownlineGroups] = useState([]);
  const [downlineMemberData, setDownlineMemberData] = useState({});
  const [totalUngroupedMembers, setTotalUngroupedMembers] = useState(0);
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const activeTab = useSelector((state) => state.appState.activeTab);
  const _DOWNLINE_TAB = 0;
  const _DOWNLINE_GROUPS_TAB = 1;
  const _DOWNLINE_GAMES_TAB = 2;

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function onSaveGroupSubmit(data, e) {
    _dispatch(setBusy());
    if (typeof selectedGroup === "undefined") {
      _dispatch(showResponseMessage(false, t("PLEASE_SELECT_GROUP")));
    } else {
      let params = {
        groupId: selectedGroup,
      };

      Object.keys(data).map((key, value) => {
        params[key] = data[key];
      });

      let formBody = createMultiPartFormBody(params);
      let responseJson = await ApiEngine.post(
        ApiUrl._API_UPDATE_DOWNLINE_PRODUCT_RATE,
        formBody
      );
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }
  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    } finally {
      _dispatch(setIdle());
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      _dispatch(setActiveTab(tab));
    }
  };

    async function getDownlineGames(product) {
        _dispatch(setBusy());
       var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT + "?masterProductId=" + product
       );
       if (responseJson[ApiKey._API_SUCCESS_KEY]) {
           setUsername((responseJson[ApiKey._API_DATA_KEY]));
        }
        _dispatch(setIdle());
    }
  /// <summary>
  /// Author: -
  /// </summary>
  async function getMemberDetails() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );

    let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      setCategories(categoryJson[ApiKey._API_DATA_KEY]);
    }
    var productResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST
    );
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productResponseJson[ApiKey._API_DATA_KEY]["products"];
      setProducts(data);
        let productList = [];
        data.map((data) => {
          productList.push({ label: data.productName, value: data.productId });
      });
        setProductOption(productList);
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      getDownlineGroups(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    }


  const getDownlineGroups = async (memberData) => {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_GROUPS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let downlineGroupList = [];
      let totalMembersInEachGroup = [];
      downlineGroupList.push({ label: "Default", value: 0 });
      let totalMembersInDefault = memberData["totalDirectDownlines"];
      data["totalMemberInGroup"].map((total, index) => {
        totalMembersInDefault -= total;
        totalMembersInEachGroup.push(total);
      });
      setTotalUngroupedMembers(totalMembersInDefault);
      totalMembersInEachGroup.unshift(totalMembersInDefault);
      data["data"].map((group, index) => {
        downlineGroupList.push({ label: group["name"], value: group["id"] });
      });
      setDownlineGroups(downlineGroupList);
      setDownlineMemberData(totalMembersInEachGroup);
    }
  };

  /// <summary>
  /// Author: -
  /// </summary>
  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      id: _location["state"]["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    //let tempformBody = createMultiPartFormBody({
    //    accountProductId: product["accountProductId"],
    //    commission: product['commission'],
    //    bonus: product['bonus']
    //});
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  function getText(e) {
    _dispatch(setDownlineSearchText(e.target.value));
    _dispatch(
      setDownlineQuery(
        ApiUrl._API_GET_USER_MEMBER_LIST +
          "?keyword=" +
          e.target.value +
          "&directUserOnly=true"
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }

  const deleteGroup = async (groupId) => {
    let params = {
      id: groupId,
    };
    let formBody = createFormBody(params);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_DOWNLINE_GROUP,
      formBody
    );
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    getDownlineGroups(memberData);
  };

    async function toggleShowValue(value, index, downlineUsername, product, showStatus) {
        _dispatch(setBusy());
      let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_DOWNLINE_MEMBER_GAME + "?username=" + downlineUsername + "&masterProductId=" + product + "&enable=" + !value);
        let tempProducts = username;
        let tempValue = !value;
        let newArr = tempProducts[index];
        if (tempValue === true) {
            newArr.show = true;
        }
        else {
            newArr.show = false;
        }
        tempProducts[index] = { ...newArr };
        _dispatch(setIdle());
  };

  return (
    <div id={_userData.selectedTheme === 0 ? "downline" : "downline-modern"}>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_DASHBOARD : null}
        title={t("DOWNLINE")}
      />
      <Nav
        className={
          "downline-nav" +
          (_userData.selectedTheme === 0 ? " classic" : " modern")
        }
        tabs
      >
        <NavItem
          onClick={() => {
            toggleTab(_DOWNLINE_TAB);
          }}
        >
          <NavLink
            className={classnames({ active: activeTab === _DOWNLINE_TAB })}
          >
            <span>{t("DOWNLINES")}</span>
          </NavLink>
        </NavItem>
        <NavItem
          onClick={() => {
            toggleTab(_DOWNLINE_GROUPS_TAB);
          }}
        >
          <NavLink
            className={classnames({
              active: activeTab === _DOWNLINE_GROUPS_TAB,
            })}
          >
            <span>{t("DOWNLINE_GROUPS")}</span>
          </NavLink>
        </NavItem>
        {/*<NavItem
          onClick={() => {
            toggleTab(_DOWNLINE_GAMES_TAB);
          }}
        >
          <NavLink
            className={classnames({
              active: activeTab === _DOWNLINE_GAMES_TAB,
            })}
          >
            <span>{t("DOWNLINE_GAMES")}</span>
          </NavLink>
        </NavItem>*/}
      </Nav>
      <TabContent
        style={{ backgroundColor: "transparent" }}
        activeTab={activeTab}
      >
        <TabPane tabId={_DOWNLINE_TAB}>
          <div className="container mt-3" style={{ paddingBottom: "35px" }}>
            <div className="card kpi-header-widget mb-3">
              <div className="card-body">
                <div className="user-detail">
                  <div>{memberData["username"]}</div>
                  <div>{memberData["phoneNumber"]}</div>
                </div>
              </div>
            </div>
            <Input
              placeholder={t("ALIAS_NAME_PHONE")}
              defaultValue={_savedData}
              onInput={(e) => {
                getText(e);
              }}
              inputGroupType="append"
              inputGroupContent={
                <i
                  className="fas fa-search"
                  style={{ color: "#FFC159", fontSize: "18px" }}
                ></i>
              }
            />
            <div className="downline-stat-wrapper">
              <div>
                <div className="card-brand text-center text-bold">
                  {t("NEW_MEMBER_TODAY", {
                    value: memberData["totalNewDownlineToday"],
                  })}
                </div>
                <div className="card-brand mb-0 text-center text-bold">
                  <div className="account-stat-wrapper">
                    <div>
                      {t("TOTAL")} &nbsp;{" "}
                      <span>{memberData["totalDownlines"]}</span>
                    </div>
                    <div>
                      {t("LEVEL")} &nbsp;{" "}
                      <span>{memberData["maxDownlineLevel"]}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card-brand card-direct-downline text-center text-bold">
                  <h1 className="title">
                    {memberData["totalDirectDownlines"]}
                  </h1>
                  <div>{t("DIRECT_DOWNLINE")}</div>
                </div>
              </div>
            </div>
            <p
              className={
                "f-w-800 text-brand" +
                (_userData.selectedTheme === 0 ? " classic" : " modern")
              }
            >
              {t("DOWNLINES")}
            </p>
            <ul className="ul-news-list">
              <DynamicLoadTable
                pageSize={_savedPageSize}
                apiQuery={_savedQuery}
                searchEnabled={true}
                autoscrollEnabled={true}
                render={(downline, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={{
                          pathname: WebUrl._URL_DOWNLINE_DETAILS,
                          scrollIndex: downline["dataLength"],
                          state: { id: downline["id"] },
                        }}
                      >
                        <div>
                          <strong className="d-block">
                            {downline["username"]} - {downline["fullname"]}
                          </strong>
                          {downline["nickname"] != "" && (
                            <small className="d-block">
                              ({downline["nickname"]})
                            </small>
                          )}
                          <span style={{ fontSize: "small" }}>
                            {downline["phoneNumber"]}
                          </span>
                        </div>
                        <i className="fas fa-chevron-right"></i>
                      </Link>
                    </li>
                  );
                }}
              />
            </ul>
          </div>
        </TabPane>
        <TabPane tabId={_DOWNLINE_GROUPS_TAB}>
          <ul className="ul-news-list">
            {downlineGroups.map((downlineGroup, index) => {
              return (
                <li key={index}>
                  {downlineGroup.label !== "Default" && (
                    <i
                      id="delGroup"
                      className="fas fa-trash d-flex align-items-center"
                      style={{ color: "#002e6c" }}
                      onClick={() => {
                        setSelectedGroupToDelete(downlineGroup);
                        setConfirmDelete(true);
                      }}
                    />
                  )}
                  <div
                    className="d-flex align-items-center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <strong
                      className="d-block"
                      style={{
                        paddingLeft:
                          downlineGroup.label !== "Default" ? "15px" : "25px",
                        color: "#002E6C",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "125px",
                      }}
                    >
                      {downlineGroup.label}
                    </strong>
                    <strong style={{ color: "#002E6C" }}>
                      {" (" +
                        t("TOTAL_MEMBER") +
                        ": " +
                        (downlineGroup.label !== "Default"
                          ? downlineMemberData[index]
                          : totalUngroupedMembers) +
                        ")"}
                    </strong>
                  </div>
                  <Link
                    to={{
                      pathname: WebUrl._URL_DOWNLINE_PRODUCT_RATE_DETAIL,
                      state: { id: downlineGroup.value },
                    }}
                  >
                    <button
                      style={{ whiteSpace: "nowrap" }}
                      className="btn btn-block btn-brand"
                    >
                      {t("EDIT_%")}
                    </button>
                  </Link>
                  <Link
                    to={{
                      pathname: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                      state: { id: downlineGroup.value },
                    }}
                  >
                    <i
                      className="fas fa-chevron-right"
                      style={{ fontSize: "20px", marginLeft: "50px" }}
                    ></i>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div
            className="content content-full-width"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "0",
              width: "100%",
            }}
          >
            <div className="container">
              <button
                type="button"
                className="btn btn-block btn-brand"
                onClick={() => {
                  _history.push({
                    pathname: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                  });
                }}
              >
                {t("CREATE")}
              </button>
            </div>
          </div>
          <SweetAlert
            show={confirmDelete}
            type="warning"
            title={t("DELETE_GROUP") + selectedGroupToDelete.label + "?"}
            showCancel
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONFIRM")}
            onConfirm={() => {
              deleteGroup(selectedGroupToDelete.value);
              setConfirmDelete(false);
            }}
            onCancel={() => {
              setConfirmDelete(false);
            }}
          />
        </TabPane>
        <TabPane tabId={_DOWNLINE_GAMES_TAB}>
          <div
            className={
              "form-group m-b-0" +
              (_userData.selectedTheme === 0
                ? " downline-games-classic"
                : " downline-games-modern")
            }
          >
            <div className="form-group m-b-15">
                <label className="col-form-label">
                    <b>{t("Games")}</b>
                </label>
                <div>
                    <Controller
                        control={control}
                        name="productId"
                        defaultValue=""
                        render={({ onChange, value }) => {
                            return (
                                <StyledSelect
                                    options={productOption}
                                    placeholder={t("PLEASE_SELECT_GAME")}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e.value);
                                        getDownlineGames(e.value);
                                    }}
                                />
                            );
                        }}
                        rules={{ required: "PLEASE_SELECT_BANK" }}
                    />
                    {errors.bankId && (
                        <div className="invalid-feedback">
                            {t(errors.bankId.message)}
                        </div>
                    )}
                </div>
                      </div>
            {username.map((u, index) => {
                return (
                    <div style={{ color: "white" }}>

                        <table className="edit-downline-table">
                            <thead></thead>
                            <tbody>
                        <tr
                            className={
                                "data-row" +
                                ((index + 1) % 2 !== 0
                                    ? " bg-color-1" +
                                    (_userData.selectedTheme === 0
                                        ? " classic"
                                        : " modern")
                                    : "")
                            }
                        >
                            <td
                                className="row-data-1"
                                style={{ color: "white" }}
                            >
                                <b>{u.username}</b>
                            </td>
                            <td className="row-data-2"></td>
                            <td className="row-data-3"></td>
                            <td className="row-data-4">
                                <ToggleButton
                                    value={u.show === true ? true : false}
                                    onToggle={(value) => {
                                        toggleShowValue(value,index, u.username, u.product,u.show);
                                    }}
                                />
                            </td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                    );
            }
                )}
          </div>
          {/*<><div className={"form-group m-b-0" + (_userData.selectedTheme === 0 ? " downline-games-classic" : " downline-games-modern")} style={{ marginTop: "15px" }}>
                            <label className="pl-4">{t("PRODUCTS")}</label>
                            <StyledSelect
                                options={products.map(x => { return { label: x['productName'], value: x['id'] } })}
                                value={product['id']}
                                onChange={(data) => {
                                    setProduct(products.find(x => x['id'] == data['value']));
                                }} />
                        </div>
                        <div>
                            <div className={"card-body" + (_userData.selectedTheme === 0 ? " downline-games-slider-classic" : " downline-games-slider-modern")}>
                                <RangeSlider
                                    max={product['maxBonus']}
                                    value={product['bonus']}
                                    label={`${t("BONUS_RATE")} (%)`}
                                    step={0.1}
                                    onChange={(value) => { product['bonus'] = value; setProduct({ ...product }); }} />
                                <RangeSlider
                                    max={product['maxCommission']}
                                    value={product['commission']}
                                    label={`${t("COMMISSION_RATE")} (%)`}
                                    step={0.1}
                                    onChange={(value) => { product['commission'] = value; setProduct({ ...product }); }} />
                            </div>
                        </div></>*/}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Downline;
