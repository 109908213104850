import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, TransactionType, TransactionStatus } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine"
import DateTime from "react-datetime";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { stringIsNullOrEmpty } from "../../util/Util";

const TransactionHistory = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const [transactionData, setTransactionData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    var _dateRef = useRef(null);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getTransactionData();
    }, [])

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        _dateRef.current.openCalendar();
    }, [startDate])

    /// <summary>
    /// Author : -
    /// </summary>
    async function getTransactionData() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_TRANSACTION_BY_MEMBER_ID +
            "?startDate=" + moment(startDate).format('YYYY-MM-DD') + "&endDate=" + moment(endDate).format('YYYY-MM-DD') + "&type=" + "2");

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTransactionData(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return (
        <div className="container" style={{ "marginBottom":"10px"}}>
            <Header history={_history} url={WebUrl._URL_REPORTS} title={t('HISTORY')} />
            <div className="filter-grid-wrapper">
                <DateTime
                    inputProps={{ className: 'form-control leftAlignPicker', readOnly: true }}
                    name="startDate"
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect={true}
                    value={startDate}
                    timeFormat={false}
                    onChange={(e) => {
                        setStartDate(e.format("YYYY-MM-DD"));
                    }} />
                <span className="text">{t("TO")}</span>
                <DateTime
                    inputProps={{ className: 'form-control', readOnly: true }}
                    name="endDate"
                    dateFormat="YYYY-MM-DD"
                    closeOnSelect={true}
                    timeFormat={false}
                    value={endDate}
                    ref={_dateRef}
                    onChange={(e) => {
                        setEndDate(e.format("YYYY-MM-DD"));
                    }} />
                <button className="btn btn-brand" onClick={() => getTransactionData()}>{t("SEARCH")}</button>
            </div>
            {
                transactionData.map((item) => {
                    var statusHtml = "";
                    var creditAmountHtml = "";
                    var debitAmountHtml = "";

                    if (item["transactionTypeId"] != TransactionType._WITHDRAWAL) {
                        creditAmountHtml = item["amount"];
                    }
                    else {
                        debitAmountHtml = item["amount"];
                    }

                    switch (item["approvalStatus"]) {
                        case TransactionStatus._APPROVED:
                            statusHtml = <span className="badge badge-secondary badge-green">{t("APPROVED")}</span>
                            break;
                        case TransactionStatus._REJECTED:
                            statusHtml =  <span className="badge badge-secondary badge-danger">{t("REJECTED")}</span>
                            break;
                        default:
                            statusHtml = <span className="badge badge-secondary badge-primary">{item["approvalStatusString"]}</span>
                            break;
                    }

                    return (
                        <div className="card mb-4">
                            <div className="card-body">
                                <table className="table table-description mb-0" style={{ "borderCollapse": "collapse" }}>
                                    <tr style={{ "borderBottom" : "0.5px solid lightgray" }}>
                                        <td>{moment(item["submitTime"]).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        <td style={{ textAlign:"center" }}>{statusHtml}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("TRANSACTION")}</th>
                                        <td style={{ textAlign: "center" }}>{item["transactionType"]}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("AMOUNT")}</th>
                                        <td style={{ textAlign: "center" }}>{stringIsNullOrEmpty(debitAmountHtml) ? creditAmountHtml : debitAmountHtml }</td>
                                    </tr>
                                    <tr>
                                        <th>{t("AUDITOR")}</th>
                                        <td style={{ textAlign: "center" }}>{item["auditedFullName"]}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default TransactionHistory;