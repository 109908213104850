import { _IDLE, _BUSY, _ALERT_TYPES, _API_CALLED, _DOWNLINE_SEARCH, _DOWNLINE_QUERY, _SET_Y, _SET_PAGESIZE, _SET_ACTIVE_TAB, _IS_KEEPED_LOGIN, _GAME_SEARCH, _GAME_QUERY } from './AppAction';
import { stringIsNullOrEmpty } from '../util/Util'

const _INITIAL_STATE = {
    isBusy: false,
    isKeepedLogin: false,
    message: "Please Wait",
    alert: {},
    iconEnabled: true,
    apiCalledResponse: {},
    savedText: "",
    gameSavedText: "",
    savedQuery: "/Member/GetUserMemberList?directUserOnly=true",
    gameQuery: "/Game/GetMasterProductGroupedGame",
    y: 0,
    pageSize: 10,
    activeTab: 0,
};

var _busyCount = 0;

/// <summary>
/// Author : 
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _BUSY:
            _busyCount++;
            return {
                ...state,
                isBusy: true,
                message: stringIsNullOrEmpty(action.message) ? _INITIAL_STATE.message : action.message,
                iconEnabled: action.iconEnabled == null ? _INITIAL_STATE.iconEnabled : action.iconEnabled,
            }
            break;
        case _IDLE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                isBusy: (_busyCount != 0),
            }
            break;
        case _ALERT_TYPES.SET_ALERT:
            return {
                ...state,
                alert: Object.keys(state.alert) == 0 ? action.alert : state.alert
            };
            break;
        case _ALERT_TYPES.NONE:
            return {
                ...state,
                alert: {}
            };
            break;
        case _API_CALLED:
            return {
                ...state,
                apiCalledResponse: action.data
            };
            break;
        case _DOWNLINE_SEARCH:
            return {
                ...state,
                savedText: action.data
            };
            break;
        case _DOWNLINE_QUERY:
            return {
                ...state,
                savedQuery: action.data
            };
            break;
        case _GAME_SEARCH:
            return {
                ...state,
                gameSavedText: action.data
            };
            break;
        case _GAME_QUERY:
            return {
                ...state,
                gameQuery: action.data
            };
            break;
        case _SET_Y:
            return {
                ...state,
                y: action.y,
            };
        case _SET_PAGESIZE:
            return {
                ...state,
                pageSize: action.data,
            };
            break;
        case _SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.data,
            };
            break;
        case _IS_KEEPED_LOGIN:
            return {
                ...state,
                isKeepedLogin: action.data,
            };
            break;
        default:
            return state;
            break;
    }
};