import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { WebUrl } from "../../util/Constant";

/// <summary>
/// Author: -
/// </summary>
const ReportMenu = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();

  const _MENU_OPTION = [
    {
      name: "WALLET_STATEMENT",
      imageUrl: require("../../assets/img/icon/wallet-report-icon.png"),
      redirectUrl: WebUrl._URL_WALLET_HISTORY,
    },
    {
      name: "BONUS_STATEMENT",
      imageUrl: require("../../assets/img/icon/bonus-report-icon.png"),
      redirectUrl: WebUrl._URL_BONUS_HISTORY,
    },
    {
      name: "ROLLOVER_STATEMENT",
      imageUrl: require("../../assets/img/icon/rollover-report-icon.png"),
      redirectUrl: WebUrl._URL_ROLLOVER_HISTORY,
    },
    {
      name: "GAME_RECORD",
      imageUrl: require("../../assets/img/icon/game-report-icon.png"),
      redirectUrl: WebUrl._URL_GAME_HISTORY,
    },
    {
      name: "INTERNAL_TRANSFER",
      imageUrl: require("../../assets/img/icon/bonus-report-icon.png"),
      redirectUrl: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
    },
  ];

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_DASHBOARD : null}
        title={t("REPORTS")}
      />
      <div className="container mt-3">
        <div className="report-menu">
          {_MENU_OPTION.map((menu, index) => {
            return (
              <div key={index} className="card-panel m-b-10">
                <Link to={menu["redirectUrl"]}>
                  <div className="report-menu-wrapper">
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ objectFit: "contain" }}
                        src={menu["imageUrl"]}
                      />
                    </div>
                    <div>{t(menu["name"])}</div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ReportMenu;
