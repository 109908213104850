import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, Status } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine"
import ScrollMenu from "react-horizontal-scrolling-menu";
import moment from "moment";
import DatePicker from "react-datepicker";
import OwlCarousel from "react-owl-carousel2";
import 'react-owl-carousel2/src/owl.carousel.css';
import { useSelector } from 'react-redux';

/// <summary>
/// Author : -
/// </summary>
const PoolResult = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const [poolOption, setPoolOption] = useState([]);
    const [selectedPool, setSelectedPool] = useState(null);
    const [poolDate, setPoolDate] = useState(null);
    const activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    const DateCustomInput = ({ value, onClick }) => (
        <input
            className="form-control"
            onClick={onClick}
            value={value}
            type="text"
            readOnly={true}
        />
    );

    const _POOL_IMAGE = {
        mg: require("../../assets/img/icon/magnum_logo.png"),
        kd: require("../../assets/img/icon/damacai_logo.png"),
        tt: require("../../assets/img/icon/toto_logo.png"),
        sg: require("../../assets/img/icon/singapore_logo.png"),
        sr: require("../../assets/img/icon/bigcash_logo.png"),
        sn: require("../../assets/img/icon/stc_logo.png"),
        sb: require("../../assets/img/icon/sabah_logo.png"),
        gd: require("../../assets/img/icon/gd_logo.png")
    };

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (selectedPool == null || moment(poolDate, 'DD/MM/YYYY').format("DD/MM/YYYY") != selectedPool["drawDateString"]) {
            init();
        }
    }, [poolDate]);

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        var apiUrl = ApiUrl._API_GET_POOL_RESULT;

        if (poolDate != null) {
            apiUrl += "?date=" + moment(poolDate).format('YYYY-MM-DD');
        }

        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseJson[ApiKey._API_DATA_KEY].length > 0) {
                setSelectedPool(responseJson[ApiKey._API_DATA_KEY][0]);
                if (responseJson[ApiKey._API_DATA_KEY][0]["drawDateString"] !== "01/01/0001") {
                    setPoolDate(moment(responseJson[ApiKey._API_DATA_KEY][0]["drawDateString"], 'DD/MM/YYYY').toDate());
                }
                setPoolOption(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                setSelectedPool(null);
                setPoolOption([]);
            }
        }
    }

    const _OPTIONS = {
        items: 6,
    };

    return (
        <>
            <Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('RESULT')} />
            <div className="pool-result container mt-3" style={{ paddingBottom: '30px' }}>
                <div style={{ marginBottom: "20px", position: "relative" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="button" className="btn btn-icon" style={{ color: "#002e6c", position: "absolute", top: "5px", left: "16px", zIndex: "1" }} onClick={() => {
                            setPoolDate(new Date(poolDate.setDate(poolDate.getDate() - 1)))
                        }}><i className="fa fa-chevron-left"></i></button>
                    </div>
                    <div className="pool-calendar" style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <DatePicker customInput={<DateCustomInput />} dateFormat="dd/MM/yyyy" placeholder={t("SELECT_DATE")} selected={poolDate} onChange={date => { setPoolDate(date); }} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button type="button" className="btn btn-icon" style={{ color: "#002e6c", position: "absolute", top: "5px", right: "16px" }} onClick={() => setPoolDate(new Date(poolDate.setDate(poolDate.getDate() + 1)))}><i className="fa fa-chevron-right"></i></button>
                    </div>
                </div>
                {selectedPool != null && moment(selectedPool["drawDateString"], "DD/MM/YYYY").format('DD/MM/YYYY') !== "01/01/0001" ? <><div className="form-group">
                    {poolOption.length > 0 &&
                        <OwlCarousel options={_OPTIONS}>
                            {
                                poolOption.map(el => {
                                    const { poolKey, is5D6D } = el;

                                    var style = {};

                                    if (selectedPool != null && poolKey == selectedPool["poolKey"] && is5D6D == selectedPool["is5D6D"]) {
                                        style = { "border": "2px solid #FFC159", "height": "40px", "borderRadius" : "12px" };
                                    } else {
                                        style = { "border": "2px solid transparent", "height": "40px", "borderRadius": "12px"  };
                                    }

                                    return (
                                        <div
                                            className="owl-item-pool"
                                            onClick={() => { setSelectedPool(poolOption.filter(i => i.poolKey == poolKey && (is5D6D == Status._ENABLED.toString()) == i.is5D6D)[0]) }}
                                            
                                            key={poolKey + "," + (is5D6D ? Status._ENABLED : Status._DISABLED)}
                                        >
                                            <img src={_POOL_IMAGE[poolKey]} alt="" className="media-object" style={style} />
                                        </div>
                                    );
                                })
                            }
                        </OwlCarousel>
                    }
                </div>
                {selectedPool == null &&
                    <div style={{ textAlign: "center", color: activeTheme === 0 ? "#002e6c" : "white" }}>{t("NO_DATA_FOUND")}</div>
                }
                {selectedPool != null &&
                    <div className={"card-package card-result mb-3" + (activeTheme === 0 ? " pool-result" : " pool-result-modern")}>
                        <div className="card-header resultCell bg-transparent">
                            <div className="pool-name">{selectedPool["poolName"]}</div>
                            <div style={{ margin: "1px", fontSize: "14px" }}>{moment(selectedPool["drawDateString"], "DD/MM/YYYY").format('ddd, DD/MM/YYYY')}</div>
                        </div>
                        <div className="card-body p-0">
                            {
                                selectedPool["is5D6D"] && !stringIsNullOrEmpty(selectedPool["p_5d_1st"]) &&
                                <table className="table" style={{ width: "100%", textAlign: "center" }}>
                                    <tbody>
                                        <tr>
                                            <th colSpan="4" className="resultCell">5D</th>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("1ST")}</th>
                                            <td className="resultCell">{selectedPool["p_5d_1st"]}</td>
                                            <th className="resultCell">{t("4TH")}</th>
                                            <td className="resultCell">{selectedPool["p_5d_4th"]}</td>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("2ND")}</th>
                                            <td className="resultCell">{selectedPool["p_5d_2nd"]}</td>
                                            <th className="resultCell">{t("5TH")}</th>
                                            <td className="resultCell">{selectedPool["p_5d_5th"]}</td>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("3RD")}</th>
                                            <td className="resultCell">{selectedPool["p_5d_3rd"]}</td>
                                            <th className="resultCell">{t("6TH")}</th>
                                            <td className="resultCell">{selectedPool["p_5d_6th"]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            {
                                selectedPool["is5D6D"] && !stringIsNullOrEmpty(selectedPool["p_6d_1st"]) &&
                                <table className="table" style={{ width: "100%", textAlign: "center" }}>
                                    <tbody>
                                        <tr>
                                            <th className="resultCell" colSpan="4">6D</th>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("1ST")}</th>
                                            <td className="resultCell" colSpan="3">{selectedPool["p_6d_1st"]}</td>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("2ND")}</th>
                                            <td className="resultCell">{selectedPool["p_6d_2nd_a"]}</td>
                                            <td className="resultCell">or</td>
                                            <td className="resultCell">{selectedPool["p_6d_2nd_b"]}</td>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("3RD")}</th>
                                            <td className="resultCell">{selectedPool["p_6d_3rd_a"]}</td>
                                            <td className="resultCell">or</td>
                                            <td className="resultCell">{selectedPool["p_6d_3rd_b"]}</td>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("4TH")}</th>
                                            <td className="resultCell">{selectedPool["p_6d_4th_a"]}</td>
                                            <td className="resultCell">or</td>
                                            <td className="resultCell">{selectedPool["p_6d_4th_b"]}</td>
                                        </tr>
                                        <tr>
                                            <th className="resultCell">{t("5TH")}</th>
                                            <td className="resultCell">{selectedPool["p_6d_5th_a"]}</td>
                                            <td className="resultCell">or</td>
                                            <td className="resultCell">{selectedPool["p_6d_5th_b"]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            {
                                !selectedPool["is5D6D"] &&
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th colSpan="5" className="resultCell">
                                                <span className="prize-type text-nowrap">{t("1ST")}</span><span className="prize-type-num">{selectedPool["p_4d_1st"]}</span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan="5" className="resultCell">
                                                <span className="prize-type text-nowrap">{t("2ND")}</span><span className="prize-type-num">{selectedPool["p_4d_2nd"]}</span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan="5" className="resultCell p-b-20">
                                                <span className="prize-type text-nowrap">{t("3RD")}</span><span className="prize-type-num">{selectedPool["p_4d_3rd"]}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th colSpan="5" className="resultCell"><div className='prize-label'>{t("SPECIAL")}</div></th>
                                        </tr>
                                        <tr>
                                            <td className="resultCell">{selectedPool["p_starter0"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter1"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter2"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter3"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter4"]}</td>
                                        </tr>
                                        <tr>
                                            <td className="resultCell">{selectedPool["p_starter5"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter6"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter7"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter8"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter9"]}</td>
                                        </tr>
                                        <tr>
                                            <td className="resultCell"></td>
                                            <td className="resultCell">{selectedPool["p_starter10"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter11"]}</td>
                                            <td className="resultCell">{selectedPool["p_starter12"]}</td>
                                            <td className="resultCell p-b-40"></td>
                                        </tr>
                                        <tr>
                                            <th colSpan="5" className="resultCell"><div className='prize-label'>{t("CONSOLATION")}</div></th>
                                        </tr>
                                        <tr>
                                            <td className="resultCell">{selectedPool["p_consolation0"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation1"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation2"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation3"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation4"]}</td>
                                        </tr>
                                        <tr>
                                            <td className="resultCell">{selectedPool["p_consolation5"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation6"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation7"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation8"]}</td>
                                            <td className="resultCell">{selectedPool["p_consolation9"]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                }</> : <div style={{ textAlign: "center", color: activeTheme === 0 ? "black" : "white" }}>{t("NO_DATA_FOUND")}</div>}
            </div>
        </>
    );
};

export default PoolResult;