import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DateTime from "react-datetime";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  TransactionStatus,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

const InternaTransferHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const { handleSubmit, register, control, watch, setValue, errors, reset } =
    useForm();
  const [products, setProducts] = useState([]);
  const [bonusUrl, setBonusUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [bonusData, setBonusData] = useState([]);
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getBonusData(startDate, endDate);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBonusData(startDate, endDate, selected = "") {
    var apiUrl =
      ApiUrl._API_GET_INTERNAL_TRANSFER +
      "?StartDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(endDate).format("YYYY-MM-DD") +
      "&v=" +
      Date.now();

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }

    setBonusUrl(apiUrl);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getBonusData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getBonusData(startDate, endDate);
  }, [searchTrigger]);

  /// <summary>
  /// Author : -
  /// </summary>
  function getRecordFromDynamicLoad(data) {
    setBonusData(data);
  }

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
        title={t("INTERNAL_TRANSFER")}
      />
      <div
        id={activeTheme === 0 ? "bonus-history" : "bonus-history-modern"}
        className="container mt-3"
        style={{ marginBottom: "10px" }}
      >
        <DateRangeFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSave={(start, end) => {
            setQueryProduct(watch()["productId"]);
            setSelectedDate("");
            setSearchTrigger(Date.now());
          }}
          options={true}
        />
        <div className="card card-package">
          <div className="card-body p-0" style={{ overflowX: "auto" }}>
            <table className="table reportTable">
              <thead>
                <tr>
                  <th>{t("DATE")}</th>
                  <th>{t("FROMUSERNAME")}</th>
                  <th>{t("TOUSERNAME")}</th>
                  <th>{t("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                <DynamicLoadTable
                  returnRecord={getRecordFromDynamicLoad}
                  pageSize={20}
                  apiQuery={bonusUrl}
                  isTable={true}
                  searchEnabled={true}
                  render={(item, i) => {
                    let isGrouped =
                      moment(endDate, "YYYY-MM-DD").diff(
                        moment(startDate, "YYYY-MM-DD"),
                        "days"
                      ) >= 1 && stringIsNullOrEmpty(selectedDate);
                    return (
                      <tr key={i}>
                        <td>{item["date"]}</td>
                        <td>{item["fromUsername"]}</td>
                        <td>{item["toUsername"]}</td>
                        <td>
                          {numberWithCurrencyFormat(
                            parseFloat(item["amount"]),
                            2
                          )}
                        </td>
                      </tr>
                    );
                  }}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternaTransferHistory;
