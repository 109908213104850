import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  BetStatus,
  LanguageKey,
  _TICKET_STATUS,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import DateTime from "react-datetime";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

const GameHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [gameRecord, setGameRecord] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [products, setProducts] = useState([]);
  const [ticketStatus, setTicketStatus] = useState([]);
  const { control, watch, setValue } = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [recordUrl, setRecordUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [queryStatus, setQueryStatus] = useState("1");
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());
  const [isGrouped, setIsGrouped] = useState(false);

  const { _userData } = useSelector((state) => ({
    _userData: state.authState.userData,
  }));

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    getGameRecords(startDate, endDate);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`
    );

    const productList = [
      {
        label: t("ALL"),
        value: null,
      },
    ];

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].products.forEach(function (product) {
        productList.push({
          label: product.productName,
          value: product.productId,
        });
      });
    }

    setProducts(productList);
    setTicketStatus(_TICKET_STATUS);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function getGameRecords(startDate, endDate, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_GAME_RECORD_BY_MEMBER +
      "?startDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      " 00:00:00" +
      "&endDate=" +
      moment(endDate).format("YYYY-MM-DD") +
      " 23:59:59" +
      "&v=" +
      Date.now() +
      "&GroupDateRequired=true";

    if (!stringIsNullOrEmpty(queryProduct)) {
      apiUrl += "&productId=" + queryProduct;
    }

    if (!stringIsNullOrEmpty(queryStatus)) {
      apiUrl += "&ticketStatus=" + queryStatus;
    }

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }

    setValue(
      "productId",
      stringIsNullOrEmpty(queryProduct) ? null : queryProduct
    );
    setValue(
      "ticketStatus",
      stringIsNullOrEmpty(queryStatus) ? null : queryStatus
    );
    setRecordUrl(apiUrl);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function showGameDetails(data) {
    setGameDetails(data);
    setIsModalVisible(true);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getGameRecords(startDate, endDate);
  }, [searchTrigger]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setIsGrouped(
      !moment(endDate, "YYYY-MM-DD").isSame(
        moment(startDate, "YYYY-MM-DD"),
        "day"
      ) && stringIsNullOrEmpty(selectedDate)
    );

    if (!stringIsNullOrEmpty(selectedDate)) {
      getGameRecords(startDate, endDate, selectedDate);
    }

    if (!stringIsNullOrEmpty(selectedDate)) {
      getGameRecords(startDate, endDate, selectedDate);
    }
  }, [selectedDate, searchTrigger]);

  /// <summary>
  /// Author : -
  /// </summary>
  function getRecordFromDynamicLoad(data) {
    setGameRecord(data);
  }

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
        title={t("GAME_RECORD")}
      />
      <div
        id={
          _userData.selectedTheme === 0 ? "game-history" : "game-history-modern"
        }
        className="container mt-3"
        style={{ marginBottom: "10px" }}
      >
        <DateRangeFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSave={(start, end) => {
            setQueryProduct(watch()["productId"]);
            setQueryStatus(watch()["ticketStatus"]);
            setSelectedDate("");
            setSearchTrigger(Date.now());
          }}
          options={true}
        />
        <div className="form-row">
          <div className="col">
            <label className="col-form-label">
              <b>{t("GAME")}</b>
            </label>
            <div className="form-group p-0">
              <Controller
                control={control}
                name="productId"
                render={({ onChange, value }) => {
                  return (
                    <StyledSelect
                      options={products}
                      value={value}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="col">
            <label className="col-form-label">
              <b>{t("TICKET_STATUS")}</b>
            </label>
            <div className="form-group p-0">
              <Controller
                control={control}
                name="ticketStatus"
                render={({ onChange, value }) => {
                  return (
                    <StyledSelect
                      options={ticketStatus}
                      value={value}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="card card-package">
          <div className="card-body p-0" style={{ overflowX: "auto" }}>
            <table className="table reportTable">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>{t("DATE")}</th>
                  <th style={{ textAlign: "center" }}>{t("GAME")}</th>
                  <th style={{ textAlign: "center" }}>{t("TICKET_ID")}</th>
                  <th style={{ textAlign: "center" }}>{t("STATUS")}</th>
                  <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                  <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                  <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                </tr>
              </thead>
              <tbody>
                <DynamicLoadTable
                  returnRecord={getRecordFromDynamicLoad}
                  pageSize={20}
                  apiQuery={recordUrl}
                  isTable={true}
                  searchEnabled={true}
                  render={(item, i) => {
                    var style = {
                      color:
                        parseFloat(item["winAmount"]) -
                          parseFloat(item["amount"]) >
                        0
                          ? "green"
                          : "red",
                    };
                    style["textAlign"] = "right";

                    return (
                      <tr
                        key={i}
                        style={{ borderBottom: "0.5px solid lightgray" }}
                      >
                        <td
                          style={{ textAlign: "center" }}
                          className={isGrouped ? "hoverable" : ""}
                          onClick={() => {
                            if (isGrouped) {
                              setSelectedDate(item["date"]);
                            }
                          }}
                        >
                          {item["date"]}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {!stringIsNullOrEmpty(item["gameName"]) &&
                          item["gameName"] != "ALL"
                            ? item["gameName"]
                            : item["productName"]}
                        </td>
                        <td style={{ textAlign: "center" }}>{item["id"]}</td>
                        <td style={{ textAlign: "center" }}>
                          {item["statusString"]}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {numberWithCurrencyFormat(
                            parseFloat(item["validTurnover"])
                          )}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {numberWithCurrencyFormat(parseFloat(item["amount"]))}
                        </td>
                        <td style={style}>
                          {isGrouped
                            ? numberWithCurrencyFormat(
                                parseFloat(item["winAmount"]) -
                                  parseFloat(item["amount"])
                              )
                            : item["status"] == 1
                            ? numberWithCurrencyFormat(
                                parseFloat(item["winAmount"]) -
                                  parseFloat(item["amount"])
                              )
                            : "0.00"}
                        </td>
                      </tr>
                    );
                  }}
                />
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4">
                    <b>{t("TOTAL")}</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>
                      {numberWithCurrencyFormat(
                        parseFloat(
                          gameRecord.reduce(
                            (totalvt, data) => totalvt + data.validTurnover,
                            0
                          )
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>
                      {numberWithCurrencyFormat(
                        parseFloat(
                          gameRecord.reduce(
                            (totala, data) => totala + data.amount,
                            0
                          )
                        )
                      )}
                    </b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>
                      {numberWithCurrencyFormat(
                        parseFloat(
                          gameRecord.reduce(
                            (totalp, data) =>
                              totalp +
                              (isGrouped
                                ? data.winAmount - data.amount
                                : data.status == 1
                                ? data.winAmount - data.amount
                                : 0),
                            0
                          )
                        )
                      )}
                    </b>
                  </td>{" "}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isModalVisible}
          toggle={() => {
            setIsModalVisible(false);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setIsModalVisible(false);
            }}
          >
            {t("GAME_DETAILS")}
          </ModalHeader>
          <ModalBody className="game-detail-wrapper">
            <div className="game-detail-head">
              <strong className="text-brand f-s-25">
                {gameDetails["betId"]}
              </strong>
              <div className="text-right">
                <span className="badge badge-brand">
                  {gameDetails["statusString"]}
                </span>
                <div className="game-detail-timestamp">
                  {gameDetails["dateStr"]}
                </div>
              </div>
            </div>
            <div className="game-detail-list">
              <label>{t("AMOUNT")}</label>
              <div>{numberWithCurrencyFormat(gameDetails["amount"])}</div>
            </div>
            <div className="game-detail-list">
              <label>{t("NET_PROFIT")}</label>
              <div>
                {numberWithCurrencyFormat(
                  parseFloat(gameDetails["winAmount"]) -
                    parseFloat(gameDetails["amount"])
                )}
              </div>
            </div>
            <div className="game-detail-list">
              <label>{t("DESCRIPTION")}</label>
              <pre className="game-detail-desc">{gameDetails["remark"]}</pre>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default GameHistory;
