import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  BankingChannel,
  PanelType,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import Dropzone from "react-dropzone";
import StyledSelect from "../../components/custom/StyledSelect";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalBody } from "reactstrap";
import { useLongPress } from "use-long-press";
import Numpad from "../../components/custom/Numpad";

/// <summary>
/// Author: -
/// </summary>
const NormalDeposit = (props) => {
  const { t, i18n } = useTranslation();
  let _history = useHistory();
  let _userData = useSelector((state) => state.authState.userData);
  let _dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    errors,
    reset,
  } = useForm();
  const [bankOption, setBankOption] = useState([]);
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [receiptImage, setReceiptImage] = useState();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [isReloadSuccess, setIsReloadSuccess] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);
  const [reloadDate, setReloadDate] = useState();
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [canDisplayBank, setCanDisplayBank] = useState(false);
  const [userVipLevel, setUserVipLevel] = useState(0);
  const [tngImg, setTngImg] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  async function init() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_USER_BANK + "?type=TNG"
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let bankList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
        setValue("bankId", bank.id);
        bankList.push({
          label: bank.bankName,
          value: bank.id,
          min: bank.minimumDeposit,
          max: bank.maximumDeposit,
        });
        //setTngImg(bank.img);
      });
      let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=DailyQR";
      let TnGJson = await ApiEngine.get(apiUrl);
      if (
        TnGJson[ApiKey._API_SUCCESS_KEY] &&
        TnGJson[ApiKey._API_DATA_KEY] != null
      ) {
        let data = TnGJson[ApiKey._API_DATA_KEY];
        setTngImg(data);
      }
      getBankAccountByBankId();
      setBankOption(bankList);
    }

    responseJson = await ApiEngine.get(
      ApiUrl._API_CHECK_DISPLAY_BANK_DETAIL
    ).then((response) => {
      setCanDisplayBank(response[ApiKey._API_SUCCESS_KEY]);
    });
    responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_VIP_LEVEL);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUserVipLevel(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBankAccountByBankId() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID +
        "?bankId=" +
        watch("bankId") +
        "&deposit=" +
        true
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let bankAccountList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
        setValue("bankAccountId", bankAccount.id);
        setMinMax({
          min: bankAccount.minimumDeposit,
          max: bankAccount.maximumDeposit,
        });
        bankAccountList.push({
          label: bankAccount.accountHolderName,
          value: bankAccount.id,
          accNumber: bankAccount.accountNumber,
          min: bankAccount.minimumDeposit,
          max: bankAccount.maximumDeposit,
        });
      });

      setBankAccountOption(bankAccountList);
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(watch("bankId"))) {
      setValue("bankAccountId", null);
      setBankAccountNumber("");
      getBankAccountByBankId();
    }
  }, [watch("bankId")]);

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setValue("receipt", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const remove = () => {
    setReceiptImage("");
    setValue("receipt", null);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function resetFormField() {
    setBankAccountNumber("");
    setBankAccountOption([]);
    setValue("bankId", "");
    setValue("bankAccountId", "");
    setValue("receipt", null);
    setReceiptImage("");
    setValue("depositDateTime", moment().format("YYYY-MM-DD HH:mm:ss"));
  }

  function setMinMax(e) {
    setMin(e.min);
    setMax(e.max);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      amount: data.amount,
      channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
      panel: PanelType._MEMBERSITE,
      bankAccountId: data.bankAccountId,
      receiptReference: "TNG: " + data.reference,
      transactionDateTime: data.depositDateTime,
    };

    params["receipt"] = data.receipt;
    if (!stringIsNullOrEmpty(data.receipt)) {
    }
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setPaidAmount(params.amount);
      setIsReloadSuccess(true);
      setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
      props.setTriggerParentInit(true);
      e.target.reset();
      resetFormField();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }

  const bindLongPressEvents = useLongPress(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "download",
          url: tngImg,
        })
      );
    }
  });

  return (
    <>
      <form class="deposit-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group m-b-15" style={{ display: "none" }}>
          <label className="col-form-label">
            <b>{t("BANK")}</b>
          </label>
          <div>
            <Controller
              control={control}
              name="bankId"
              defaultValue={""}
              render={({ onChange, value }) => {
                return (
                  <StyledSelect
                    options={bankOption}
                    placeholder={t("PLEASE_SELECT_BANK")}
                    value={value}
                    onChange={(e) => {
                      setMin(e.min);
                      setMax(e.max);
                      onChange(e.value);
                    }}
                  />
                );
              }}
            />
            {errors.bankId && (
              <div className="invalid-feedback">{t(errors.bankId.message)}</div>
            )}
          </div>
        </div>

        {!stringIsNullOrEmpty(getValues("bankId")) && (
          <>
            <div className="form-group m-b-15" style={{ display: "none" }}>
              <label className="col-form-label">
                <b>{t("BANK_ACCOUNT")}</b>
              </label>
              <div>
                <Controller
                  control={control}
                  name="bankAccountId"
                  defaultValue={""}
                  render={({ onChange, value }) => {
                    return (
                      <StyledSelect
                        options={bankAccountOption}
                        placeholder={t("PLEASE_SELECT_BANK_ACCOUNT")}
                        value={value}
                        onChange={(e) => {
                          onChange(e.value);
                          setBankAccountNumber(e.accNumber);
                          setMinMax(e);
                        }}
                      />
                    );
                  }}
                  rules={{ required: "PLEASE_SELECT_BANK_ACCOUNT" }}
                />
                {errors.bankAccountId && (
                  <div className="invalid-feedback">
                    {t(errors.bankAccountId.message)}
                  </div>
                )}
              </div>
            </div>
            {/* {!stringIsNullOrEmpty(watch("bankAccountId")) && ( */}
            <>
              {/**
               * Allowing mobile app user to download image after long press on TnGo qrcode image
               */}
              {window.ReactNativeWebView ? (
                <div
                  className="form-group m-b-15 text-center"
                  {...bindLongPressEvents()}
                >
                  <img src={tngImg} width="100%" />
                </div>
              ) : (
                <div className="form-group m-b-15 text-center">
                  <img src={tngImg} width="100%" />
                </div>
              )}
              <div className="form-group m-b-15 text-center">
                <a
                  href="https://youtu.be/ertSjk2cF7g"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  {t("HOW_TO_TNG")}
                </a>
              </div>{" "}
            </>
            {/* )} */}
          </>
        )}

        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("AMOUNT")}</b>
          </label>
          <div>
            <input
              type="number"
              readOnly
              className="form-control bg-white amount-text"
              placeholder={t("PLEASE_ENTER_AMOUNT")}
              name="amount"
              style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
              onClick={(e) => {
                setCurrentInput(e.target);
              }}
              ref={register({
                required: "PLEASE_ENTER_AMOUNT",
                validate: {
                  minValue: (value) =>
                    (!canDisplayBank
                      ? value >= min
                      : parseFloat(value) > 0 && value >= min) ||
                    "VALUE_LESS_THAN_MINIMUM",
                  maxValue: (value) =>
                    (!canDisplayBank ? true : parseFloat(value) <= max) ||
                    "EXCEED_MAXIMUM_VALUE",
                },
              })}
            />
            {errors.amount && (
              <div className="invalid-feedback">{t(errors.amount.message)}</div>
            )}
            {min != 0 && max != 0 && (
              <div className="col-md-12">
                <span className="reminder">
                  * Min: {min}/ Max: {max}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("RECEIPT_REFERENCE")}</b>
          </label>
          <div>
            <input
              type="text"
              className="form-control"
              name="reference"
              ref={register({ required: "PLEASE_ENTER_RECEIPT_REFERENCE" })}
              placeholder={t("PLEASE_ENTER_RECEIPT_REFERENCE")}
              style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
            />
            {errors.reference && (
              <div className="invalid-feedback">
                {t(errors.reference.message)}
              </div>
            )}
          </div>
        </div>
        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("DEPOSIT_DATE_TIME")}</b>
          </label>
          <div>
            <Controller
              control={control}
              name="depositDateTime"
              defaultValue={""}
              rules={{ required: "FIELD_REQUIRED" }}
              render={({ onChange, value }) => {
                return (
                  <DateRangePicker
                    containerStyles={{
                      width: "100%",
                    }}
                    timePicker={true}
                    singleDatePicker={true}
                    timePickerSeconds={true}
                    locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                    onApply={(event, picker) => {
                      onChange(
                        moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                      );
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={value}
                      style={{
                        fontSize: "14px",
                        color: "#002e6c",
                        fontWeight: "bold",
                      }}
                    />
                  </DateRangePicker>
                );
              }}
            />
            {errors.depositDateTime && (
              <div className="invalid-feedback">
                {t(errors.depositDateTime.message)}
              </div>
            )}
          </div>
        </div>
        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("RECEIPT")}</b>
          </label>
          <div>
            <Controller
              control={control}
              name="receipt"
              defaultValue={""}
              render={({ onChange, value }) => {
                return (
                  <Dropzone
                    accept={"image/*"}
                    onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ position: "relative" }}>
                        {!stringIsNullOrEmpty(receiptImage) && (
                          <div
                            style={{
                              background: "transparent",
                              padding: "5px 10px",
                              textAlign: "center",
                              position: "absolute",
                              right: "5px",
                              top: "5px",
                            }}
                            onClick={() => remove()}
                          >
                            <i className="fa fa-trash text-red"></i>
                          </div>
                        )}
                        <div
                          className="dropzone"
                          style={{ minHeight: "200px", textAlign: "center" }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <h4 style={{ color: "grey" }}>
                            {t(
                              "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                            )}
                          </h4>
                          {!stringIsNullOrEmpty(receiptImage) && (
                            <aside className="thumbsContainer">
                              <div className="thumb">
                                <div className="thumbInner">
                                  <img
                                    src={receiptImage}
                                    className="dropzone-img"
                                  />
                                </div>
                              </div>
                            </aside>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                );
              }}
            />
          </div>
        </div>
        <div style={{ margin: "1rem 0px" }}>
          <button type="submit" className="btn btn-block btn-brand-gradient">
            {t("SUBMIT")}
          </button>
        </div>
      </form>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-reload-success"
        isOpen={isReloadSuccess}
        toggle={() => {
          setIsReloadSuccess(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <strong className="reload-title text-yellow mb-3 d-block">
              {t("RELOAD_SUCCESS")}
            </strong>
            <div className="text-brand text-bo">
              {t("PAID_DEPOSIT", { amount: paidAmount })}
            </div>
          </div>
          <table className="table text-brand table-reload-detail">
            <tr>
              <th>{t("DEPOSIT_DATE_TIME")}</th>
              <th style={{ textAlign: "right" }}>{reloadDate}</th>
            </tr>
            <tr>
              <th>{t("REFERENCE_NUMBER")}</th>
              <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
            </tr>
          </table>
          <button
            className="btn btn-block btn-brand-gradient"
            onClick={() => {
              setIsReloadSuccess(false);
            }}
          >
            {t("DONE")}
          </button>
        </ModalBody>
      </Modal>
      <Numpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          setCurrentInput(e);
        }}
      />
    </>
  );
};

export default NormalDeposit;
