import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  BankingChannel,
  PanelType,
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
} from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import Dropzone from "react-dropzone";
import StyledSelect from "../../components/custom/StyledSelect";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalBody, Alert } from "reactstrap";
import Numpad from "../../components/custom/Numpad";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiClose } from "@mdi/js";
import Input from "../../components/custom/Input";

/// <summary>
/// Author: -
/// </summary>
const NormalDeposit = (props) => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    let _userData = useSelector((state) => state.authState.userData);
    let _dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        getValues,
        errors,
        reset,
    } = useForm();
    const [bankOption, setBankOption] = useState([]);
    const [bankAccountOption, setBankAccountOption] = useState([]);
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const [receiptImage, setReceiptImage] = useState();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [bankAccountId, setBankAccountId] = useState("");
    const [isReloadSuccess, setIsReloadSuccess] = useState(false);
    const [paidAmount, setPaidAmount] = useState(0);
    const [reloadDate, setReloadDate] = useState();
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [currentInput, setCurrentInput] = useState();
    const [canDisplayBank, setCanDisplayBank] = useState(true);
    const [userVipLevel, setUserVipLevel] = useState(0);
    const [imageFileSize, setImageFileSize] = useState(0);
    const ONE_MB_IN_BYTES = 1048576;
    const fileSizeLimit_InMB = 20;
    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const _ALERT_TIMEOUT = 3000;
    let _copyNameRef = useRef();
    let _copyAccountNumberRef = useRef();
    const fixedAmount = [20, 50, 100, 200, 500];
    const [amountValue, setAmountValue] = useState(0);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    async function init() {
        getBankAccountByBankId();
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_USER_BANK + "?type=DEPOSIT"
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let bankList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
                bankList.push({
                    label: bank.bankName,
                    value: bank.id,
                    min: bank.minimumDeposit,
                    max: bank.maximumDeposit,
                });
            });
            setBankOption(bankList);
        }

        //responseJson = await ApiEngine.get(ApiUrl._API_CHECK_DISPLAY_BANK_DETAIL)
        //    .then((response) => {
        //        setCanDisplayBank(response[ApiKey._API_SUCCESS_KEY]);
        //    })
        responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_VIP_LEVEL);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setUserVipLevel(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function getBankAccountByBankId() {
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_BANK_ACCOUNT +
            "?deposit=" +
            true
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let bankAccountList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
                bankAccountList.push({
                    label: bankAccount.accountHolderName,
                    value: bankAccount.id,
                    accNumber: bankAccount.accountNumber,
                    min: bankAccount.minimumDeposit,
                    max: bankAccount.maximumDeposit,
                    bankName : bankAccount.bankName
                });
            });

            setBankAccountOption(bankAccountList);
        }
    }


  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const fileSizeInMB = file.size / ONE_MB_IN_BYTES;
      setImageFileSize(fileSizeInMB);
      setValue("receipt", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const remove = () => {
    setReceiptImage("");
    setValue("receipt", null);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function resetFormField() {
    setBankAccountNumber("");
    setBankAccountOption([]);
    setValue("bankId", "");
    setValue("bankAccountId", "");
    setValue("receipt", null);
    setReceiptImage("");
    setValue("depositDateTime", moment().format("YYYY-MM-DD HH:mm:ss"));
  }

  function setMinMax(e) {
    setMin(e.min);
    setMax(e.max);
    }

    function setMinMaxButton(min,max,bankId) {
        setMin(min);
        setMax(max);
        setBankAccountId(bankId);
    }

  /// <summary>
  /// Author : -
  /// </summary>
  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      amount: data.amount,
      channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
      panel: PanelType._MEMBERSITE,
      bankAccountId: data.bankAccountId,
      receiptReference: data.reference,
      transactionDateTime: data.depositDateTime,
    };
    if (!stringIsNullOrEmpty(bankAccountId)) {
        params["bankAccountId"] = bankAccountId;
    }
    params["receipt"] = data.receipt;
    if (!stringIsNullOrEmpty(data.receipt)) {
    }
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setPaidAmount(params.amount);
      setIsReloadSuccess(true);
      setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
      props.setTriggerParentInit(true);
      e.target.reset();
      resetFormField();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }

  function copyText(label) {
      navigator.clipboard.writeText(label);
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  return (
    <>
      <form class="deposit-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group m-b-0">
          <label className="col-form-label">
            <b>{t("BANK")}</b>
          </label>
          <div class="row">
            <div class="col-12">
                          
              <table class="table deposit-table">
                <tbody>
                    {
                        bankAccountOption.map((bank, index) => {
                            return (
                                <>
                                    <tr>
                                        <td class="border-bottom"></td>
                                        <td class="bank-text">{bank.bankName}</td>
                                    </tr>
                                    <tr>
                                    <td class="border-top border-bottom">
                                        <div class="form-check custom-checkbox">
                                        <input
                                            class="form-check-input custom-form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            onClick={() => setMinMaxButton(bank.min,bank.max,bank.value)}
                                        />
                                        </div>
                                    </td>

                                    <td class="bank-text">
                                            <span>{bank.label}</span>
                                        <div
                                        class="float-right-icon"
                                        onClick={() => copyText(bank.label)}
                                            >
                                        <Icon path={mdiContentCopy} size={1} />
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="border-top"></td>
                                    <td class="bank-text">
                                        <span>{bank.accNumber}</span>
                                        <div
                                            class="float-right-icon"
                                            onClick={() => copyText(bank.accNumber)}
                                        >
                                            <Icon path={mdiContentCopy} size={1} />
                                        </div>
                                    </td>
                                    </tr>
                                </>
                            )
                        }
                        )
                    }
                </tbody>
              </table>
            </div>
          </div>
          {/* 
          <div>
            <Controller
              control={control}
              name="bankId"
              defaultValue={""}
              render={({ onChange, value }) => {
                return (
                  <StyledSelect
                    options={bankOption}
                    placeholder={t("PLEASE_SELECT_BANK")}
                    value={value}
                    onChange={(e) => {
                      setMin(e.min);
                      setMax(e.max);
                      onChange(e.value);
                    }}
                  />
                );
              }}
            />
            {errors.bankId && (
              <div className="invalid-feedback">{t(errors.bankId.message)}</div>
            )}
          </div> */}
        </div>
        {!stringIsNullOrEmpty(getValues("bankId")) && (
          <>
            <div className="form-group m-b-15">
              <label className="col-form-label">
                <b>{t("BANK_ACCOUNT")}</b>
              </label>
              <div>
                <Controller
                  control={control}
                  name="bankAccountId"
                  defaultValue={""}
                  render={({ onChange, value }) => {
                    return (
                      <StyledSelect
                        options={bankAccountOption}
                        placeholder={t("PLEASE_SELECT_BANK_ACCOUNT")}
                        value={value}
                        onChange={(e) => {
                          onChange(e.value);
                          setBankAccountNumber(e.accNumber);
                          setMinMax(e);
                        }}
                      />
                    );
                  }}
                  rules={{ required: "PLEASE_SELECT_BANK_ACCOUNT" }}
                />
                {errors.bankAccountId && (
                  <div className="invalid-feedback">
                    {t(errors.bankAccountId.message)}
                  </div>
                )}
              </div>
            </div>
            {!stringIsNullOrEmpty(watch("bankAccountId")) && (
              <div className="form-group m-b-15">
                <label className="col-form-label">
                  <b>{t("BANK_ACCOUNT_NUMBER")}</b>
                </label>
                {!canDisplayBank ? (
                  <div
                    className="card-brand"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50px",
                      padding: "0.5rem",
                      fontSize: "80%",
                    }}
                  >
                    <div className="text-center">
                      <strong
                        className="text-brand"
                        style={{
                          color: userVipLevel === 1 ? "red" : "#002e6c",
                        }}
                      >
                        {t("PLEASE_CONTACT_CS_BANK_DETAILS")}
                      </strong>
                    </div>
                  </div>
                ) : (
                  <div
                    className="card-brand"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50px",
                      padding: "0.5rem 1.5rem",
                    }}
                  >
                    {bankAccountNumber}
                  </div>
                )}
              </div>
            )}
          </>
        )}

        <div className="form-group m-b-0">
          <label className="col-form-label">
            <b>{t("AMOUNT")}</b>
          </label>
          <div class="deposit input-group">
            <input
              type="number"
              readOnly
              className="form-control bg-white amount-text"
              //   placeholder={t("PLEASE_ENTER_AMOUNT")}
              placeholder={amountValue}
              value={amountValue}
              name="amount"
              style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
              onClick={(e) => {
                setCurrentInput(e.target);
              }}
              ref={register({
                required: "PLEASE_ENTER_AMOUNT",
                validate: {
                  minValue: (value) =>
                    (!canDisplayBank
                      ? value >= min
                      : parseFloat(value) > 0 && value >= min) ||
                    "VALUE_LESS_THAN_MINIMUM",
                  maxValue: (value) =>
                    (!canDisplayBank ? true : parseFloat(value) <= max) ||
                    "EXCEED_MAXIMUM_VALUE",
                },
              })}
            />

            <div class="close-button">
              <Icon
                path={mdiClose}
                size={1}
                color="#FF0808"
                onClick={() => {
                  setAmountValue(0);
                }}
              />
            </div>
          </div>
          {errors.amount && (
            <div className="invalid-feedback">{t(errors.amount.message)}</div>
          )}
          {min != 0 && max != 0 && (
            <div className="col-md-12">
              <span className="reminder">
                * Min: {min}/ Max: {max}
                          </span>
            </div>
          )}

          <div class="amount-row">
            {fixedAmount.map((amount, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  onClick={() => {
                    setAmountValue(
                      (parseFloat(amount) + parseFloat(amountValue)).toFixed(2)
                    );
                  }}
                  className="btn custom-button"
                >
                  + {amount}
                </button>
              );
            })}
          </div>
        </div>
        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("RECEIPT_REFERENCE")}</b>
          </label>
          <div>
            <input
              type="text"
              className="form-control"
              name="reference"
              ref={register({ required: "PLEASE_ENTER_RECEIPT_REFERENCE" })}
              placeholder={t("PLEASE_ENTER_RECEIPT_REFERENCE")}
              style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
            />
            {errors.reference && (
              <div className="invalid-feedback">
                {t(errors.reference.message)}
              </div>
            )}
          </div>
        </div>
        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("DEPOSIT_DATE_TIME")}</b>
          </label>
          <div>
            <Controller
              control={control}
              name="depositDateTime"
              defaultValue={""}
              rules={{ required: "FIELD_REQUIRED" }}
              render={({ onChange, value }) => {
                return (
                  <DateRangePicker
                    containerStyles={{
                      width: "100%",
                    }}
                    timePicker={true}
                    timePickerSeconds={true}
                    singleDatePicker={true}
                    locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                    onApply={(event, picker) => {
                      onChange(
                        moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                      );
                    }}
                  >
                    <input
                      type="text"
                      className="form-control bg-white"
                      defaultValue={value}
                      style={{
                        fontSize: "14px",
                        color: "#002e6c",
                        fontWeight: "bold",
                      }}
                      readOnly
                    />
                  </DateRangePicker>
                );
              }}
            />
            {errors.depositDateTime && (
              <div className="invalid-feedback">
                {t(errors.depositDateTime.message)}
              </div>
            )}
          </div>
        </div>
        <div className="form-group m-b-15">
          <label className="col-form-label">
            <b>{t("RECEIPT")}</b>
            {" (" + t("MAX_FILE_SIZE") + ": " + fileSizeLimit_InMB + "MB)"}
          </label>
          <div>
            <Controller
              control={control}
              name="receipt"
              defaultValue={""}
              render={({ onChange, value }) => {
                return (
                  <Dropzone
                    accept={"image/*"}
                    onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                    maxSize={fileSizeLimit_InMB * ONE_MB_IN_BYTES}
                    onDropRejected={() =>
                      _dispatch(
                        showResponseMessage(
                          false,
                          t("FILE_LARGER_THAN") +
                            fileSizeLimit_InMB +
                            "MB, " +
                            t("PLEASE_TRY_AGAIN")
                        )
                      )
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <section style={{ position: "relative" }}>
                          {!stringIsNullOrEmpty(receiptImage) && (
                            <div
                              style={{
                                background: "transparent",
                                padding: "5px 10px",
                                textAlign: "center",
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                              }}
                              onClick={() => remove()}
                            >
                              <i className="fa fa-trash text-red"></i>
                            </div>
                          )}
                          <div
                            className="dropzone"
                            style={{ minHeight: "200px", textAlign: "center" }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            {stringIsNullOrEmpty(receiptImage) && (
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                            )}
                            {!stringIsNullOrEmpty(receiptImage) && (
                              <aside className="thumbsContainer">
                                <div className="thumb">
                                  <div className="thumbInner">
                                    <img
                                      src={receiptImage}
                                      className="dropzone-img"
                                    />
                                  </div>
                                </div>
                              </aside>
                            )}
                          </div>
                        </section>
                        {!stringIsNullOrEmpty(receiptImage) ? (
                          <div style={{ color: "#002e6c", fontSize: "15px" }}>
                            {t("UPLOADED_FILE_SIZE") +
                              ": " +
                              numberWithCurrencyFormat(imageFileSize, 3, true) +
                              "MB"}
                          </div>
                        ) : (
                          <div>&nbsp;</div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                );
              }}
            />
          </div>
        </div>

        {/* <div style={{ margin: "1rem 0px" }}> */}
        <button type="submit" className="btn btn-block btn-brand-gradient">
          {t("SUBMIT")}
        </button>
        {/* </div> */}
      </form>

      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-reload-success"
        isOpen={isReloadSuccess}
        toggle={() => {
          setIsReloadSuccess(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <strong className="reload-title text-yellow mb-3 d-block">
              {t("RELOAD_SUCCESS")}
            </strong>
            <div className="text-brand text-bo">
              {t("PAID_DEPOSIT", { amount: paidAmount })}
            </div>
          </div>
          <table className="table text-brand table-reload-detail">
            <tr>
              <th>{t("DEPOSIT_DATE_TIME")}</th>
              <th style={{ textAlign: "right" }}>{reloadDate}</th>
            </tr>
            <tr>
              <th>{t("REFERENCE_NUMBER")}</th>
              <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
            </tr>
          </table>
          <button
            className="btn btn-block btn-brand-gradient"
            onClick={() => {
              setIsReloadSuccess(false);
            }}
          >
            {t("DONE")}
          </button>
        </ModalBody>
      </Modal>
      <Numpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          setAmountValue(currentInput.value);
          setCurrentInput(e);
        }}
      />

      {showCopyAlert && (
        <div
          className="content content-full-width"
          style={{
            zIndex: "9999",
            position: "absolute",
            top: "5px",
            right: "5px",
            width: "200px",
          }}
        >
          <Alert color="green" style={{ textAlign: "center" }}>
            {t("COPY_SUCCESS")}
          </Alert>
        </div>
      )}
    </>
  );
};

export default NormalDeposit;
