/// <summary>
/// Author : 
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_CODE_KEY = "code";

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";
}

/// <summary>
/// Author : 
/// Url to communicate to controller
/// </summary> 
export class ApiUrl {
    static _API_LOGIN = "/Account/Login";
    static _API_LOGOUT = "/Account/Logout";
    static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
    static _API_GET_USER_PAYMENT_GATEWAY_INFO = "/Account/GetPaymentGatewayInfo";
    static _API_SEND_RESET_PASSWORD_SMS = "/Account/SendResetPasswordSms";
    static _API_CREATE_OR_UPDATE_MEMBER = "/Account/CreateOrUpdateMember";
    static _API_GET_USER_ANNOUNCEMENT = "/Account/GetUserAnnouncement";
    static _API_GET_COMPANY_CONTACT_US = "/Account/GetCompanyContactUs";

    static _API_UPDATE_USER_DATA = "/Account/UpdateUserData";

    static _API_GET_LOGIN_FAILED_COUNT = "/Account/GetLoginFailCount";
    static _API_MANAGE_MEMBER_LOGIN_FAIL_COUNT = "/Account/ManageMemberLoginFailCount";

    static _API_GET_MEMBER_DETAILS_BY_ID = "/Member/GetMemberDetailsById";
    static _API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT = "/Member/GetMemberDownlineGameByMasterProduct";
    static _API_UPDATE_DOWNLINE_MEMBER_GAME = "/Game/UpdateDownlineMemberGame";
    static _API_GET_MEMBER_DETAILS_DASHBOARD = "/Member/GetMemberDetailsDashboard";
    static _API_GET_NOTIFICATION_LIST = "/Member/GetNotificationList";
    static _API_GET_VIDEO = "/Member/GetVideo";
    static _API_MEMBER_RESET_PASSWORD = "/Member/ResetPassword";
    static _API_GET_ANNOUNCEMENT = "/Member/GetAnnouncement";
    static _API_MARK_ANNOUNCEMENT_AS_READ = "/Member/MarkAnnouncementRead";
    static _API_GET_USER_MEMBERS = "/Member/GetUserMember";
    static _API_GET_USER_MEMBER_LIST = "/Member/GetUserMemberList";
    static _API_GET_MEMBER_PRODUCT_RATES = "/Member/GetMemberProductRates";
    static _API_GET_MEMBER_UPLINE_PRODUCT_RATES = "/Member/GetUplineProductRates";
    static _API_GET_MEMBER_REFERRAL_CODES = "/Member/GetMemberReferralCodes";
    static _API_DEL_REFERRAL = "/Member/DelMemberReferralCode";
    static _API_GENERATE_MEMBER_REFERRAL_CODE = "/Member/GenerateReferralCode";
    static _API_GET_UPLINE_BY_REFERRAL_CODE = "/Member/GetUplineByReferralCode";
    static _API_CHECK_USERNAME = "/Member/CheckUsername";
    static _API_GET_MEMBER_SUMMARY_KPI = "/Member/GetMemberSummaryKpi";
    static _API_GET_MEMBER_DETAIL_KPI = "/Member/GetMemberKpiDetail";
    static _API_UPDATE_MEMBER_NICKNAME = "/Member/UpdateMemberNickname";
    static _API_CHECK_MEMBER_PIN_NUMBER = "/Member/CheckMemberPinNumber";
    static _API_SET_MEMBER_PIN_NUMBER = "/Member/SetMemberPinNumber";
    static _API_RESET_MEMBER_PIN_NUMBER = "/Member/ResetMemberPinNumber";
    static _API_CHECK_SHAREHOLDER_PIN_NUMBER = "/Member/CheckShareholderPinNumber";
    static _API_SET_SHAREHOLDER_PIN_NUMBER = "/Member/SetShareholderPinNumber";
    static _API_RESET_SHAREHOLDER_PIN_NUMBER = "/Member/ResetShareholderPinNumber";
    static _API_CHECK_IF_PHONE_VERIFIED = "/Member/CheckIfPhoneVerified";
    static _API_GET_COMPANY_SKIN_SETTINGS = "/Member/GetCompanySkinSettings";

    static _API_GET_MEMBER_PRODUCT_DETAILS = "/Member/GetMemberProductDetails";

    static _API_CREATE_OR_UPDATE_DOWNLINE_GROUP = "/Member/CreateOrUpdateDownlineGroup";
    static _API_ADD_OR_REMOVE_MEMBER_INTO_GROUP = "/Member/AddOrRemoveMemberIntoGroup";
    static _API_GET_DOWNLINE_GROUPS = "/Member/GetDownlineGroups";
    static _API_GET_DOWNLINE_GROUP_DETAIL_BY_ID = "/Member/GetDownlineGroupDetailById";
    static _API_DELETE_DOWNLINE_GROUP = "/Member/DeleteDownlineGroup";
    static _API_GET_UNGROUP_MEMBER_LIST = "/Member/GetUngroupMemberList";

    static _API_GET_UNCLAIM_COMMISSION_AMT = "/Member/GetUnclaimCommissionAmt";

    static _API_CHECK_HIDE_SUMMARY = "/Member/CheckHideSummary";

    static _API_GET_USER_FULLNAME = "/Member/GetUserFullName";

    static _API_GET_MEMBER_WALLET_STATEMENT = "/Transaction/GetMemberWalletStatement";
    static _API_GET_MEMBER_REWARD_HISTORY = "/Transaction/GetMemberRewardHistory";
    static _API_GET_TRANSACTION_BY_MEMBER_ID = "/Transaction/GetTransactionByMemberId";
    static _API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL = "/Transaction/GetTransactionByMemberDepositAndWithdrawal";
    static _API_GET_ROLLOVER_RECORD = "/Transaction/GetRolloverRecord";
    static _API_CREATE_TRANSACTION = "/Transaction/CreateTransaction";
    static _API_GET_BONUS_HISTORY = "/Transaction/GetBonusHistory";
    static _API_GET_INTERNAL_TRANSFER = "/Transaction/GetInternalTransferHistory";
    static _API_GET_COMMISSION_HISTORY = "/Transaction/GetCommissionHistory";
    static _API_GET_PENDING_TRANSACTION = "/Transaction/CheckPendingTransaction";

    static _API_GET_CLAIM_HISTORY = "/Transaction/GetClaimHistory";

    static _API_GET_GAME_RECORD_BY_MEMBER = "/Game/GetProductGameRecordByMember";

    static _API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER = "/Bank/GetMemberBankAccountByMember";
    static _API_CREATE_OR_UPDATE_MEMBER_BANK_ACCOUNT = "/Bank/CreateOrUpdateMemberBankAccount";
    static _API_GET_USER_BANK = "/Bank/GetUserBank";
    static _API_GET_BANK_ACCOUNT_BY_BANK_ID = "/Bank/GetBankAccountByBankId";
    static _API_GET_BANK_ACCOUNT = "/Bank/GetBankAccount";

    static _API_CREATE_WALLET_TRANSFER_TRANSACTION = "/Transaction/CreateWalletTransferTransaction";

    static _API_GET_NON_SEAMLESS_PRODUCT_BALANCE = "/Game/GetNonSeamlessProductBalance";
    static _API_GET_ASSIGNED_MEMBER_PRODUCT = "/Game/GetAssignedMemberProduct";
    static _API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST = "/Game/GetAssignedMemberProductShowList";
    static _API_UPDATE_MEMBER_PRODUCT_RATE = "/Game/UpdateMemberProductRate";
    static _API_UPDATE_DOWNLINE_PRODUCT_RATE = "/Game/UpdateDownlineProductRate";
    static _API_UPDATE_MEMBER_PRODUCT_ACTIVENESS = "/Game/UpdateMemberProductActiveness";
    static _API_GET_POOL_RESULT = "/Game/GetPoolResult";
    static _API_GET_PRODUCT_WITH_MAX_RATE = "/Game/GetGroupedProductWithMaxRates";
    static _API_GET_CATEGORY_GROUP_GAME = "/Game/GetCategoryGroupedGame";
    static _API_GET_CATEGORY_GROUP_GAME_DASHBOARD = "/Game/GetCategoryGroupedGameDashboard";

    static _API_GET_MASTER_PRODUCT_GROUP_GAME = "/Game/GetMasterProductGroupedGame";
    static _API_GET_MASTER_PRODUCT_GROUP_GAME_BY_GAME_ID = "/Game/GetMasterProductGroupedGameByGameId";
    static _API_GET_RECOMMENDED_MASTER_PRODUCT_GROUP_GAME = "/Game/GetRecommendedMasterProductGroupedGame";
    static _API_GET_MASTER_PRODUCT_BY_ID = "/Game/GetMasterProductById";
    static _API_GET_IS_NEW_MASTER_PRODUCT_GROUP_GAME = "/Game/GetIsNewMasterProductGroupedGame";

    static _API_CHECK_IF_NON_SEAMLESS = "/Game/CheckIfNonSeamless";
    static _API_CHANGE_PRODUCT_PASSWORD = "/Game/ChangeProductPassword";
    static _API_GET_GAME_IS_NEW_WINDOW = "/Game/GetGameIsNewWindow";

    static _API_START_GAME = "/Game/StartGame";
    static _API_GET_BET_DETAILS = "/Game/GetProductGameRecordById";
    static _API_GET_COMMUNICATION_CHANNELS = "/Member/GetCommunicationChannel";

    static _API_SEND_VERIFICATION_CODE = "/Member/SendVerificationCode";
    static _API_SEND_PIN_CODE = "/Member/SendPinCode";
    static _API_VERIFY_PHONE_NUMBER = "/Member/VerifyPhoneNumber";
    static _API_CHECK_DISPLAY_BANK_DETAIL = "/Bank/CheckBankDetails";

    static _API_GET_USER_BANNER = "/Member/GetUserBanner";
    static _API_MANAGE_BANNER_NOTIFICATION = "/Member/ManageBannerNotification";
    static _API_GET_USER_REWARD_BANNER = "/Member/GetUserRewardBanner";
    static _API_GET_PRODUCT_CATEGORY = "/Member/GetProductCategory";
    static _API_GET_PRODUCT_CATEGORY_WITH_MASTER_PRODUCT = "/Member/GetProductCategoryWithMasterProduct";
    static _API_GET_MASTER_PRODUCT_CATEGORY = "/Member/GetMasterProductCategory";
    static _API_GET_USER_VIP_LEVEL = "/Member/GetUserVipLevel";

    static _API_GET_MEMBER_SHAREHOLDER_DETAIL = "/Member/GetMemberShareholderDetail";
    static _API_GET_MEMBER_SHAREHOLDER_UNCLAIM = "/Member/GetMemberShareholderUnclaim";
    static _API_UPDATE_SHAREHOLDER = "/Member/UpdateShareholder";
    static _API_CLAIM_SHAREHOLDER = "/Member/ClaimShareholder";
    static _API_CLAIM_COMMISSION = "/Member/ClaimCommission";
    static _API_CLAIM_REWARD = "/Member/ClaimReward";

    static _API_UPDATE_MEMBER_THEME = "/Member/UpdateMemberTheme";

    static _API_CHECK_PHONE_NUMBER = "/Member/CheckPhoneNumber";

    static _API_GET_COMPANY_TNG_SYSTEM_SETTINGS = "/Member/GetCompanyTnGSystemSettings";
    static _API_GET_FLAG_TRANSFER_BACK_MAIN_WALLET = "/Game/GetFlagTransferBackMainWallet";

    static _API_SPG_CHECK = "/Transaction/CheckSPG";

    static _API_SEND_TAC_CODE = "/Member/SendTACCode";
    static _API_VERIFY_TAC_CODE = "/Member/VerifyTACCode";

    static _API_GET_USER_SYSTEM_SETTINGS = "/Member/GetUserSystemSettings";

    static _API_ADD_OPERATION_LOG = "/Account/AddOperationLog";

    static _API_GET_CATEGORY_REWARD_RATES = "/Member/GetCategoryRewardRates";

}

/// <summary>
/// Author : 
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _URL_MAIN = "/";
    static _URL_LOGIN = "/login";
    static _URL_UNDER_MAINTENANCE = "/undermaintenance";
    static _URL_REGISTER = "/register";
    static _URL_CAMO_REGISTER = "/registerPage";
    static _URL_DASHBOARD = "/dashboard";
    static _URL_DASHBOARD_V2 = "/dashboardv2";
    static _URL_CAMO_HOMEPAGE = "/homePage";
    static _URL_QR_CODE = "/qr-code";
    static _URL_RELOAD = "/reload";
    static _URL_WITHDRAWAL = "/withdrawal";
    static _URL_WITHDRAWAL_RECORD = "/withdrawal-record";
    static _URL_DEPOSIT_RECORD = "/deposit-record";
    static _URL_CASH_RELOAD = "/cash-reload";
    static _URL_CASH_BALANCE = "/cash-balance";
    static _URL_CASH_BALANCE_HISTORY = "/cash-balance-history";
    static _URL_DOWNLINE = "/downline";
    static _URL_DOWNLINE_DETAILS = "/downline-details";
    static _URL_EDIT_DOWNLINE = "/edit-downline";
    static _URL_FORGET_PIN = "/forget-pin";
    static _URL_POINT_TRANSFER = "/point-transfer";
    static _URL_POINT_BALANCE = "/point-balance";
    static _URL_RECEIPTS = "/receipts";
    static _URL_SETTINGS = "/settings";
    static _URL_VIDEO_TUTORIAL = "/videoTutorial";
    static _URL_NEWS = "/news";
    static _URL_NEWS_DETAILS = "/news-details";
    static _URL_PACKAGES = "/packages";
    static _URL_TICKETS = "/tickets";
    static _URL_KPI = "/kpi";
    static _URL_GAME_LOBBY = "/gameLobby";
    static _URL_DEPOSIT = "/deposit";
    static _URL_TRANSACTION = "/transaction";
    static _URL_RELOAD_TRANSACTION = "/reloadTransaction";
    static _URL_WALLET_HISTORY = "/report/walletHistory";
    static _URL_WALLET_HISTORY_MOBILE = "/report/walletHistoryM";
    static _URL_TRANSACTION_HISTORY = "/report/transactionHistory";
    static _URL_BONUS_HISTORY = "/report/bonusHistory";
    static _URL_INTERAL_TRASNFER_HISTORY = "/report/internalTransferHistory";
    static _URL_COMMISSION_HISTORY = "/report/commissionHistory";
    static _URL_ROLLOVER_HISTORY = "/report/rolloverHistory";
    static _URL_GAME_HISTORY = "/report/gameHistory";
    static _URL_GAME_DETAILS = "/report/gameDetails";
    static _URL_GAME_RESULT = "/result";
    static _URL_MANAGE_REFERRAL_CODES = "/referralCodes";
    static _URL_REFERRAL_CODE_DETAIL = "/referralCodeDetail";
    static _URL_REPORTS = "/report";
    static _URL_GAME_WALLET = "/gameWallet";
    static _URL_MAINTENANCE = "/maintenance";
    static _URL_CONTACT_US = "/contact-us";
    static _URL_LIVE_CHAT = "/live-chat";
    static _URL_SHAREHOLDER = "/shareholder";
    static _URL_SHAREHOLDER_DETAIL = "/shareholder-details";
    static _URL_EDIT_SHAREHOLDER = "/edit-shareholder";
    static _URL_DOWNLINE_GROUP_DETAIL = "/downlineGroupDetail";
    static _URL_UNCLAIM_COMMISSION_HISTORY = "/UnclaimCommissionHistory";
    static _URL_TNC = "/TnC";
    static _URL_CLAIM_RECORD = "/claim-history";
    static _URL_SUMMARY_DOWNLINE_SALES = "/shareholder/downline";
    static _URL_DOWNLINE_PRODUCT_RATE_DETAIL = "/downline/productRateDetail";
    static _URL_REWARDS = "/rewards";
    static _URL_REWARDS_HISTORY = '/rewards/history';
    static _URL_REWARDS_DETAIL = '/rewards/detail';
    static _URL_GAME_PAGE = '/gamePage';
}

/// <summary>
/// Author : 
/// </summary>
export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _DELETED = 2;
}

/// <summary>
/// Author : 
/// </summary>
export class Language {
    static _CHINESE = "zh";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "ms";
    static _BANGLADESH = "bn";
}

/// <summary>
/// Author : 
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
}

/// <summary>
/// Author : 
/// </summary>
export class Role {
    static _SUPER_ADMIN = 1;
    static _SHOP = 2;
    static _MANAGER = 3;
    static _MEMBER = 4;
    static _ADMIN_SUB_ACCOUNT = 0;
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionType {
    static _DEPOSIT = 1;
    static _WITHDRAWAL = 2;
    static _BONUS = 3;
    static _ADJUSTMENT = 4;
    static _REBATE = 5;
    static _TRANSFER = 6;
    static _REFERRALBONUS = 7;
    static _REFUND = 8;
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionStatus {
    static _OPEN = 0;
    static _IN_REVIEW = 1;
    static _IN_PROGRESS = 2;
    static _APPROVED = 3;
    static _REJECTED = 4;
    static _AUTO_APPROVED = 5;
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author: -
/// </summary>
export class FilterStatus {
    static _ACTIVE = 1;
    static _DELETED = 2;
    static _SUSPENDED = 0;
}

/// <summary>
/// Author: -
/// </summary>
export class LoginType {
    static _LOGIN_TYPE_SHOP = 2;
    static _LOGIN_TYPE_ADMIN = 1;
}

/// <summary>
/// Author: -
/// this is match db value, do not change
/// </summary>
export class TopUpStatus {
    static _PENDING = 0;
    static _SUCCESS = 1;
    static _FAILED = 2;
}

/// <summary>
/// Author: -
/// </summary>
export class CommonStatus {
    static _ALL_STATUS = -1;
    static _PENDING_STATUS = 0;
    static _APPROVE_STATUS = 1;
    static _REJECT_STATUS = 2;
    static _INACTIVE_STATUS = 3;
}

export class TransferType {
    static _DEFAULT = "Default";
    static _CONVERT_POINTS = "Convert Points";
    static _BANK = "Bank";
    static _DOWNLINE = "Downline";
    static _UPLINE = "Upline"
}

/// <summary>
/// Author : -
/// </summary>
export class BankingChannel {
    static _CHANNEL_OFFLINE_BANKING = 1;
    static _CHANNEL_PAYTRUST = 2;
    static _CHANNEL_GPAY = 3;
}

/// <summary>
/// Author : -
/// </summary>
export class PanelType {
    static _MEMBERSITE = "MemberSite";
    static _MOBILESITE = "MobileSite";
    static _BACKOFFICE = "BackOffice";
    static _APPS = "Apps";
    static _SYSTEM = "Systems";
}

/// <summary>
/// Author : -
/// </summary>
export class BetStatus {
    static _PENDING_BET_STATUS = 0;
    static _SETTLED_BET_STATUS = 1;
    static _CANCELED_BET_STATUS = 2;
}

/// <summary>
/// Author : -
/// </summary>
export class LanguageKey {
    static _PRIMARY = "common";
}

/// <summary>
/// Author : -
/// </summary>
export class PaymentType {
    static _PREPAID = 2;
    static _POSTPAID = 1;
}

/// <summary>
/// Author : -
/// </summary>
export const _TICKET_STATUS = [
    { value: null, label: "ALL" },
    { value: "0", label: "PENDING" },
    { value: "1", label: "SETTLED" },
    { value: "2", label: "CANCELLED" }
];

/// <summary>
/// Author : -
/// </summary>
export const _MAIN_WALLET_ID = 0;

/// <summary>
/// Author : Wong
/// </summary>
export const LanguageOption = [{
    prefix: 'EN',
    value: Language._ENGLISH
},
{
    prefix: "中文",
    value: Language._CHINESE
},
{
    prefix: "MY",
    value: Language._BAHASA_MELAYU
},
{
    prefix: "BD",
    value: Language._BANGLADESH
}
];

export const LanguageSelectStyle = {
    control: (styles, state) => ({
        width: "60px",
        padding: ".5rem",
        borderRadius: "4px",
        background: "rgba(0, 0, 0, 0.3)",
    }),
    placeholder: (provided, state) => ({
        color: "#fff",
        margin: "0 auto",
    }),
    dropdownIndicator: defaultStyles => ({
        display: 'none'
    }),
    indicatorsContainer: defaultStyles => ({
        display: 'none'
    }),
    option: (provided, state) => ({
        fontSize: "12px",
        textAlign: "center",
        marginTop: "3px",
        marginBottom: "2px",
        padding: ".5rem 1rem",
        color: "#002e6c"
    }),
}

export const DEFAULT_PAGE_SIZE = 10;