import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WebUrl } from "../../util/Constant";
import Header from "../../components/custom/Header";
import LiveChat from "react-livechat";
import querystring from "query-string";
import { useDispatch } from "react-redux";
import { setBusy, setIdle } from "../../redux/AppAction";

/// <summary>
/// Author: -
/// </summary>
const LiveChatPage = props => {
    let _history = useHistory();
    var _querystring = querystring;
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const _LOAD_STATUS = {
        LOAD_PENDING: 0,
        LOAD_SUCCESS: 1,
        LOAD_FAIL: -2
    }

    const [livechat, setLiveChat] = useState();
    const [isLoaded, setIsLoaded] = useState(_LOAD_STATUS.LOAD_PENDING);

    useEffect(() => {
        _dispatch(setBusy());
        window.__lc = {};

        /// Validate the Username and password
        if (_location.search !== undefined) {
            const data = _querystring.parse(_location.search);
            window.__lc.license = parseInt(data.license);
        } else {
            setIsLoaded(_LOAD_STATUS.LOAD_FAIL);
        }
    }, []);

    useEffect(() => {
        if (isLoaded != _LOAD_STATUS.LOAD_PENDING) {
            _dispatch(setIdle());
        }
    }, [isLoaded]);

    useEffect(() => {
        if (livechat != undefined) {
            livechat.on_after_load = function () {
                livechat.open_chat_window();
                setIsLoaded(_LOAD_STATUS.LOAD_SUCCESS);
            }
            setTimeout(function () {
                if (!livechat.is_loaded()) {
                    setIsLoaded(_LOAD_STATUS.LOAD_FAIL);
                }
            }, 5000);
        }
       
    }, [livechat]);

    return (
        <div>
            <div style={{ paddingBottom: "10px" }}>
                <Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('LIVE_CHAT')} />
                {
                    isLoaded == _LOAD_STATUS.LOAD_FAIL &&
                    <div className="text-center">{t("LIVE_CHAT_NOT_LOADED")}</div>
                }
                {
                    isLoaded == _LOAD_STATUS.LOAD_SUCCESS && 
                    <div className="text-center">{t("LIVE_CHAT_LOADED")}</div>
                }
                <LiveChat license={0} onChatLoaded={ref => setLiveChat(ref)} />
            </div>
        </div>
    )

}

export default LiveChatPage;