import React, { useState, useEffect, useRef } from "react";
//import Accordion from 'react-bootstrap/Accordion';
//import Card from 'react-bootstrap/Card';
import PinInput from "react-pin-input";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, _MAIN_WALLET_ID } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import {
  showMessage,
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { saveLanguageId } from "../../redux/LanguageAction";
import Checkbox from "../../components/custom/Checkbox";
import { performLogout } from "../../redux/AuthAction";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  Alert,
} from "reactstrap";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
  createFormBody,
} from "../../util/Util";
import Spinner from "react-bootstrap/Spinner";
import classnames from "classnames";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import Numpad from "../../components/custom/Numpad";
import moment from "moment";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import contactUsIcon from "../../assets/img/icon/contact-us-icon-yellow.svg";
import kpiIcon from "../../assets/img/icon/kpi-icon.svg";
import referralIcon from "../../assets/img/icon/right-menu-referral-icon.svg";
import i8Icon from "../../assets/img/icon/i8-logo_backup.png";
import reloadPasswordIcon from "../../assets/img/icon/reset_password.svg";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";

/// <summary>
/// Author: -
/// </summary>
const DashboardV2 = (props) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const location = useLocation();
  const _dispatch = useDispatch();

  const [memberData, setMemberData] = useState({});
  const [kpiData, setKpiData] = useState({});
  const [isLobbyModalVisible, setIsLobbyModalVisible] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [games, setGames] = useState([]);
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [gameCategories, setGameCategories] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [contactUsData, setContactUsData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [walletDetails, setWalletDetails] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [loadingWalletFlag, setLoadingWalletFlag] = useState(true);
  const [loadingMemberFlag, setLoadingMemberFlag] = useState(true);
  const [loadingLogoutFlag, setLoadingLogoutFlag] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showCreateShareholderPin, setShowCreateShareholderPin] =
    useState(false);
  const [theInterval, setTheInterval] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const [pinNumber, setPinNumber] = useState("");
  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");
  const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
  const [pinNumberFinal, setPinNumberFinal] = useState("");
  const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
  const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
  const [forgetPin, setForgetPin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [showProductPasswordAlert, setShowProductPasswordAlert] =
    useState(false);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [smsSent, setSmsSent] = useState(false);
  const [unclaimComm, setUnclaimComm] = useState("");
  const [imageTopRight, setImageTopRight] = useState();
  const [imageBottomLeft, setImageBottomLeft] = useState();
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  //const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [productGameId, setProductGameId] = useState(0);
  const [masterProductId, setMasterProductId] = useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [clubFee, setClubFee] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [mpData, setMpData] = useState([]);
  const [isApp, setIsApp] = useState(false);
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  let usernameRef = useRef();
  let passwordRef = useRef();

  const _COUNTDOWN_PERIOD = 120;
  const _SECONDS_IN_MS = 1000;
  const _ALERT_TIMEOUT = 3000;
  const [countdownPeriod, setCountdownPeriod] = useState(0);

  const elePin = useRef(null);
  const eleConfirm = useRef(null);
  const eleFinalPin = useRef(null);
  const keyboard = useRef();

  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const DEFAULT_CAROUSEL_ITEMS = [
    {
      src: require("../../assets/img/banner.png"),
      altText: "",
      caption: "",
      header: "",
      key: "1",
    },
  ];

  const _CLOSE_TAB_KEY = "CLOSE_TAB";
  const _USER_DATA = useSelector((s) => s.authState.userData);

  const _MENU_SELECTIONS = [
    {
      name: "RELOAD",
      imageUrl: require("../../assets/img/icon/reload-icon-white.svg"),
      redirectUrl: WebUrl._URL_RELOAD_TRANSACTION,
      customStyle: { width: "26px" },
    },
    {
      name: "TRANSFER",
      imageUrl: require("../../assets/img/icon/transfer-icon-white.svg"),
      redirectUrl: WebUrl._URL_TRANSACTION,
      customStyle: { height: "28px", width: "28px" },
    },
    {
      name: "REPORTS",
      imageUrl: require("../../assets/img/icon/reports-icon-white.svg"),
      redirectUrl: WebUrl._URL_REPORTS,
      customStyle: { width: "20px", height: "28px" },
    },
    {
      name: "4D_RESULT",
      imageUrl: require("../../assets/img/icon/4d-result-icon-white.svg"),
      redirectUrl: WebUrl._URL_GAME_RESULT,
      customStyle: {
        marginTop: "-0.3rem",
        marginRight: "-0.3rem",
        height: "35px",
        width: "30px",
      },
    },
    {
      name: "DOWNLINE",
      imageUrl: require("../../assets/img/icon/downline-icon-white.svg"),
      redirectUrl: WebUrl._URL_DOWNLINE,
      customStyle: {
        marginTop: "-0.3rem",
        marginRight: "-0.3rem",
        height: "35px",
        width: "30px",
      },
    },
  ];

  const RIGHT_MENU_SELECTIONS = [
    {
      name: "NOTIFICATION",
      imageUrl: require("../../assets/img/icon/right-menu-notification-icon.svg"),
      redirectUrl: WebUrl._URL_NEWS,
    },
    {
      name: "VIDEO_TUTORIAL",
      imageUrl: require("../../assets/img/icon/right-menu-video-tutorial-icon.svg"),
      redirectUrl: WebUrl._URL_VIDEO_TUTORIAL,
    },
  ];

  memberData["isShareHolder"] &&
    RIGHT_MENU_SELECTIONS.push({
      name: "SHAREHOLDER",
      imageUrl: require("../../assets/img/icon/right-menu-vip-icon.svg"),
      redirectUrl: WebUrl._URL_SHAREHOLDER,
    });

  if (!window.ReactNativeWebView) {
    RIGHT_MENU_SELECTIONS.push({
      name: "LIVE_CHAT",
      imageUrl: require("../../assets/img/icon/right-menu-contact-us-icon.svg"),
      redirectUrl: WebUrl._URL_CONTACT_US,
    });
  }

  RIGHT_MENU_SELECTIONS.push(
    {
      name: "TNC_SHORT",
      imageUrl: require("../../assets/img/icon/right-menu-tnc-icon.svg"),
      redirectUrl: WebUrl._URL_TNC,
    },
    {
      name: "SETTINGS",
      imageUrl: require("../../assets/img/icon/right-menu-settings-icon.svg"),
      redirectUrl: WebUrl._URL_SETTINGS,
    },
    {
      name: "RANKING_EVENT",
      imageUrl: require("../../assets/img/icon/right-menu-ranking-event-icon.svg"),
      redirectUrl: "#comingsoon",
    }
  );

  if (!window.ReactNativeWebView) {
    RIGHT_MENU_SELECTIONS.push({
      name: "PROMOTION",
      imageUrl: require("../../assets/img/icon/right-menu-promotion-icon.svg"),
      redirectUrl: WebUrl._URL_REWARDS,
    });
  }
  RIGHT_MENU_SELECTIONS.push({
    name: "LOGOUT",
    imageUrl: require("../../assets/img/icon/right-menu-logout-icon.svg"),
  });

  /// <summary>
  /// Author : -
  /// </summary>
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === bannerData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? bannerData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function submitPin(pin, conf) {
    _dispatch(setBusy());
    let params = {
      pinNumber: pin,
      confirmPinNumber: conf,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowCreateShareholderPin(false);
      setInvalidPin(false);
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    } else {
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      setInvalidPin(true);
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(0);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(0);
      elePin["current"].focus();
    }
    _dispatch(setIdle());
  }

  const verifyPin = async (pin) => {
    if (pin === "0000") {
      _history.push(WebUrl._URL_SETTINGS); // need to change
      setInvalidPin(false);
    } else {
      let params = {
        pinNumber: pin,
      };
      let responseJson = await ApiEngine.post(
        ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setInvalidPin(false);
        _history.push(
          WebUrl._URL_SHAREHOLDER +
          "?GAzobE73Vse4nr8WpufA=" +
          memberData["hideSummary"]
        );
      } else {
        setInvalidPin(true);
        setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
        eleFinalPin["current"].clear();
        setPinNumberFinal("");
        setEnteredPinNumberFinal("");
        setPinNumberFinalFocused(0);
        eleFinalPin["current"]["elements"][0].focus();
      }
    }
  };

  /// <summary>
  /// Author: Wong
  /// </summary>
  useEffect(() => {
    if (_USER_DATA.selectedTheme === 1) {
      init();
    } else if (_USER_DATA.selectedTheme === 0) {
      _dispatch(setBusy());
      _dispatch(showResponseMessage(false, t("REDIRECTING")));
      window.location.replace(WebUrl._URL_DASHBOARD);
    }

    if (_USER_DATA.recentlyResetPassword) {
      setChangePasswordModal(true);
    }

    window.addEventListener("message", function (event) {
      if (event.data == _CLOSE_TAB_KEY) {
        event.source.close();
      }
    });
  }, []);

  useEffect(() => {
    // fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
    //   method: ApiKey._API_GET,
    //   headers: {
    //     "Content-Type": ApiKey._API_FORM_URLENCODED,
    //     "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //       let data = responseJson[ApiKey._API_DATA_KEY];
    //       setGameCategories(data);
    //       setActiveTab(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
    //     }
    //   });
    ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        setGameCategories(data);
        setActiveTab(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
      }
    });
  }, []);

  useEffect(() => {
    // fetch(ApiUrl._API_GET_USER_BANNER, {
    //   method: ApiKey._API_GET,
    //   headers: {
    //     "Content-Type": ApiKey._API_FORM_URLENCODED,
    //     "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //       setBannerData(responseJson[ApiKey._API_DATA_KEY]);
    //     }
    //   });
    ApiEngine.get(ApiUrl._API_GET_USER_BANNER).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setBannerData(responseJson[ApiKey._API_DATA_KEY]);
      }
    });
  }, []);

  useEffect(() => {
    if (walletDetails === true) {
      setLoadingWalletFlag(true);

      ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE).then(
        (responseJson) => {
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
              showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
          } else {
            setWallets(responseJson[ApiKey._API_DATA_KEY]);
            setLoadingWalletFlag(false);
          }
        }
      );
    }
  }, [walletDetails]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    let member = [];
    try {
      responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
      setLoadingMemberFlag(false);
      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      member = responseJson[ApiKey._API_DATA_KEY];
      if (member["selectedTheme"] === 0) {
        _dispatch(setBusy());
        _dispatch(showResponseMessage(false, t("REDIRECTING")));
        window.location.replace(WebUrl._URL_DASHBOARD);
        return;
      }
      changeLanguage(member["languageCode"]);
      setMemberMainWalletBalance(
        isNaN(parseFloat(member["primaryCredit"]), 3)
          ? "0.00"
          : numberWithCurrencyFormat(
            parseFloat(member["primaryCredit"]),
            3,
            true
          )
      );
      responseJson = await ApiEngine.get(
        ApiUrl._API_GET_CATEGORY_GROUP_GAME_DASHBOARD +
        "?languageId=" +
        member["languageSetting"]
      ); // + "?languageId=" + member["languageSetting"]
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setGames(responseJson[ApiKey._API_DATA_KEY]);

        /// preload
        responseJson[ApiKey._API_DATA_KEY].map((game, index) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_SUMMARY_KPI
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setKpiData(responseJson[ApiKey._API_DATA_KEY]);
        setTimeout(() => {
          setLoadingLogoutFlag(true);
        }, 1500);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      let params = {};
      if (member["isShareHolder"] === true) {
        responseJson = await ApiEngine.post(
          ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
          createFormBody(params)
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          setShowCreateShareholderPin(true);
          if (
            elePin["current"]["values"][0] == "" ||
            elePin["current"]["values"][0] == undefined
          ) {
            elePin["current"]["elements"][0].focus();
          }
        }
      }

      /*var responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTACT_US);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setContactUsData(responseJson[ApiKey._API_DATA_KEY]);
            }*/
      getUnclaimCommHistory();
      await _dispatch(checkIsLoggedIn());
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    } finally {
      _dispatch(setIdle());
    }
  }

  const getUnclaimCommHistory = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
  };

  const adjustFocus = () => {
    if (eleConfirm["current"] !== null) {
      if (
        eleConfirm["current"]["values"][0] == "" ||
        eleConfirm["current"]["values"][0] == undefined
      ) {
        eleConfirm["current"]["elements"][0].focus();
      }
    }
  };

  function onCreatePinKeyPress(key) {
    // 1st: have to know if entering for elePin or eleConfirm
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = enteredPinNumber;
    let pinConfirmStr = enteredPinNumberConfirm;
    if (key !== "{bksp}") {
      if (pinNumber === "") {
        currentIndex = pinNumberFocused;
        elePin["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumber(pinStr);
        if (currentIndex >= 0 && currentIndex < 3) {
          currentIndex++;
          setPinNumberFocused(currentIndex);
          elePin["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumber(pinStr);
        }
      } else {
        currentIndex = pinNumberConfirmFocused;
        eleConfirm["current"]["elements"][currentIndex].state.value = key;
        pinConfirmStr += key;
        setEnteredPinNumberConfirm(pinConfirmStr);
        currentIndex++;
        setPinNumberConfirmFocused(currentIndex);
        if (currentIndex <= 3) {
          eleConfirm["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumberConfirm(pinConfirmStr);
          submitPin(pinNumber, pinConfirmStr);
        }
      }
    } else {
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(currentIndex);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(currentIndex);
      elePin["current"]["elements"][currentIndex].focus();
    }
  }

  function onVerifyPinKeyPress(key) {
    let currentIndex = 0;
    let pinStr = enteredPinNumberFinal;
    if (key !== "{bksp}") {
      if (pinNumberFinal === "") {
        currentIndex = pinNumberFinalFocused;
        eleFinalPin["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumberFinal(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFinalFocused(currentIndex);
          eleFinalPin["current"]["elements"][currentIndex].focus();
        } else {
          verifyPin(pinStr);
        }
      }
    } else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(currentIndex);
      eleFinalPin["current"]["elements"][currentIndex].focus();
    }
  }

  /// <summary>
  /// Author:
  /// </summary>
  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  async function sendPinCode() {
    if (countdownPeriod < 1) {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IF_PHONE_VERIFIED
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var hpNum = responseJson[ApiKey._API_DATA_KEY];
        let params = {
          phoneNumber: hpNum,
          isShareholder: "true",
        };
        let smsJson = await ApiEngine.post(
          ApiUrl._API_SEND_PIN_CODE,
          createFormBody(params)
        );
        if (smsJson[ApiKey._API_SUCCESS_KEY]) {
          setForgetPin(false);
          setSmsSent(true);
          setCountdownPeriod(_COUNTDOWN_PERIOD);
        }
      }
      else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  async function updateWalletBalance() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempFromOptions = [];
      let tempToOptions = [];
      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          setMemberMainWalletBalance(
            isNaN(parseFloat(product.balance), 3)
              ? 0.0
              : numberWithCurrencyFormat(parseFloat(product.balance), 3, true)
          );
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          let gameWalletBalance = parseFloat(product.balance);
          setGameWalletBalanceBeforeTransfer(gameWalletBalance);
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
    }
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

          var mpJson = await ApiEngine(
            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
            "?masterProductId=" +
            masterProductId
          );
          if (mpJson[ApiKey._API_SUCCESS_KEY]) {
            let data = mpJson[ApiKey._API_DATA_KEY];
            setMpData(data);
            if (window.ReactNativeWebView && isApp) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  action: "deeplink",
                  url: appUrl,
                  deeplink: appDeepLink.replace("[username]", data["username"]).replace("[password]", data["password"]),
                  packageKeyword: packageNameKeyword
                })
              );
            }
          }
        }
        setProductGameId(gameId);
        console.log("!isSeamless");
        var isNewWindowJson = await ApiEngine(
          ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
        );
        setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
        setShowPregamePopup(true);

        _dispatch(setIdle());
      } else {
        if (!window.ReactNativeWebView) {
          window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp ? "1" : "0"));
        }
        else {
          if (isNewWindow) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                url:
                  window.location.origin +
                  ApiUrl._API_START_GAME +
                  "?gameId=" +
                  gameId,
              })
            );
          }
          else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                url:
                  window.location.origin +
                  WebUrl._URL_GAME_PAGE +
                  "?gameId=" +
                  gameId,
              })
            );
          }
        }
        //var isNewWindowJson = await ApiEngine(
        //  ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
        //);
        //if (isNewWindowJson[ApiKey._API_SUCCESS_KEY]) {
        //  let tempIsNewWindow = isNewWindowJson[ApiKey._API_DATA_KEY];
        //  if (!window.ReactNativeWebView) {
        //    console.log("!window.ReactNativeWebView");
        //      let tempApiUrl = ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp ? "1" : "0");
        //      //console.log(tempApiUrl); 
        //      //var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        //      //console.log(isIOS);
        //    //if (tempIsNewWindow === true) {
        //      //if (!isIOS) {
        //        window.open(tempApiUrl);
        //      //} else {
        //      //  let temp = document.createElement("a");
        //      //  temp.href = window.location.origin + tempApiUrl;
        //      //  temp.target = "_blank";
        //      //  document.body.appendChild(temp);
        //      //  temp.click();
        //      //  document.body.removeChild(temp);
        //      //}
        //    /*} else {
        //      _history.push({
        //        pathname: WebUrl._URL_GAME_PAGE,
        //        search: "?gameId=" + gameId,
        //        state: { url: window.location.pathname },
        //      });
        //    }*/
        //  } else {
        //    console.log("window.ReactNativeWebView");
        //    if (tempIsNewWindow === true) {
        //      window.ReactNativeWebView.postMessage(
        //        JSON.stringify({
        //          url:
        //            window.location.origin +
        //            ApiUrl._API_START_GAME +
        //            "?gameId=" +
        //            gameId,
        //        })
        //      );
        //    } else {
        //      window.ReactNativeWebView.postMessage(
        //        JSON.stringify({
        //          url:
        //            window.location.origin +
        //            WebUrl._URL_GAME_PAGE +
        //            "?gameId=" +
        //            gameId,
        //        })
        //      );
        //    }
        //  }
        //}
      }
    } else {
      if (isSeamless == false) {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

          var mpJson = await ApiEngine(
            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
            "?masterProductId=" +
            masterProductId
          );
          if (mpJson[ApiKey._API_SUCCESS_KEY]) {
            let data = mpJson[ApiKey._API_DATA_KEY];
            setMpData(data);
          }
        }

        var isNewWindowJson = await ApiEngine(
          ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
        );
        setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
        setShowPregamePopup(true);
        setProductGameId(gameId);
        setShowPregamePopup(true);
        SetToLobbyBtn(true);
        _dispatch(setIdle());
      } else {
        _history.push(
          WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"]
        );
      }
    }
  }

  const submitPopupForm = async (data, e) => {
    _dispatch(setBusy());
    let params = {
      fromWallet: _MAIN_WALLET_ID,
      toWallet: masterProductId,
      amount: data.amount,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(
        parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
      );
      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
      setIsTransferSuccess(true);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

      e.target.reset();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };

  /// <summary>
  /// Author: -
  /// </summary>
  function copyText(textType) {
    if (textType === "username") {
      console.log(usernameRef);
      usernameRef.current.select();
    } else if (textType === "password") {
      console.log(passwordRef);
      passwordRef.current.select();
    }
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  async function resetProductPassword(masterProductId) {
    setMasterProductId(masterProductId);
    let apiUrl =
      ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
      "?masterProductId=" +
      masterProductId;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setMpData(data);
      setShowProductPasswordAlert(true);
      setTimeout(() => setShowProductPasswordAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  const changeLanguage = async (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);

    _dispatch(saveLanguageId(selectedLanguage));
  };

  return (
    <>
      <div className="dashboard" style={{ height: "100vh" }}>
        <div className={"banner " /* + (_isLoggedIn ? "is-loggedin" : "234")*/}>
          {bannerData.length > 0 ? (
            <ReactCarousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              indicator={true}
            >
              <CarouselIndicators
                items={bannerData}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {bannerData.map((item, index) => (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={index}
                >
                  {/*{item.url = item.url.toLowerCase().indexOf("http://") == 0 || item.url.toLowerCase().indexOf("https://") == 0 ? item.url : "http://" + item.url}*/}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      if (!window.ReactNativeWebView) {
                        window.open(item.url);
                        return;
                      }

                      window.location.href = item.url;
                    }}
                  >
                    <img
                      className="carousel-img"
                      src={item.mobileImage /*desktop=classic,mobile=modern*/}
                      alt={item.content}
                      style={{ width: "100%" }}
                    />
                  </a>
                  {/*<CarouselCaption captionText={item.content} />*/}
                </CarouselItem>
              ))}
            </ReactCarousel>
          ) : (
            <UncontrolledCarousel items={DEFAULT_CAROUSEL_ITEMS} />
          )}
        </div>
        <div className="dashboard-v2-wrapper">
          <div className="user-name">
            {loadingMemberFlag == true ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div>{t("WELCOME") + ", " + memberData["username"]}</div>
            )}
          </div>
          <div className="user-info">
            <div className="user-info-left wallet-and-commission">
              <div
                className="wallet"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  setWalletDetails(true);
                }}
              >
                <i className="fas fa-wallet text-yellow" style={{}}></i>
                {loadingMemberFlag == true ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    style={{ verticalAlign: "middle", marginLeft: "10px" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <strong className="member-title ml-2">
                    {memberMainWalletBalance}
                  </strong>
                )}
                <i
                  className="fas fa-info-circle ml-2 text-yellow"
                  style={{}}
                ></i>
              </div>
              {loadingMemberFlag != true ? (
                <div
                  className="commission text-yellow"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                  onClick={() =>
                    _history.push(WebUrl._URL_UNCLAIM_COMMISSION_HISTORY)
                  }
                >
                  <img
                    src={require("../../assets/img/icon/money-bag.svg")}
                    style={{ margin: "0 10px 3px 0" }}
                  />
                  <strong
                    className="mr-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {unclaimComm == ""
                      ? "Loading... "
                      : " " +
                      numberWithCurrencyFormat(
                        parseFloat(unclaimComm.totalClaimable),
                        3
                      ) +
                      " "}
                    <i
                      className="fa fa-chevron-right"
                      style={{ marginLeft: "20px" }}
                    />
                  </strong>
                </div>
              ) : (
                <div className="commission text-yellow">
                  <strong className="mr-1">&nbsp;</strong>
                </div>
              )}
            </div>
            <div className="user-info-right">
              <div
                className="kpi-and-settings referral"
                onClick={() => _history.push(WebUrl._URL_MANAGE_REFERRAL_CODES)}
              >
                <div
                  className="referral-icon"
                  style={{ backgroundImage: `url(${referralIcon})` }}
                ></div>
                <div className="text">{t("REFERRAL")}</div>
              </div>
              <div
                className="kpi-and-settings kpi"
                onClick={() => _history.push(WebUrl._URL_KPI)}
              >
                <div
                  className="kpi-icon"
                  style={{ backgroundImage: `url(${kpiIcon})` }}
                ></div>
                <div className="text">KPI</div>
              </div>
              <div
                className="kpi-and-settings contact-us"
                onClick={() => _history.push(WebUrl._URL_CONTACT_US)}
              >
                <div
                  style={{ backgroundImage: `url(${contactUsIcon})` }}
                  className="contact-us-icon"
                ></div>
                <div className="text">{t("HELP")}</div>
              </div>
              <div
                className="kpi-and-settings settings"
                onClick={() => setShowRightMenu(true)}
              >
                <i
                  className="fas fa-regular fa-bars"
                  style={{ fontSize: "28px", color: "#FFC159" }}
                ></i>
                <div className="text">{t("MENU")}</div>
              </div>
            </div>
          </div>
          <div className="dashboard-menu">
            {_MENU_SELECTIONS.map((menu, index) => {
              if (!stringIsNullOrEmpty(menu["redirectUrl"])) {
                return (
                  <Link
                    key={index}
                    to={{
                      pathname: menu["redirectUrl"],
                      state: { fromDashboard: true },
                    }}
                    className="menu-link"
                  >
                    <img
                      className="menu-icon"
                      style={{ ...menu["customStyle"] }}
                      src={menu["imageUrl"]}
                    />
                    <div className="menu-name">{t(menu["name"])}</div>
                  </Link>
                );
              }
            })}
          </div>
          <div className="games-content">
            <div className="sidenav-menu">
              <Nav className="category-list" style={{ display: "inherit" }}>
                {gameCategories.map((category, index) => {
                  if (
                    games.filter(
                      (n) => n.categoryTitle === category.originalTitle
                    ).length > 0 ||
                    category.title === "All"
                  ) {
                    return (
                      <NavItem
                        className={classnames({
                          active: activeTab === category.id,
                        })}
                      >
                        <NavLink
                          className={
                            "category-tile " +
                            classnames({ active: activeTab === category.id })
                          }
                          onClick={() => {
                            toggleTab(category.id);
                          }}
                        >
                          <img
                            className="category-icon"
                            src={require("../../assets/img/icon/" +
                              category.originalTitle +
                              ".svg")}
                          />
                          <span className="category-text d-sm-none">
                            {t(category.title)}
                          </span>
                          <span className="category-text d-sm-block d-none">
                            {t(category.title)}
                          </span>
                        </NavLink>
                      </NavItem>
                    );
                  }
                })}
              </Nav>
            </div>
            <TabContent activeTab={activeTab}>
              {gameCategories.map((category, index) => {
                if (
                  games.filter(
                    (n) => n.categoryTitle === category.originalTitle
                  ).length > 0 ||
                  category.originalTitle === "All"
                ) {
                  return (
                    <TabPane tabId={category.id}>
                      <div className="games-menu">
                        {games.map((game, index) => {
                          if (
                            game["categoryTitle"] === category.originalTitle ||
                            category.originalTitle === "All"
                          ) {
                            return (
                              <>
                                <div
                                  className="game-container"
                                  key={"container-" + index}
                                >
                                  <div
                                    className={
                                      game["gameUm"] == true ? "um-wrapper" : ""
                                    }
                                  >
                                    <a
                                      key={index}
                                      onClick={() => {
                                        setPopupBannerImage(
                                          game["popupBannerImage"]
                                        );
                                        if (game["gameUm"] !== true) {
                                          startGame(
                                            game["id"],
                                            game["hasOwnLobby"],
                                            game["masterProductId"],
                                            game["gameName"],
                                            game["masterProductIsSeamless"],
                                            game["isApp"],
                                            game["appUrl"],
                                            game["appDeepLink"],
                                            game["isNewWindow"],
                                            game["packageNameKeyword"]
                                          );
                                        }
                                      }}
                                      className={
                                        game["gameUm"] == true ? "gameum" : ""
                                      }
                                    >
                                      <div
                                        className="game-info"
                                        style={{
                                          backgroundImage: !stringIsNullOrEmpty(
                                            game["image"]
                                          )
                                            ? `url('${game["image"]}')`
                                            : `url('${i8Icon}')`,
                                        }}
                                      >
                                        {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                                        {game["gameUm"] == true && (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                              className="v2-um-tag game-category-label"
                                            ></div>
                                            <div
                                              style={{
                                                backgroundImage: `url(${UMIcon})`,
                                              }}
                                              className="v2-um-tag-icon"
                                            ></div>
                                          </>
                                        )}
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    </TabPane>
                  );
                }
              })}
            </TabContent>
          </div>
        </div>
      </div>
      <Modal
        fade={false}
        contentClassName={
          "modal-brand modal-bottom wallet-info" +
          (_USER_DATA.selectedTheme === 0 ? "" : " modern")
        }
        isOpen={walletDetails}
        toggle={() => {
          setWalletDetails(false);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setWalletDetails(false);
          }}
        >
          {t("WALLET_INFO")}
        </ModalHeader>
        <ModalBody style={{ minHeight: "unset" }}>
          <div className="row">
            <label
              className="col-6 col-form-label"
              style={{
                padding: "0 15px 15px 15px",
                color: "#ffc159",
                fontSize: "16px",
              }}
            >
              {t("CATEGORY")}
            </label>
            <label
              className="col-6 col-form-label text-right"
              style={{
                padding: "0 15px 15px 15px",
                color: "#ffc159",
                fontSize: "16px",
              }}
            >
              {t("BALANCE")}
            </label>
          </div>
          <hr style={{ margin: "0", backgroundColor: "#ffc159" }} />
          {loadingWalletFlag && (
            <div
              style={{
                textAlign: "center",
                marginTop: "25px",
                marginBottom: "20px",
                color: "#ffc159",
              }}
            >
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{
                  verticalAlign: "middle",
                  width: "4rem",
                  height: "4rem",
                }}
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}

          {!loadingWalletFlag &&
            wallets
              .filter((i) => i.productId == 0)
              .map((wallet, index) => {
                return (
                  <span key={index}>
                    <div className="row">
                      <label
                        className="text-brand col-6 col-form-label"
                        style={{ fontSize: "16px", color: "#ffc159" }}
                      >
                        {wallet["productName"]}
                      </label>
                      <label
                        className="text-brand col-6 col-form-label text-right"
                        style={{ fontSize: "16px", color: "#ffc159" }}
                      >
                        {numberWithCurrencyFormat(
                          parseFloat(
                            wallet["balance"] != "N/A" ? wallet["balance"] : 0
                          ),
                          3
                        )}
                      </label>
                    </div>
                  </span>
                );
              })}
          {!loadingWalletFlag &&
            wallets
              .filter((i) => i.productId != 0)
              .map((wallet, index) => {
                return (
                  <span key={index}>
                    <div className="row">
                      <label
                        className="text-brand col-6 col-form-label"
                        style={{ fontSize: "16px", color: "#ffc159" }}
                      >
                        {wallet["productName"]}
                      </label>
                      <label
                        className="text-brand col-6 col-form-label text-right"
                        style={{ fontSize: "16px", color: "#ffc159" }}
                      >
                        {numberWithCurrencyFormat(
                          parseFloat(
                            wallet["balance"] != "N/A" ? wallet["balance"] : 0
                          ),
                          3
                        )}
                      </label>
                    </div>
                  </span>
                );
              })}
        </ModalBody>
      </Modal>
      {showRightMenu && (
        <>
          <div>
            <div
              className="right-menu-wrapper pin-wrapper"
              onClick={() => setShowRightMenu(false)}
            ></div>
            <div className="right-menu">
              <div
                className="right-menu-arrow-wrapper"
                onClick={() => setShowRightMenu(false)}
              >
                <i className="right-menu-arrow fa fa-chevron-right" />
              </div>
              <div className="right-menu-main">
                {RIGHT_MENU_SELECTIONS.map((menu, index) => {
                  if (menu["name"] === "LOGOUT") {
                    return (
                      <div
                        className={"right-menu-item item-" + (index + 1)}
                        onClick={() => _dispatch(performLogout())}
                      >
                        <img className="item-icon" src={menu["imageUrl"]} />
                        <div className="item-name">{t(menu["name"])}</div>
                      </div>
                    );
                  } else if (menu["redirectUrl"] === "/shareholder") {
                    return (
                      <>
                        <div
                          className={"right-menu-item item-" + (index + 1)}
                          onClick={() => setShowPinEntry(true)}
                        >
                          <img className="item-icon" src={menu["imageUrl"]} />
                          <div className="item-name">{t(menu["name"])}</div>
                        </div>
                        <hr className="right-menu-hr" />
                      </>
                    );
                  } else if (
                    menu["name"] ===
                    "RANKING_EVENT" /* || menu['name'] === "PROMOTION"*/
                  ) {
                    return (
                      <>
                        <div
                          className={"right-menu-item item-" + (index + 1)}
                          style={{
                            cursor: "not-allowed",
                            paddingTop:
                              menu["name"] === "PROMOTION" ? "0.25px" : "0",
                          }}
                        >
                          <div className="coming-soon-label">
                            {t("COMING_SOON_LABEL")}
                          </div>
                          <img className="item-icon" src={menu["imageUrl"]} />
                          <div className="item-name">{t(menu["name"])}</div>
                        </div>
                        <hr className="right-menu-hr" />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div
                          className={"right-menu-item item-" + (index + 1)}
                          onClick={() => _history.push(menu["redirectUrl"])}
                        >
                          <img className="item-icon" src={menu["imageUrl"]} />
                          <div className="item-name">{t(menu["name"])}</div>
                        </div>
                        <hr className="right-menu-hr" />
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </>
      )}
      {showPinEntry && (
        <div>
          <div className="pin-wrapper"></div>
          <div className="pin-number">
            <button
              type="button"
              style={{
                alignSelf: "flex-end",
                backgroundColor: "transparent",
                border: "none",
                marginRight: "15px",
                fontSize: "2rem",
                color: "#002e6c",
                marginTop: "-15px",
                float: "right",
                fontWeight: "bold",
              }}
              onClick={() => {
                clearInterval(theInterval);
                setShowPinEntry(false);
              }}
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
            <strong
              className="reload-title text-yellow mb-3 d-block"
              style={{ fontSize: "20px", marginLeft: "40px" }}
            >
              {t("ENTER_YOUR_PIN")}
            </strong>
            <PinInput
              style={{ marginBottom: "35px" }}
              ref={eleFinalPin}
              length={4}
              initialValue=""
              secret={true}
              focus={showPinEntry}
              autoSelect={false}
              disabled={showPinEntry}
              onChange={(value, index) => {
                setPinNumberFinalFocused(index);
              }}
              type="numeric"
              inputMode="number"
              inputStyle={{
                border: "0",
                margin: "0px 10px",
                width: "40px",
                height: "40px",
                backgroundColor: "transparent",
                borderBottom: "2px solid #002e6c",
              }}
              inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
              regexCriteria={/^[ 0-9_@./#&+-]*$/}
            />
            {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

            <strong
              className="text-brand display-value"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setForgetPin(true);
                sendPinCode();
              }}
            >
              {t("RESET_PIN")}
            </strong>
            {smsSent && (
              <p
                className="text-brand"
                style={{ textAlign: "center", marginTop: "5px" }}
              >
                {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod} {t("SECOND")}
              </p>
            )}
            {showAlert && (
              <div
                className="content content-full-width"
                style={{
                  position: "fixed",
                  top: "10px",
                  left: "0",
                  width: "100%",
                }}
              >
                <div className="container">
                  <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                </div>
              </div>
            )}
            <div
              className="invalid-feedback"
              style={{ fontSize: "12px", marginTop: "1rem" }}
            >
              {invalidPin && t(errorMessage)}
              {!invalidPin && <span>&nbsp;</span>}
            </div>
            <Keyboard
              layout={{
                default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
              }}
              display={{
                "{bksp}": "<i class='fas fa-backspace'></i>",
              }}
              theme={`keyboard keyboard-numpad`}
              keyboardRef={(r) => (keyboard.current = r)}
              onKeyPress={onVerifyPinKeyPress}
              disableButtonHold
              disableCaretPositioning
            />
          </div>
        </div>
      )}
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={showCreateShareholderPin}
        centered
      >
        <ModalBody>
          <form>
            <div className="display-wrapper">
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "20px" }}
              >
                {t("NO_SHAREHOLDER_PIN_FOUND")}
              </strong>
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                }}
              >
                {t("ENTER_YOUR_PIN")}
              </strong>
              <PinInput
                ref={elePin}
                length={4}
                initialValue=""
                secret={true}
                focus={true}
                autoSelect={false}
                disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberFocused(index);
                }}
                onComplete={(value) => adjustFocus()}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                  marginTop: "30px",
                }}
              >
                {t("CONFIRM_YOUR_PIN")}
              </strong>
              <PinInput
                ref={eleConfirm}
                length={4}
                initialValue=""
                secret={true}
                focus={false}
                autoSelect={false}
                disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberConfirmFocused(index);
                }}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t(errorMessage)}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
              <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onCreatePinKeyPress}
                disableButtonHold
                disableCaretPositioning
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        id="dashboard-pregame-popup"
        fade={false}
        contentClassName="modal-pregame-popup modal-brand modal-center"
        isOpen={showPregamePopup}
        toggle={() => {
          setShowPregamePopup(false);
          SetToLobbyBtn(false);
        }}
        centered
      >
        <div className="pregame-popup-header">
          <i
            className="fa fa-arrow-left"
            style={{ position: "absolute", left: "20px" }}
            onClick={() => {
              setShowPregamePopup(false);
              SetToLobbyBtn(false);
            }}
          />
          {selectedGameName}
        </div>
        <div
          className="pregame-popup-banner"
          style={{ backgroundImage: `url(${popupBannerImage})` }}
        ></div>
        <ModalBody className="pregame-modal-body">
          <form onSubmit={handleSubmitPopup(submitPopupForm)}>
            <div className="display-wrapper">
              <div className="wallet-info">
                <div>
                  {fromOptions.length > 0
                    ? fromOptions[0].label +
                    " " +
                    numberWithCurrencyFormat(fromOptions[0].amount, 3)
                    : ""}
                </div>
                <div>
                  <hr className="vertical-line" />
                </div>
                <div>
                  {toOptions.length > 0
                    ? toOptions[0].label +
                    " " +
                    numberWithCurrencyFormat(toOptions[0].amount, 3)
                    : ""}
                </div>
              </div>
              <div className="transfer-amount-wrapper">
                <div className="transfer-input-label">
                  {t("TOTAL_TRANSFER_AMOUNT")}
                </div>
                <div className="transfer-input">
                  {/*<div className="currency">{"MYR"}</div>*/}
                  <input
                    type="number"
                    className="form-control transfer-amount"
                    readOnly
                    placeholder={"0.00"}
                    name="amount"
                    onClick={(e) => {
                      setCurrentInput(e.target);
                    }}
                    ref={registerPopup({
                      required: "PLEASE_ENTER_AMOUNT",
                      validate: {
                        minValue: (value) =>
                          parseFloat(value) > 0 ||
                          "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                      },
                    })}
                  />
                  {errorsPopup.amount && (
                    <div className="invalid-feedback">
                      {t(errorsPopup.amount.message)}
                    </div>
                  )}
                </div>
                <div className="transfer-balance">
                  <div>{selectedGameName + " " + t("NEW_BALANCE")}</div>
                  <div>
                    {watchPopup("amount") > 0 &&
                      numberWithCurrencyFormat(
                        parseFloat(toOptions[0].amount) +
                        parseFloat(watchPopup("amount")),
                        2,
                        true
                      )}{" "}
                    {watchPopup("amount") == "" && "-"}
                  </div>
                </div>
              </div>
              <div className="transfer-btn">
                <button type="submit" className="btn btn-brand-gradient">
                  {t("TRANSFER")}
                </button>
              </div>
            </div>
          </form>
          {(bonus > 0 || commission > 0 || clubFee > 0) && (
            <div className="bonus-and-commission">
              {bonus > 0 && (
                <div className="bonus">
                  {t("BONUS") +
                    " (%) : " +
                    numberWithCurrencyFormat(parseFloat(bonus), 2, true) +
                    "%"}
                </div>
              )}
              {commission > 0 && (
                <div className="commission">
                  {t("COMMISSION") +
                    " (%) : " +
                    numberWithCurrencyFormat(parseFloat(commission), 2, true) +
                    "%"}
                </div>
              )}
              {clubFee > 0 && (
                <div className="clubFee">
                  {t("CLUB_FEE") +
                    " (%) : " +
                    numberWithCurrencyFormat(parseFloat(clubFee), 2, true) +
                    "%"}
                </div>
              )}
            </div>
          )}
          {isApp && appDeepLink !== "" && (
            <>
              <div style={{ paddingBottom: "20px" }}>
                <hr
                  style={{
                    backgroundColor: "#ffc159",
                    width: "90%",
                    padding: "0.5px 0",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "-25px",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#02285A",
                      color: "#ffc159",
                      padding: "0 25px",
                    }}
                  >
                    {selectedGameName + " " + t("ACCOUNT")}
                  </span>
                </div>
              </div>
              <div className="username-and-password">
                <div className="details username">
                  <div className="details-label">{t("USERNAME") + " : "}</div>
                  <input
                    type="text"
                    className="username-input"
                    value={mpData["username"]}
                    ref={usernameRef}
                    readOnly
                  />
                  <div
                    style={{ marginLeft: "10px" }}
                    onClick={() => copyText("username")}
                  >
                    <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                  </div>
                </div>
                <div className="details password">
                  <div className="details-label">{t("PASSWORD") + " : "}</div>
                  <input
                    type="text"
                    className="password-input"
                    value={mpData["password"]}
                    ref={passwordRef}
                    readOnly
                  />
                  <div
                    style={{ marginLeft: "10px" }}
                    onClick={() => copyText("password")}
                  >
                    <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                  </div>
                  <div
                    className="refresh-password-btn"
                    style={{
                      backgroundImage: `url(${reloadPasswordIcon})`,
                    }}
                    onClick={() => resetProductPassword(masterProductId)}
                  >
                    <div className="tooltip-text">
                      <span>{t("REFRESH_PASSWORD")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <div className="start-btn-wrapper" style={{ width: "100%" }}>
          <div className="container">
            {!isApp || (isApp && appDeepLink != "") ? (
              <>
                {!toLobbyBtn ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (!window.ReactNativeWebView) {
                        //if (isNewWindow === true || isApp) {
                        window.open(
                          ApiUrl._API_START_GAME + "?gameId=" +
                          productGameId +
                          "&isApp=0"
                        );
                        /*} else {
                            _history.push({
                              pathname: WebUrl._URL_GAME_PAGE,
                              search: "?gameId=" + productGameId,
                              state: { url: window.location.pathname },
                            });
                          }*/
                      } else {
                        if (isApp) {
                          window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                              action: "deeplink",
                              url: appUrl,
                              deeplink: appDeepLink.replace("[username]", mpData["username"]).replace("[password]", mpData["password"])
                            })
                          );
                        } else {
                          if (isNewWindow === true) {
                            window.ReactNativeWebView.postMessage(
                              JSON.stringify({
                                url: `${process.env.REACT_APP_ENDPOINT_URL ||
                                  window.location.origin
                                  }${ApiUrl._API_START_GAME
                                  }?gameId=${productGameId}`,
                              })
                            );
                          } else {
                            window.ReactNativeWebView.postMessage(
                              JSON.stringify({
                                url: `${process.env.REACT_APP_ENDPOINT_URL ||
                                  window.location.origin
                                  }${WebUrl._URL_GAME_PAGE
                                  }?gameId=${productGameId}`,
                              })
                            );
                          }
                        }
                      }
                    }}
                    className="btn pregame-popup-btn btn-block btn-brand"
                  >
                    {t("START_GAME")}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      _history.push(
                        WebUrl._URL_GAME_LOBBY +
                        "?name=" +
                        selectedGameName +
                        "&id=" +
                        productGameId +
                        "&languageId=" +
                        memberData["languageSetting"]
                      );
                    }}
                    className="btn pregame-popup-btn btn-block btn-brand"
                  >
                    {t("TO_LOBBY", { productName: selectedGameName })}
                  </button>
                )}
              </>
            ) : (
              <button
                type="button"
                onClick={() => {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({ url: appUrl })
                    );
                  } else {
                    window.open(appUrl);
                  }
                }}
                className="btn pregame-popup-btn btn-block btn-brand"
              >
                {t("DOWNLOAD_APP")}
              </button>
            )}
          </div>
        </div>
      </Modal>
      <BottomSuccessModal
        isOpen={isTransferSuccess}
        setIsOpen={setIsTransferSuccess}
        title={"TRANSFER_SUCCESS"}
        amount={paidAmount}
        date={transferDate}
        transactionNumber={transcactionNumber}
      />
      {(getValuesPopup("toWallet") > 0 || currentInput) && (
        <Numpad
          currentBalance={parseFloat(memberMainWalletBalance)}
          currentInput={currentInput}
          setCurrentInput={(e) => {
            setCurrentInput(e);
          }}
        />
      )}
      {showCopyAlert && (
        <div
          className="content content-full-width"
          style={{
            zIndex: "9999",
            position: "absolute",
            top: "5px",
            right: "5px",
            width: "200px",
          }}
        >
          <Alert color="green" style={{ textAlign: "center" }}>
            {t("COPY_SUCCESS")}
          </Alert>
        </div>
      )}
      {showProductPasswordAlert && (
        <div
          className="content content-full-width"
          style={{
            zIndex: "9999",
            position: "absolute",
            top: "5px",
            right: "5px",
            width: "200px",
          }}
        >
          <Alert color="green" style={{ textAlign: "center" }}>
            {t("PASSWORD_RESET_SUCCESS")}
          </Alert>
        </div>
      )}
    </>
  );
};

export default DashboardV2;
