import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, _MAIN_WALLET_ID } from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import { useDispatch } from "react-redux";
import classnames from 'classnames';
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../../components/custom/StyledSelect";
import Numpad from "../../../components/custom/Numpad";
import moment from "moment";
import BottomSuccessModal from "../../../components/custom/BottomSuccessModal";
import { stringIsNullOrEmpty, numberWithCurrencyFormat, createFormBody } from "../../../util/Util";
import { showResponseMessage, setBusy, setIdle } from "../../../redux/AppAction.js";
import { Modal, ModalBody, ModalHeader, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

/// <summary>
/// Author: -
/// </summary>
const PlayTechLobby = (props) => {
    var { t } = useTranslation();
    var _dispatch = useDispatch();

    const [masterProductId, setMasterProductId] = useState(0);
    const [games, setGames] = useState([]);
    const [recommendedGamesList, setRecommendedGamesList] = useState([]);
    const [gameCategories, setGameCategories] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [initCheck, setInitCheck] = useState(false);
    const [providerLogo, setProviderLogo] = useState("");
    const [bannerImage, setBannerImage] = useState("");
    const [iconImage, setIconImage] = useState("");
    const [lobbyName, setLobbyName] = useState("");
    const [showPregamePopup, setShowPregamePopup] = useState(false);
    const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [fromOptions, setFromOptions] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [currentInput, setCurrentInput] = useState();
    const [showTaxWarning, setShowTaxWarning] = useState(false);
    const [productGameId, setProductGameId] = useState(0);
    const [paidAmount, setPaidAmount] = useState();
    const [transferDate, setTransferDate] = useState();
    const [isTransferSuccess, setIsTransferSuccess] = useState(false);
    const [transcactionNumber, setTranscactionNumber] = useState();

    const {
        handleSubmit: handleSubmitPopup,
        register: registerPopup,
        control: controlPopup,
        watch: watchPopup,
        getValues: getValuesPopup,
        setValue: setValuePopup,
        errors: errorsPopup,
        reset: resetPopup
    } = useForm();

    /// <summary>
    /// Author : -
    /// </summary>
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        setLobbyName(props.name);
        setMasterProductId(props.id);
        init(props.id, props.languageId);
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init(id, languageId) {
        var apiUrl = ApiUrl._API_GET_MASTER_PRODUCT_BY_ID;
        apiUrl += "?id=" + id;
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBannerImage(responseJson[ApiKey._API_DATA_KEY]["bannerImage"]);
            setIconImage(responseJson[ApiKey._API_DATA_KEY]["iconImage"]);
            setProviderLogo(responseJson[ApiKey._API_DATA_KEY]["productImage"]);
            responseJson = await ApiEngine.get(ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME + "?id=" + id + "&languageId=" + languageId);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var availableGames = responseJson[ApiKey._API_DATA_KEY];
                var tempList = [];
                setGames(availableGames);
                availableGames.map((game, index) => {
                    if (game['isRecommended'] === true) { tempList.push(game) }
                })
                setRecommendedGamesList(tempList);

                responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    setGameCategories(data);
                    setActiveTab(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
                }
                else {
                    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            }
            else {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        setInitCheck(true);
    }

    async function startGame(gameId) {
        setProductGameId(gameId);
        checkIfNonSeamless(gameId);
    }

    async function checkIfNonSeamless(gameId) {
        _dispatch(setBusy());
        let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_NON_SEAMLESS + "?id=" + gameId);
        if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY] !== null) {
            responseJson = await ApiEngine.get(ApiUrl._API_GET_FLAG_TRANSFER_BACK_MAIN_WALLET);
            if (responseJson[ApiKey._API_DATA_KEY] == 1) {
                setIsTransferBackToMainWallet(true);
                responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWalletList(responseJson[ApiKey._API_DATA_KEY]);
                    let tempFromOptions = [];

                    responseJson[ApiKey._API_DATA_KEY].map((product) => {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                        if (product.productId === _MAIN_WALLET_ID || product.productId === masterProductId) {
                            tempFromOptions.push(option);
                        }
                    })
                    setFromOptions(tempFromOptions);
                }
            }
            else {
                responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                    let tempFromOptions = [];
                    let tempToOptions = [];

                    responseJson[ApiKey._API_DATA_KEY].map((product) => {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
                        if (product.productId === _MAIN_WALLET_ID) {
                            tempFromOptions.push(option);
                        } else if (product.productId === masterProductId) {
                            tempToOptions.push(option);
                        }
                    })
                    setFromOptions(tempFromOptions);
                    setToOptions(tempToOptions);
                }
            }
            setShowPregamePopup(true);
        }
        else {
            window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId);
        }
        _dispatch(setIdle());
    }

    function setTransfertoToption(fromWallet) {
        if (isTransferBackToMainWallet) {
            let tempToOptions = [];
            if (fromWallet !== _MAIN_WALLET_ID) {
                setShowTaxWarning(true);
                fromOptions.map((fromOption, index) => {
                    if (fromOption.value === _MAIN_WALLET_ID) {
                        tempToOptions.push(fromOption);
                    }
                });
            }
            else {
                setShowTaxWarning(false);
                fromOptions.map((fromOption, index) => {
                    if (fromOption.value !== fromWallet) {
                        tempToOptions.push(fromOption);
                    }
                });
            }
            setToOptions(tempToOptions);
        }
    }

    const submitPopupForm = async (data, e) => {
        _dispatch(setBusy());
        let params = {
            "fromWallet": data.fromWallet,
            "toWallet": data.toWallet,
            "amount": data.amount
        }

        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await checkIfNonSeamless(productGameId);

            setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
            setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
            setIsTransferSuccess(true);
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

            e.target.reset();
            resetFormField();
        } else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : -
    /// </summary>
    function resetFormField() {
        setValuePopup("fromWallet", null);
        setValuePopup("toWallet", null);
        setCurrentInput();
    }

    return (
        <div id="playtech-lobby">
            {initCheck && <div className="content-wrapper">
                <div className="top-banner" style={{ backgroundColor: "black" }}>
                    <div className="banner-background" style={{ backgroundImage: `url(${bannerImage})` }} ></div>
                    <div className="banner-icon" style={{ backgroundImage: `url(${iconImage})` }} ></div>
                    {/*<img className="banner-icon" src={require('./../../../assets/img/icon/playtech-icon.png')} style={{ position: "absolute", width: "30px", top: "0", left: "0", margin: "10px" }} />*/}
                    <div className="text-and-searchbar">
                        <div className="banner-text">{lobbyName}</div>
                        <div className="banner-searchbar">
                            <input className="search-box" type="text" placeholder="SEARCH" />
                            <div><i className="search-icon fas fa-search"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {recommendedGamesList.length > 0 && <><div className="hot-games">
                    <div className="hot-games-label"><label htmlFor="hot-games-list" style={{ color: "#002E6C", fontWeight: "bold" }}>{t("HOT_GAMES")}</label>{/*<div>{t("SEE_MORE")}</div>*/}</div>
                    <div id="hot-games-list" className="hot-games-list game-row">
                        {recommendedGamesList.map((game, index) => {
                            return (
                                <div className="hot-games-tile" key={index}>
                                    <a key={index} onClick={() => { startGame(game['id']) }} className={game['gameUm'] == true ? "gameum" : ""} target="_blank">
                                        <div className="lobby-games-img" style={{ backgroundImage: game.image !== null ? `url(${game.image})` : `url(${providerLogo})` }}></div>
                                        <div className="hot-games-name">
                                            <div className="hot-games-text">{game.gameName}</div>
                                            {/*<img className="hot-games-like" src={require('./../../assets/img/icon/icon-heart.png')} />*/}
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div></>}
                <div className="all-games">
                    <Nav className="games-lobby">
                        {gameCategories.map((category, index) => {
                            if (games.filter(n => n.categoryTitle === category.title).length > 0 || category.title === "All") {
                                return (
                                    <NavItem className={classnames({ active: activeTab === category.id })}>
                                        <NavLink
                                            className={classnames({ active: activeTab === category.id })}
                                            onClick={() => { toggleTab(category.id); }}>
                                            <span className="d-sm-none">{t(category.title)}</span>
                                            <span className="d-sm-block d-none">{t(category.title)}</span>
                                        </NavLink>
                                    </NavItem>
                                )
                            }
                        })}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {gameCategories.map((category, index) => {
                            if (games.filter(n => n.categoryTitle === category.title).length > 0 || category.title === "All") {
                                return (
                                    <TabPane tabId={category.id}>
                                        <div className="game-row" style={{ marginTop: "20px" }}>
                                            {games.map((game, index) => {
                                                if (game['categoryTitle'] === category.title || category.title === "All") {
                                                    return (
                                                        <div className="hot-games-tile" key={index}>
                                                            <a key={index} onClick={() => { startGame(game['id']) }} className={game['gameUm'] == true ? "gameum" : ""} target="_blank">
                                                                <div className="lobby-games-img" style={{ backgroundImage: game.image !== null ? `url(${game.image})` : `url(${providerLogo})` }}></div>
                                                                <div className="hot-games-name">
                                                                    <div className="hot-games-text">{game.gameName}</div>
                                                                    {/*<img className="hot-games-like" src={require('./../../assets/img/icon/icon-heart.png')} />*/}
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </TabPane>
                                )
                            }
                        })}
                    </TabContent>
                </div>
                <Modal fade={false} contentClassName="modal-brand modal-center" isOpen={showPregamePopup} toggle={() => { setShowPregamePopup(false) }} centered>
                    <ModalHeader toggle={() => { setShowPregamePopup(false) }}>{t("TRANSFER")}</ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmitPopup(submitPopupForm)}>
                            <div className="display-wrapper">
                                {/*<strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("NO_SHAREHOLDER_PIN_FOUND")}</strong>*/}
                                <div className="form-group m-b-15">
                                    <label className="col-form-label"><b>{t("TRANSFER_FROM")}</b></label>
                                    <div>
                                        <Controller
                                            control={controlPopup}
                                            name="fromWallet"
                                            defaultValue={null}
                                            render={({ onChange, value }) => {
                                                return (
                                                    <StyledSelect options={fromOptions}
                                                        placeholder={t("PLEASE_SELECT_WALLET")}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.value);
                                                            setTransfertoToption(e.value)
                                                        }} />
                                                )
                                            }}
                                            rules={{
                                                validate: {
                                                    required: value => value !== null || "PLEASE_SELECT_WALLET"
                                                }
                                            }} />
                                        {errorsPopup.fromWallet && <div className="invalid-feedback">{t(errorsPopup.fromWallet.message)}</div>}
                                    </div>
                                </div>
                                <div className="form-group m-b-15">
                                    <label className="col-form-label"><b>{t("TRANSFER_TO")}</b></label>
                                    <div>
                                        <Controller
                                            control={controlPopup}
                                            name="toWallet"
                                            defaultValue={null}
                                            render={({ onChange, value }) => {
                                                return (
                                                    <StyledSelect options={toOptions}
                                                        placeholder={t("PLEASE_SELECT_WALLET")}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.value)
                                                        }} />
                                                )
                                            }}
                                            rules={{
                                                validate: {
                                                    required: value => value !== null || "PLEASE_SELECT_WALLET"
                                                }
                                            }} />
                                        {errorsPopup.toWallet && <div className="invalid-feedback">{t(errorsPopup.toWallet.message)}</div>}
                                    </div>
                                </div>
                                <div className="form-group m-b-15">
                                    <label className="col-form-label"><b>{t("AMOUNT")}</b></label>
                                    <div>
                                        <input type="number" className="form-control bg-white" readOnly placeholder={t("PLEASE_ENTER_AMOUNT")} name="amount"
                                            style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
                                            onClick={(e) => { setCurrentInput(e.target) }}
                                            ref={registerPopup({
                                                required: "PLEASE_ENTER_AMOUNT",
                                                validate: {
                                                    minValue: value => parseFloat(value) > 0 || 'PLEASE_ENTER_VALUE_MORE_THAN_ZERO'
                                                }
                                            })}
                                        />
                                        {errorsPopup.amount ? <div className="invalid-feedback">{t(errorsPopup.amount.message)}</div> : (showTaxWarning && <div className="invalid-feedback" style={{ fontSize: "13px" }}>{t("TRANSFER_TRANSACTION_HAS_TAX")}</div>)}
                                    </div>
                                </div>
                                <div style={{ display: "flex", margin: "1rem 0px", justifyContent: "space-evenly" }}>
                                    <button type="submit" className="btn btn-pregame-popup btn-brand-gradient">{t("TRANSFER")}</button>
                                    <button type="button" onClick={() => window.open(ApiUrl._API_START_GAME + "?gameId=" + productGameId)} className="btn btn-pregame-popup btn-brand-gradient">{t("START_GAME")}</button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                <BottomSuccessModal
                    isOpen={isTransferSuccess}
                    setIsOpen={setIsTransferSuccess}
                    title={"TRANSFER_SUCCESS"}
                    amount={paidAmount}
                    date={transferDate}
                    transactionNumber={transcactionNumber}

                />
                {
                    (getValuesPopup('toWallet') > 0 || currentInput) &&
                    <Numpad currentBalance={typeof (walletList?.find(op => op.productId == getValuesPopup('toWallet'))?.balance) !== "undefined" ? walletList?.find(op => op.productId == getValuesPopup('toWallet'))?.balance : 0} currentInput={currentInput} setCurrentInput={(e) => { setCurrentInput(e) }} />
                }
            </div>}
        </div>
    );
};

export default PlayTechLobby;