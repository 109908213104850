import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Icon from '@mdi/react'
import ApiEngine from "../../util/ApiEngine";
import StyledSelect from "../../components/custom/StyledSelect";
import Input from "../../components/custom/Input";
import PhoneNumber from "../../components/custom/PhoneNumber";
import Numpad from "../../components/custom/Numpad";
import PinInput from 'react-pin-input';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { mdiHistory } from '@mdi/js'
import { Link } from "react-router-dom";
import { Alert } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl, TransactionType, BankingChannel, PanelType } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, createFormBody, numberWithCurrencyFormat } from "../../util/Util";
import Keyboard from 'react-simple-keyboard';
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import 'react-simple-keyboard/build/css/index.css';
import Header from "../../components/custom/Header";

/// <summary>
/// Author: -
/// </summary>
const Withdrawal = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    let _userData = useSelector(state => state.authState.userData);
    let _dispatch = useDispatch();
    const { handleSubmit, register, control, watch, setValue, getValues, errors, clearErrors, reset, trigger } = useForm();
    const {
        handleSubmit: handleVerifySubmit,
        register: registerVerify,
        getValues: getValuesVerify,
        errors: errorsVerify,
        clearErrors: clearErrorsVerify,
        watch: watchVerify,
        setValue: setValueVerify
    } = useForm();

    const {
        handleSubmit: handleFullNameSubmit,
        register: registerFullName,
        getValues: getValuesFullName,
        errors: errorsFullName,
        setValue: setValueFullName
    } = useForm();
    const [bankOption, setBankOption] = useState([]);
    const [memberBankAccounts, setMemberBankAccounts] = useState([]);
    const [bankName, setBankName] = useState('');
    const [remember, setRemember] = useState(false);
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalDate, setWithdrawalDate] = useState();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [currentInput, setCurrentInput] = useState();
    const [member, setMember] = useState("");
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const _COUNTDOWN_PERIOD = 120;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 3000;
    const [sentCode, setSentCode] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const mainForm = useRef(null);
    const [createPinEntry, setCreatePinEntry] = useState(false);
    const [forgetPin, setForgetPin] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [pinEntered, setPinEntered] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);

    const [showBankAccEntry, setShowBankAccEntry] = useState(false);
    const [bankAccEntered, setBankAccEntered] = useState("");
    const [bankAccNumber, setBankAccNumber] = useState("");

    const [showFullnamePopup, setShowFullnamePopup] = useState(false);

    const _MAX_DIGITS_IN_BANK_ACC = 33;

    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);
    const keyboard = useRef();
    const activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, [])

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        try {
            let tempMemberData = {};
            var memberJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);

            if (memberJson[ApiKey._API_SUCCESS_KEY]) {
                setMember(memberJson[ApiKey._API_DATA_KEY]);
                tempMemberData = memberJson[ApiKey._API_DATA_KEY];
                //setphoneNumber(memberJson[ApiKey._API_DATA_KEY]['phoneNumber']);
            }
            else {
                throw memberJson[ApiKey._API_MESSAGE_KEY];
            }

            if (stringIsNullOrEmpty(tempMemberData['userFullName'])) {
                setShowFullnamePopup(true);
            }
            else {
                let params = {};
                let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
                if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                    var theInterval = setInterval(() => {
                        if (elePin['current'] == undefined)
                            return;

                        if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                            elePin['current']['elements'][0].focus();
                        }
                        else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                            elePin['current']['elements'][1].focus();
                        }
                        else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                            elePin['current']['elements'][2].focus();
                        }
                        else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                            elePin['current']['elements'][3].focus();
                        } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                            eleConfirm['current']['elements'][0].focus();
                        }
                        else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                            eleConfirm['current']['elements'][1].focus();
                        }
                        else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                            eleConfirm['current']['elements'][2].focus();
                        }
                        else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                            eleConfirm['current']['elements'][3].focus();
                        }
                        else {

                            var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                            var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                            if (currpin != currconfpin) {
                                setInvalidPin(true);
                                elePin['current'].clear();
                                eleConfirm['current'].clear();
                                elePin['current'].focus();
                            }
                            else {
                                setInvalidPin(false);
                                clearInterval(theInterval);
                                submitPin(currpin, currconfpin);
                            }
                        }

                    }, 50);
                    setTheInterval(theInterval);
                    setCreatePinEntry(true);
                }
            }

            let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_BANK + "?type=WITHDRAWAL");
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let bankList = [];
                responseJson[ApiKey._API_DATA_KEY].map((bank) => {
                    bankList.push({ label: bank.bankName, value: bank.id, min: bank.minimumWithdrawal, max: bank.maximumWithdrawal });
                });
                setBankOption(bankList);
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            getMemberBankAccount();
            _dispatch(checkIsLoggedIn());
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author: 
    /// </summary>
    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

    /// <summary>
    /// Author: 
    /// </summary>
    const sendVerificationCode = async () => {
        await ApiEngine.post(ApiUrl._API_SEND_VERIFICATION_CODE, createMultiPartFormBody({ phoneNumber: formattedPhoneNumber }))
            .then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    setSentCode(true);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                }
            })
    }

    /// <summary>
    /// Author: 
    /// </summary>
    const verifyPhoneNumber = async () => {
        await ApiEngine.post(ApiUrl._API_VERIFY_PHONE_NUMBER, createMultiPartFormBody({ verificationCode: getValuesVerify('verificationCode') }))
            .then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    setShowVerifyPhone(false);
                }

                _dispatch(showResponseMessage(response[ApiKey._API_SUCCESS_KEY], response[ApiKey._API_MESSAGE_KEY]));
            })
    }

    /// <summary>
    /// Author : -
    /// get saved member bank account
    /// </summary>
    async function getMemberBankAccount() {
        try {
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let bankAccountList = [];
                responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
                    bankAccountList.push({
                        label: bankAccount.bankName + " - " + bankAccount.accountNumber,
                        value: bankAccount.id,
                        bankId: bankAccount.bankId,
                        accountHolderName: bankAccount.accountHolderName,
                        accNum: bankAccount.accountNumber,
                        bankName: bankAccount.bankName
                    });
                });
                setMemberBankAccounts(bankAccountList);
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    function setMinMax(e) {
        setMin(e.min);
        setMax(e.max);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    function resetFormField() {
        setValue("bankId", "");
        setRemember(false);
        setValue("memberBankAccount", "");
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function submitPin(pin, conf) {
        _dispatch(setBusy());
        let params = {
            "pinNumber": pin,
            "confirmPinNumber": conf
        };
        let responseJson = await ApiEngine.post(ApiUrl._API_SET_MEMBER_PIN_NUMBER, createFormBody(params));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCreatePinEntry(false);
        }
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

        elePin["current"].clear();
        eleConfirm["current"].clear();
        setPinNumber("");
        setEnteredPinNumber("");
        setPinNumberFocused(0);
        setPinNumberConfirm("");
        setEnteredPinNumberConfirm("");
        setPinNumberConfirmFocused(0);

        _dispatch(setIdle());
    }

    async function submitWithdrawal(data, e, pin) {
        _dispatch(setBusy());
        let params = {
            "transactionTypeId": TransactionType._WITHDRAWAL,
            "pinNumber": pin,
            "amount": data.amount,
            "channel": "",
            "panel": PanelType._MEMBERSITE,
            "bankName": bankName,
            "bankAccountNumber": data.bankAccountNumber,
            "bankAccountHolderName": member['userFullName']
        };

        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_TRANSACTION, createFormBody(params));
        let message = responseJson[ApiKey._API_MESSAGE_KEY];
        let isSuccess = responseJson[ApiKey._API_SUCCESS_KEY];

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowPinEntry(false);
            setWithdrawalAmount(data.amount);
            setIsWithdrawalSuccess(true);
            setWithdrawalDate(moment().format("DD MMM YYYY, hh:mm A"));
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
            resetFormField();
            e.target.reset();
            _dispatch(checkIsLoggedIn());

            if (remember) {
                let saveAccountParams = {
                    "bankId": data.bankId,
                    "accountNumber": data.bankAccountNumber,
                    "accountHolderName": data.bankAccountHolderName
                }
                let saveResponseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_BANK_ACCOUNT, createFormBody(saveAccountParams));

                if (saveResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    getMemberBankAccount();
                }
                else {
                    message = t("TRANSACTION_CREATED_BUT_SAVE_FAILED") + "\n" + saveResponseJson[ApiKey._API_MESSAGE_KEY];
                    isSuccess = false;
                }
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        setPinEntered(false);
        setPinNumberFinal('');
        setEnteredPinNumberFinal('');
        setPinNumberFinalFocused(0);
        setShowPinEntry(false);
        //_dispatch(showResponseMessage(isSuccess, message));
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function onSubmit(data, e) {
        let phoneJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_PHONE_VERIFIED);
        if (!phoneJson[ApiKey._API_SUCCESS_KEY]) {
            setShowVerifyPhone(true);
            return;
        }
        else {
            if (!pinEntered) {
                setShowPinEntry(true);
            }
        }
        if (pinEntered && pinNumberFinal !== '') {
            submitWithdrawal(data, e, pinNumberFinal);
        }
    }

    async function submitFullName(data) {
        let params = {
            "userFullName": data.userFullName,
            "id": member["id"]
        };
        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER, createFormBody(params));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            setShowFullnamePopup(false);
            let params = {};
            let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
            if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                var theInterval = setInterval(() => {
                    if (elePin['current'] == undefined)
                        return;

                    if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                        elePin['current']['elements'][0].focus();
                    }
                    else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                        elePin['current']['elements'][1].focus();
                    }
                    else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                        elePin['current']['elements'][2].focus();
                    }
                    else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                        elePin['current']['elements'][3].focus();
                    } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                        eleConfirm['current']['elements'][0].focus();
                    }
                    else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                        eleConfirm['current']['elements'][1].focus();
                    }
                    else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                        eleConfirm['current']['elements'][2].focus();
                    }
                    else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                        eleConfirm['current']['elements'][3].focus();
                    }
                    else {

                        var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                        var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                        if (currpin != currconfpin) {
                            setInvalidPin(true);
                            elePin['current'].clear();
                            eleConfirm['current'].clear();
                            elePin['current'].focus();
                        }
                        else {
                            setInvalidPin(false);
                            clearInterval(theInterval);
                            submitPin(currpin, currconfpin);
                        }
                    }

                }, 50);
                setTheInterval(theInterval);
                setCreatePinEntry(true);
            }
        }
        init();
    }

    async function sendPinCode() {
        if (countdownPeriod < 1) {
            let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_PHONE_VERIFIED);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    "phoneNumber": hpNum,
                    "isShareholder": "false"
                };
                let smsJson = await ApiEngine.post(ApiUrl._API_SEND_PIN_CODE, createFormBody(params));
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                }
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT])
        }
    }

    function onCreatePinKeyPress(key) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (key !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                elePin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumber(pinStr);
                }
            }
            else {
                currentIndex = pinNumberConfirmFocused;
                eleConfirm['current']['elements'][currentIndex].state.value = key;
                pinConfirmStr += key;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex <= 3) {
                    eleConfirm['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberConfirm(pinConfirmStr);
                    submitPin(pinNumber, pinConfirmStr);
                }
            }
        }
        else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin['current']['elements'][currentIndex].focus();
        }
    }

    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;
        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            if (key === "{enter}") {
                if (pinEntered) {
                    setShowPinEntry(false);
                }
            }
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                eleFinalPin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    eleFinalPin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberFinal(pinStr);
                    setPinEntered(true);
                }
            }
        }
        else {
            eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            eleFinalPin['current']['elements'][currentIndex].focus();
        }
    }

    function onBankAccKeyPress(key) {
        let bankAccStr = bankAccEntered;
        if (key !== "{bksp}") {
            if (bankAccStr.length < _MAX_DIGITS_IN_BANK_ACC) {
                bankAccStr += key;
                setBankAccEntered(bankAccStr);
                setBankAccNumber(bankAccStr);
                if (bankAccStr.length === _MAX_DIGITS_IN_BANK_ACC) {
                    setTimeout(() => {
                        setShowBankAccEntry(false);
                    }, 1000);
                }
            }
        }
        else {
            bankAccStr = bankAccStr.slice(0, -1);
            setBankAccEntered(bankAccStr);
            setBankAccNumber(bankAccStr);
        }
    }

    return (
        <><Header history={_history} url={WebUrl._URL_DASHBOARD} title={t('WITHDRAWAL')} hasBackButton={false}/>
        <div className="col-xs-12">
        <div id={activeTheme === 0 ? "withdrawal" : "withdrawal-modern"} className="deposit-inner-panel deposit-part" style={{ paddingBottom: '30px' }}>
            <Link to={WebUrl._URL_WITHDRAWAL_RECORD}>
                <div className="d-flex justify-content-end align-items-center withdrawal-record-btn">
                    <div style={{ marginRight: "5px" }}> {t("WITHDRAWAL_RECORD")} </div>
                    <Icon path={mdiHistory}
                        title="User Profile"
                        size={1}
                        color={activeTheme === 0 ? "#002C68" : "#ffffff"} />
                </div>
            </Link>
            {!showPinEntry &&
                <div className="card-brand" style={{ backgroundColor: '#fff' }}>
                    <div className="member-card d-flex justify-content-between m-0">
                        <div className="text-brand text-bold">{t("CASH")}</div>
                        <div className="d-flex align-items-center" >
                            <span className="text-yellow currency-left"></span>
                            <strong className="text-brand ml-2" >{numberWithCurrencyFormat(parseFloat(_userData.walletBalance), 3)}</strong>
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-xs-12">
                    <form onSubmit={handleSubmit(onSubmit)} ref={mainForm}>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("SAVED_BANK_ACCOUNT")}</b></label>
                            <div>
                                <Controller
                                    control={control}
                                    name="memberBankAccount"
                                    defaultValue={""}
                                    render={({ onChange, value }) => {
                                        return (
                                            <StyledSelect options={memberBankAccounts}
                                                placeholder={t("YOU_MAY_CHOOSE_HERE_TO_SELECT_SAVED_BANK_ACCOUNT")}
                                                value={value}
                                                onChange={(e) => {
                                                    setRemember(false);
                                                    setBankName(e.bankName);
                                                    setBankAccNumber(e.accNum);
                                                    setValue("bankAccountNumber", e.accNum);
                                                    setValue("bankAccountHolderName", e.accountHolderName)
                                                    setValue("bankId", e.bankId);
                                                    setMinMax({ min: bankOption.find(b => b.value == e.bankId)?.min, max: bankOption.find(b => b.value == e.bankId)?.max });
                                                    onChange(e.value);
                                                    trigger();
                                                }} />
                                        )
                                    }} />
                                {errors.memberBankAccount && <div className="invalid-feedback">{t(errors.memberBankAccount.message)}</div>}
                            </div>
                        </div>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("BANK")}</b></label>
                            <div>
                                <Controller
                                    control={control}
                                    name="bankId"
                                    defaultValue={""}
                                    render={({ onChange, value }) => {
                                        return (
                                            <StyledSelect options={bankOption}
                                                isDisabled={!stringIsNullOrEmpty(watch("memberBankAccount"))}
                                                placeholder={t("PLEASE_SELECT_BANK")}
                                                value={value}
                                                onChange={(e) => {
                                                    setBankName(e.label);
                                                    onChange(e.value);
                                                    setMinMax(e);
                                                }} />
                                        )
                                    }}
                                    rules={{
                                        required: "PLEASE_SELECT_BANK"
                                    }} />
                                {errors.bankId && <div className="invalid-feedback">{t(errors.bankId.message)}</div>}
                            </div>
                        </div>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("BANK_ACCOUNT_NUMBER")}</b></label>
                            <div onClick={() => setShowBankAccEntry(true)}>{console.log(watch("memberBankAccount"))}
                                <input type="tel" readOnly className="form-control" placeholder={t("PLEASE_ENTER_BANK_ACCOUNT_NUMBER")} name="bankAccountNumber"
                                    style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c", backgroundColor: "white" }}
                                    defaultValue={""}
                                    value={bankAccNumber}
                                    ref={register({
                                        required: "PLEASE_ENTER_BANK_ACCOUNT_NUMBER",
                                        pattern: { value: /^[0-9]+$/, message: "INVALID_BANK_ACCOUNT_NUMBER" }
                                    })}
                                />
                                {errors.bankAccountNumber && <div className="invalid-feedback">{t(errors.bankAccountNumber.message)}</div>}
                            </div>
                        </div>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("BANK_ACCOUNT_HOLDER_NAME")}</b></label>
                            <div>
                                <input type="text" readOnly={true} className="form-control" value={member['userFullName']}
                                    style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c" }}
                                />
                            </div>
                        </div>
                        <div className="form-group m-b-15">
                            <label className="col-form-label"><b>{t("AMOUNT")}</b></label>
                            <div>
                                <input type="number" readOnly className="form-control bg-white" placeholder={t("PLEASE_ENTER_AMOUNT")} name="amount"
                                    style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c" }}
                                    onClick={(e) => { setCurrentInput(e.target) }}
                                    ref={register({
                                        required: "PLEASE_ENTER_AMOUNT",
                                        validate: {
                                            minValue: value => parseFloat(value) > 0 && value >= min || 'VALUE_LESS_THAN_MINIMUM',
                                            maxValue: value => parseFloat(value) <= max || 'EXCEED_MAXIMUM_VALUE'
                                        },
                                        max: {
                                            value: _userData.walletBalance,
                                            message: 'PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE'
                                        }
                                    })}
                                />
                                {errors.amount && <div className="invalid-feedback">{t(errors.amount.message)}</div>}
                                <div className="col-md-12"><span className="reminder">* {t("MIN")} {min} / {t("MAX")} {max}</span></div>
                            </div>
                        </div>
                        {stringIsNullOrEmpty(watch("memberBankAccount")) && <div className="m-b-15">
                            <div className="form-group" style={{ marginBottom: "0px" }}>
                                <label className="col-form-label"><b>{t("REMEMBER_BANK")}</b></label>
                            </div>
                            <div style={{ paddingLeft: "15px" }}>
                                <div className="switcher">
                                    <input type="checkbox" name="remember" id="remember"
                                        onChange={(e) => setRemember(e.target.checked)}
                                        value={false}
                                        checked={remember}
                                        ref={register} />
                                    <label htmlFor="remember"></label>
                                </div>
                            </div>
                        </div>}
                        <div style={{ margin: "1rem 0px" }}>
                            <button type="button" onClick={() => { if (!pinEntered) { setShowPinEntry(true); } }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT")}</button>
                        </div>
                        {showPinEntry &&
                            <div>
                                <div className="pin-wrapper">
                                </div>
                                <div className="pin-number">
                                    <button type="button" style={{
                                        alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", float: "right", fontWeight: "bold"
                                    }} onClick={() => { clearInterval(theInterval); setShowPinEntry(false); }} data-dismiss="modal" aria-hidden="true">&times;</button>
                                    <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px", marginLeft: "40px" }}>{t("ENTER_YOUR_PIN")}</strong>
                                    <PinInput style={{ marginBottom: "35px" }}
                                        ref={eleFinalPin}
                                        length={4}
                                        initialValue=""
                                        secret={true}
                                        focus={true}
                                        autoSelect={true}
                                        disabled={showPinEntry}
                                        onChange={(value, index) => {
                                            setPinNumberFinalFocused(index);
                                        }}
                                        type="numeric"
                                        inputMode="number"
                                        inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                        inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                    />
                                    {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

                                    <strong className="text-brand display-value" onClick={() => { setForgetPin(true); sendPinCode(); }}>{t("RESET_PIN")}</strong>
                                    <Keyboard
                                        layout={{
                                            'default': [
                                                "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                            ]
                                        }}
                                        display={{
                                            '{bksp}': "<i class='fas fa-backspace'></i>",
                                        }}
                                        theme={`keyboard keyboard-numpad`}
                                        keyboardRef={r => (keyboard.current = r)}
                                        onKeyPress={onVerifyPinKeyPress}
                                        disableButtonHold
                                        disableCaretPositioning
                                    />
                                    {forgetPin && <p className="text-brand" style={{ textAlign: "center", marginTop: "5px" }}>{t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod} {t("SECOND")}</p>}
                                    {showAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", left: "0", width: "100%" }}>
                                        <div className="container">
                                            <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                                        </div>
                                    </div>}
                                    <button type="submit" style={{ position: "relative", marginTop: "30px", width: "250px" }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT_TRANSACTION")}</button>
                                </div>
                            </div>
                        }
                    </form>
                </div>
            </div>


            {
                //WITHDRAWAL STATUS
            }
            <BottomSuccessModal
                isOpen={isWithdrawalSuccess}
                setIsOpen={setIsWithdrawalSuccess}
                title={"WITHDRAWAL_SUCCESS"}
                amount={withdrawalAmount}
                date={withdrawalDate}
                transactionNumber={transcactionNumber}

            />
            {/*<Modal fade={false} contentClassName="modal-brand modal-bottom modal-reload-success" isOpen={isWithdrawalSuccess} toggle={() => { setIsWithdrawalSuccess(false) }} centered>
                <ModalBody>
                    <div className="text-center">
                        <strong className="reload-title text-yellow mb-3 d-block">{t("WITHDRAWAL_SUCCESS")}</strong>
                        <div className="text-brand text-bo">{t("PAID_DEPOSIT", { amount: withdrawalAmount })}</div>
                    </div>
                    <table className="table text-brand table-reload-detail">
                        <tr>
                            <th>{t("WITHDRAWAL_DATE_TIME")}</th>
                            <th style={{ textAlign: "right" }}>{withdrawalDate}</th>
                        </tr>
                        <tr>
                            <th>{t("REFERENCE_NUMBER")}</th>
                            <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
                        </tr>
                    </table>
                    <button className="btn btn-block btn-brand-gradient" onClick={() => { setIsWithdrawalSuccess(false) }}>{t("DONE")}</button>
                </ModalBody>
            </Modal>*/}

            {
                //VERIFY PHONE
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={showVerifyPhone} centered>
                <ModalBody style={{ minHeight: "30vh" }}>
                    <form onSubmit={handleVerifySubmit(verifyPhoneNumber)}>

                        <button type="button" style={{
                            backgroundColor: "transparent", border: "none", fontSize: "2rem", color: "#002e6c", marginTop: "-3%", marginLeft: "90%", fontWeight: "bold"
                        }} onClick={() => { clearInterval(theInterval); setShowVerifyPhone(false); }} data-dismiss="modal" aria-hidden="true">&times;</button>

                        <div className="display-wrapper withdrawal">
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("PHONE_VERIFICATION")}</strong>
                            {!sentCode || countdownPeriod == 0 ?
                                <strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }}>{t("VERIFY_TO_CONTINUE")}</strong>
                                :
                                <p>{t("VERIFICATION_CODE_SENT")} {t("RESEND_IN")} {countdownPeriod}s</p>
                            }
                            <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
                                <div className="d-flex" style={{ marginTop: "20px", width: "80%" }}>
                                    <PhoneNumber
                                        className="p-0"
                                        mainContainerStyle={{ marginBottom: "0", flex: 1 }}
                                        register={registerVerify}
                                        name={'phoneNumber'}
                                        value={watchVerify('phoneNumber')}
                                        errors={errorsVerify}
                                        onChange={(value, formattedValue) => {
                                            setValueVerify('phoneNumber', value);
                                            setFormattedPhoneNumber(formattedValue);
                                        }} />
                                </div>
                            </div>
                            <div style={{ width: "100%" }}>
                                <button type="button" className="btn btn-block btn-brand-gradient btn-done" onClick={() => sendVerificationCode()} disabled={sentCode ? true : false} style={{ width: "80%" }}>{t("SEND_VERIFICATION_CODE")}</button>
                            </div>

                        </div>
                        {sentCode ?
                            <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                                <div style={{ width: "80%" }}>
                                    <Input
                                        name={"verificationCode"}
                                        placeholder={t("ENTER_VERIFICATION_CODE")}
                                        type="tel"
                                        ref={registerVerify({
                                            required: 'FIELD_REQUIRED',
                                            pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                                        })}
                                        errors={errorsVerify} />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "80%" }}>{t("SUBMIT")}</button>
                                </div>
                            </div>
                            :
                            <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                                <div style={{ width: "80%" }}>
                                    <Input
                                        name={"verificationCode"}
                                        placeholder={t("ENTER_VERIFICATION_CODE")}
                                        type="tel"
                                        ref={registerVerify({
                                            required: 'FIELD_REQUIRED',
                                            pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                                        })}
                                        errors={errorsVerify} readOnly />
                                </div>
                            </div>
                        }

                    </form>
                </ModalBody>
            </Modal>

            {
                //PHONE STATUS
            }
            <Numpad currentBalance={_userData.walletBalance} currentInput={currentInput} setCurrentInput={(e) => { setCurrentInput(e) }} />
            {
                //CREATE NEW PIN
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={createPinEntry} centered>
                <ModalBody>
                    <form>
                        <div className="display-wrapper">
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_PIN")}</strong>
                            <strong className="reload-title mb-3 d-block" style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c" }}>{t("ENTER_YOUR_PIN")}</strong>
                            <PinInput
                                ref={elePin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <strong className="reload-title mb-3 d-block" style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c", marginTop: "30px" }}>{t("CONFIRM_YOUR_PIN")}</strong>
                            <PinInput
                                ref={eleConfirm}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberConfirmFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>
                            <Keyboard
                                layout={{
                                    'default': [
                                        "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                    ]
                                }}
                                display={{
                                    '{bksp}': "<i class='fas fa-backspace'></i>",
                                }}
                                theme={`keyboard keyboard-numpad`}
                                keyboardRef={r => (keyboard.current = r)}
                                onKeyPress={onCreatePinKeyPress}
                                disableButtonHold
                                disableCaretPositioning
                            />
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {
                //CREATE NEW USER FULL NAME
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showFullnamePopup} centered>
                <ModalBody>
                    <form onSubmit={handleFullNameSubmit(submitFullName)}>
                        <div className="display-wrapper">
                            <strong className="reload-title text-yellow d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_FULL_NAME")}</strong>
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{t("IN_YOUR_BANK_ACCOUNT")}</strong>
                            <input
                                type="text"
                                name="userFullName"
                                className="withdrawal-fullName-input"
                                ref={registerFullName({
                                    required: 'FIELD_REQUIRED',
                                    pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" }
                                })}
                                onChange={(e) => setValueFullName('userFullName', e.target.value)}
                            />
                            {errorsFullName.userFullName && <div className="invalid-feedback">{t(errorsFullName.userFullName.message)}</div>}
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "200px" }}>{t("SUBMIT")}</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {
                //BANK ACCOUNT NUMBER KEYPAD
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showBankAccEntry} centered>
                <ModalBody>
                    <div className="display-wrapper">
                        <button type="button" style={{
                            alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", fontWeight: "bold",
                        }} onClick={() => setShowBankAccEntry(false)} data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
                        <strong style={{ color: "#002E6C" }}>{t("BANK_ACCOUNT_NUMBER")}</strong>
                        {bankAccEntered.length !== 0 ? <strong className="text-brand display-value">{bankAccEntered}</strong> : <strong className="text-brand display-value">&nbsp;</strong>}
                    </div>
                    <Keyboard
                        layout={{
                            'default': [
                                "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                            ]
                        }}
                        display={{
                            '{bksp}': "<i class='fas fa-backspace'></i>",
                        }}
                        theme={`keyboard keyboard-numpad`}
                        keyboardRef={r => (keyboard.current = r)}
                        onKeyPress={onBankAccKeyPress}
                        disableButtonHold
                        disableCaretPositioning
                    />
                    <div style={{ marginTop: "25px" }}>
                        <button type="button" className="btn btn-block btn-brand" onClick={() => setShowBankAccEntry(false)}>{t("SUBMIT")}</button>
                    </div>
                </ModalBody>
            </Modal>
        </div></div></>
    );
}

export default Withdrawal;