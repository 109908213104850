import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  BankingChannel,
  PanelType,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import ReactHtmlParser from "react-html-parser";
import Numpad from "../../components/custom/Numpad";

/// <summary>
/// Author: -
/// </summary>
const SPGDeposit = (props) => {
  const _RECEIPT_REFERENCE = "Instant Pay";
  const { t, i18n } = useTranslation();
  let _history = useHistory();
  let _userData = useSelector((state) => state.authState.userData);
  let _dispatch = useDispatch();
  const { handleSubmit, register, control, watch, setValue, errors, reset } =
    useForm();
  const [bankOption, setBankOption] = useState([]);
  const [maxDeposit, setMaxDeposit] = useState(0);
  const [minDeposit, setMinDeposit] = useState(0);
  const [serviceCharge, setServiceCharge] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [gateways, setGateways] = useState(props.gatewayOption);
  const [paymentOption, setPaymentOption] = useState(props.paymentOption);
  const [defaultBank, setDefaultBank] = useState(0);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    console.log(props.gatewayOption);
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (gateways["instantBankSettingModel"]) {
      let bankList = [];
      setValue("bankId", gateways["instantBankSettingModel"][0].id);
      //setDefaultBank(gateways["instantBankSettingModel"][0].id);
      setMaxDeposit(gateways["instantBankSettingModel"][0].maximumDeposit);
      setMinDeposit(gateways["instantBankSettingModel"][0].minimumDeposit);
      gateways["instantBankSettingModel"].map((bank) => {
        bankList.push({ label: bank.name, value: bank.id, data: bank });
      });
      setBankOption(bankList);
      setServiceCharge(gateways["serviceCharge"]);
    } else {
      _history.goBack();
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function onSubmit(data, e) {
    console.log("bankId:" + data.bankId);
    _dispatch(setBusy());
    var bankData = bankOption.filter(
      (option) => option.value == data.bankId
    )[0];

    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      bankId: data.bankId,
      amount: data.amount,
      bankCode: bankData.data.bankCode,
      channel: gateways["instantPayGatewayId"],
      panel: PanelType._MEMBERSITE,
      instantPayBankSettingId: bankData.data.id,
      currency: bankData.data.currency,
      instantPay: true,
      receiptReference:
        _RECEIPT_REFERENCE + "(" + gateways["instantPayGatewayString"] + ")",
      bankAccountId: bankData.data.bankId,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_TRANSACTION,
      createMultiPartFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setBusy(true, t("PLEASE_WAIT_REDIRECTING")));

      e.target.reset();
      props.setTriggerParentInit(true);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "payment",
            url: responseJson[ApiKey._API_DATA_KEY]["redirect_to"],
          })
        );
      } else {
        window.location.href =
          responseJson[ApiKey._API_DATA_KEY]["redirect_to"];
      }
      _dispatch(setIdle());
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }

  return (
    <>
      <div>
        {parseFloat(serviceCharge) !== 0 && (
          <div className="col-xs-12">
            <div
              className="deposit-note reminder"
              //   style={{ margin: "-12px 0 12px 0" }}
            >
              {t("SERVICE_CHARGE_DISCLAIM") +
                serviceCharge +
                "%" +
                t("SERVICE_CHARGE_DISCLAIM_BACK")}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xs-12">
          <form class="deposit-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group m-b-15">
              <label className="col-form-label">
                <b>{t("BANK")}</b>
              </label>
              <div>
                <Controller
                  control={control}
                  name="bankId"
                  defaultValue=""
                  render={({ onChange, value }) => {
                    return (
                      <StyledSelect
                        options={bankOption}
                        placeholder={t("PLEASE_SELECT_BANK")}
                        value={value}
                        onChange={(e) => {
                          var bankData = bankOption.filter(
                            (option) => option.value == e.value
                          )[0];
                          setMaxDeposit(bankData.data.maximumDeposit);
                          setMinDeposit(bankData.data.minimumDeposit);
                          onChange(e.value);
                        }}
                      />
                    );
                  }}
                  rules={{ required: "PLEASE_SELECT_BANK" }}
                />
                {errors.bankId && (
                  <div className="invalid-feedback">
                    {t(errors.bankId.message)}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group m-b-15">
              <label className="col-form-label">
                <b>{t("AMOUNT")}</b>
              </label>
              <div>
                <input
                  type="number"
                  readOnly
                  className="form-control bg-white amount-text"
                  placeholder={t("PLEASE_ENTER_AMOUNT")}
                  name="amount"
                  style={{
                    fontSize: "14px",
                    color: "#002e6c",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => {
                    setCurrentInput(e.target);
                  }}
                  ref={register({
                    required: "PLEASE_ENTER_AMOUNT",
                    validate: {
                      minValue: (value) =>
                        (parseFloat(value) > 0 && value >= minDeposit) ||
                        "VALUE_LESS_THAN_MINIMUM",
                      maxValue: (value) =>
                        parseFloat(value) <= maxDeposit ||
                        "EXCEED_MAXIMUM_VALUE",
                    },
                  })}
                />
                {errors.amount && (
                  <div className="invalid-feedback">
                    {t(errors.amount.message)}
                  </div>
                )}
                {minDeposit != 0 && maxDeposit != 0 && (
                  <div className="col-md-12">
                    <span className="reminder">
                      * Min: {minDeposit}/ Max: {maxDeposit}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div style={{ margin: "1rem 0px" }}>
              <button
                type="submit"
                className="btn btn-block btn-brand-gradient"
              >
                {t("SUBMIT")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Numpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          setCurrentInput(e);
        }}
      />
    </>
  );
};

export default SPGDeposit;
