import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useForm, Controller } from "react-hook-form";
import { Nav, NavItem, NavLink } from "reactstrap";
import { createMultiPartFormBody } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";

const UnclaimCommissionHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _userData = useSelector((state) => state.authState.userData);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);
  const [data, setData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");

  const { handleSubmit, control, watch, setValue } = useForm();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [queryProduct, setQueryProduct] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  const [memberData, setMemberData] = useState({});
  const [unclaimComm, setUnclaimComm] = useState("");

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        data.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    setMemberData(responseJson[ApiKey._API_DATA_KEY]);
    getUnclaimCommHistory();

    _dispatch(setIdle());
  }

  const getUnclaimCommHistory = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_COMMISSION,
      createMultiPartFormBody({})
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  return (
    <>
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("Confirm Claim?")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_DASHBOARD : null}
        title={t("COMMISSION_DASHBOARD")}
      />
      <div
        id={
          _userData.selectedTheme === 0 ? "comm-history" : "comm-history-modern"
        }
        className="container mt-3"
        style={{ paddingBottom: "10px" }}
      >
        <div className="card kpi-header-widget mb-2">
          <div className="card-body">
            <div className="user-detail">
              <div>{memberData["username"]}</div>
              <div>{memberData["phoneNumber"]}</div>
            </div>
            <div></div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="claim-title title-md text-yellow">
            {t("CURRENT_BALANCE")}
          </div>
          <Link to={WebUrl._URL_COMMISSION_HISTORY}>
            <div className="d-flex justify-content-end align-items-center comm-history-btn">
              <div style={{ marginRight: "5px", fontSize: "12px" }}>
                {" "}
                {t("COMMISSION_HISTORY")}{" "}
              </div>
              <Icon
                path={mdiHistory}
                title="User Profile"
                size={1}
                color={_userData.selectedTheme === 0 ? "#002C68" : "#ffffff"}
              />
            </div>
          </Link>
        </div>
        <div className="downline-stat-wrapper">
          <div>
            <div className="card-brand text-center text-bold">
              {unclaimComm == ""
                ? "Loading..."
                : " " +
                  numberWithCurrencyFormat(
                    parseFloat(unclaimComm.totalClaimable),
                    3
                  )}
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="btn mt-2 btn-block btn-brand-gradient mb-4"
              disabled={unclaimComm == "" || unclaimComm["totalClaimable"] == 0}
              onClick={() => unclaimComm["totalClaimable"] != 0 && preSubmit()}
            >
              {t("CLAIM")}
            </button>
          </div>
        </div>
        <div className="claim-title title-md text-yellow">
          {t("CURRENT_SUMMARY")}
        </div>
        {unclaimComm["data"] && unclaimComm["data"].length > 0 && (
          <div className="card card-package">
            <div className="card-body p-0">
              <div className="card-table table-responsive">
                <table className="table table-kpi-summary comm-summary-table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>{t("DATE")}</th>
                      <th style={{ textAlign: "center" }}>{t("MY_COMM")}</th>
                      <th style={{ textAlign: "center" }}>
                        {t("DOWNLINE_COMM")}
                      </th>
                      <th style={{ textAlign: "right" }}>{t("CLAIMABLE")}</th>
                      <th style={{ textAlign: "center" }}>{t("STATUS")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unclaimComm["data"].map((row, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ textAlign: "left" }}>
                            {moment(row["commDate"]).format("YYYY-MM-DD")}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(row["ownComm"]),
                              3,
                              true
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(row["downlineComm"]),
                              3,
                              true
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(row["totalComm"]),
                              3,
                              true
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <span
                              className="badge badge-no"
                              style={{ fontSize: "8px" }}
                            >
                              {t("UNCLAIMED")}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {unclaimComm["data"] && unclaimComm["data"].length == 0 && (
          <div className="card card-package">
            <div className="card-body p-0">
              <div className="card-table table-responsive">
                <table className="table table-kpi-summary comm-summary-table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>{t("DATE")}</th>
                      <th style={{ textAlign: "center" }}>{t("MY_COMM")}</th>
                      <th style={{ textAlign: "center" }}>
                        {t("DOWNLINE_COMM")}
                      </th>
                      <th style={{ textAlign: "right" }}>{t("CLAIMABLE")}</th>
                      <th style={{ textAlign: "center" }}>{t("STATUS")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="5" style={{ textAlign: "left" }}>
                        No unclaimed data.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UnclaimCommissionHistory;
