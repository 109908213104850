import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import Header from "../../components/custom/Header";
import { setBusy, setIdle, showResponseMessage, setDownlineSearchText, setDownlineQuery, setPageSize, setY } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import moment from "moment";
import Icon from '@mdi/react'
import { mdiEye, mdiEyeOff } from '@mdi/js';

/// <summary>
/// Author: -
/// </summary>
const Downline = props => {
    var { t } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();

    const [searchDownlineKeyword, setSearchDownlineKeyword] = useState('');
    const [memberData, setMemberData] = useState({});
    const [downlines, setDownlines] = useState([]);
    let _userData = useSelector(state => state.authState.userData);
    let _savedData = useSelector(state => state.appState.savedText);
    let _savedQuery = useSelector(state => state.appState.savedQuery);
    let _savedPageSize = useSelector(state => state.appState.pageSize);
    const [page, setPage] = useState(0);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        try {
            await getMemberDetails();
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getMemberDetails() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMemberData(responseJson[ApiKey._API_DATA_KEY]);
            console.log(props);
            if (("" + responseJson[ApiKey._API_DATA_KEY]["hideSummary"]) !== (props.hideSummary)) {
                _dispatch(showResponseMessage(false, "Error"));
                _history.push(WebUrl._URL_DASHBOARD);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    function getText(e) {
        _dispatch(setDownlineSearchText(e.target.value));
        _dispatch(setDownlineQuery(ApiUrl._API_GET_USER_MEMBER_LIST + "?keyword=" + e.target.value + "&directUserOnly=true"));
        _dispatch(setPageSize(10));
        _dispatch(setY(0));
    }

    return (
        <>
            <div id={_userData.selectedTheme === 0 ? "shareholder-downline" : "shareholder-downline-modern"} className="container mt-3" style={{ paddingBottom: "35px" }}>
                <div className="card kpi-header-widget mb-3">
                    <div className="card-body">
                        <div className="user-detail">
                            <div style={{marginBottom:"10px"}}>{memberData["username"]}</div>
                            <div>{memberData["phoneNumber"]}</div>
                        </div>
                        <div>
                            <div style={{ textAlign: "center", fontSize: "10px", marginBottom: "8px" }}>{moment(memberData['shareHolderDate']).format("DD/MM/YYYY")}</div>
                            <span style={{ fontSize: "10px" }} className={memberData["isShareHolder"] === 1 ? "badge badge-secondary" : "badge badge-isShareHolder"}>{t("SHAREHOLDERS")}</span>
                        </div>
                    </div>
                </div>
                <Input placeholder={t("ALIAS_NAME_PHONE")} defaultValue={_savedData} onInput={(e) => { getText(e) }}
                    inputGroupType="append" inputGroupContent={<i className="fas fa-search" style={{ color: "#FFC159", fontSize: "18px" }}></i>} />
                <p className="f-w-800 text-brand">{t("DOWNLINES")}</p>
                <ul className="ul-news-list">
                    <DynamicLoadTable pageSize={_savedPageSize} apiQuery={_savedQuery} searchEnabled={true} autoscrollEnabled={true} render={(downline, i) => {
                        return <li key={i}>
                            <Link to={{ pathname: WebUrl._URL_SHAREHOLDER_DETAIL, scrollIndex: downline['dataLength'], state: { id: downline['id'], hideSummary: props.hideSummary } }}>
                                <div>
                                    <strong className="d-block" >{downline['username']} - {downline['fullname']}</strong>
                                    {downline['nickname'] != '' && <small className="d-block" >({downline['nickname']})</small>}
                                    <span style={{ fontSize: 'small' }}>{downline['phoneNumber']}</span>
                                </div>
                                {downline['isShareHolder'] == 1 ? <>
                                    <span className="badge badge-isShareHolder" style={{ display: "flex", alignItems: "center", minWidth: "135px", justifyContent: "center" }}>
                                        {t("SHAREHOLDERS")}
                                        <div style={{ marginLeft: downline.hideSummary === true ? "10px" : "0" }}>
                                            {downline.hideSummary === true &&
                                                <Icon path={mdiEyeOff} size={0.7} color="#002e6c" />
                                            }
                                        </div>
                                    </span>
                                </> :
                                    <i className="fas fa-chevron-right"></i>
                                }
                                
                            </Link>
                        </li>
                    }} />
                </ul>
            </div>
        </>
    );

}

export default Downline;