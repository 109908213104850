import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody, isObjectEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { showResponseMessage, setBusy, setIdle, setActiveTab } from "../../redux/AppAction";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

/// <summary>
/// Author : 
/// </summary>
const DownlineGroupDetail = props => {
    const { t, i18n } = useTranslation();
    const _ALERT_TIMEOUT = 2000;
    let _history = useHistory();
    let _location = useLocation();
    const _dispatch = useDispatch();
    let _userData = useSelector(state => state.authState.userData);
    let _savedPageSize = useSelector(state => state.appState.pageSize);
    const [downlineGroupDetail, setDownlineGroupDetail] = useState([]);
    const [selectedDownlineMembers, setSelectedDownlineMembers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [initCheck, setInitCheck] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [memberListInGroup, setMemberListInGroup] = useState([]);
    const [memberGroupQuery, setMemberGroupQuery] = useState("");
    const [showSubmitBtn, setShowSubmitBtn] = useState(false);

    const { register, handleSubmit, errors, setValue, control, watch } = useForm();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_location.state && _location.state.id >= 0) {
            setMemberGroupQuery(ApiUrl._API_GET_UNGROUP_MEMBER_LIST + "?groupId=" + _location.state.id);
        }
        init();
        _dispatch(setActiveTab(1));
        setInitCheck(true);
    }, [_location.state]);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        getGroupDetail();
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getGroupDetail() {
        let apiUrl = ApiUrl._API_GET_DOWNLINE_GROUP_DETAIL_BY_ID;

        if (_location.state && _location.state.id >= 0) {
            apiUrl += "?groupId=" + _location.state.id;
        }

        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            if (data !== null) { setDownlineGroupDetail(data); }
            if (data === null || data['name'] !== 'Default') { setShowSubmitBtn(true); }
        }
    }

    const addMemberIntoGroup = async (member, e) => {
        let params = { 'memberGuid': member['id'], 'removeMember': !e.target.checked };
        let formBody = createFormBody(params);
        let apiUrl = ApiUrl._API_ADD_OR_REMOVE_MEMBER_INTO_GROUP;
        if (_location.state && !stringIsNullOrEmpty(_location.state.id)) {
            apiUrl += "?groupId=" + _location.state.id;
        }
        let responseJson = await ApiEngine.post(apiUrl, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setAlertMsg(member['username'] + " " + t("SUCCESSFULLY_" + responseJson[ApiKey._API_MESSAGE_KEY] + "_GROUP"));
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data) => {
        let params = { 'groupName': data.groupName};
        let formBody = createFormBody(params);
        let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_DOWNLINE_GROUP;
        if (!stringIsNullOrEmpty(_location.state && _location.state.id)) {
            apiUrl += "?groupId=" + _location.state.id;
        }
        let responseJson = await ApiEngine.post(apiUrl, formBody);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setModalVisible(false);

            if (!downlineGroupDetail || !downlineGroupDetail.length) {
                _history.replace({
                    pathname: _location.pathname,
                    state: {
                        id: responseJson[ApiKey._API_DATA_KEY]["id"]
                    }
                })
            }
            else {
                init();
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const DownlineGroupDetailForm = () => {
        return (
            <form>
                <div className="row" style={{ marginBottom: "1em" }}>
                    <div className="col-lg-12 ">
                        <label className="col-md-4 col-form-label p-0" style={{ marginBottom: "10px", color: "#002E6C" }}>{t("GROUP_NAME")}</label>
                        <div className="col-md-7 p-0">
                            <Input name="groupName"
                                defaultValue={downlineGroupDetail && initCheck ? downlineGroupDetail.name : ""}
                                ref={register()}
                                placeholder={t("ENTER_GROUP_NAME")}
                                readonly={!isObjectEmpty(downlineGroupDetail) && downlineGroupDetail.name === "Default" ? "readonly" : false}
                            />
                        </div>
                        {showSubmitBtn &&
                            <div style={{ margin: "1rem 0px" }}>
                                <button type="button" className="btn btn-block btn-brand" disabled={downlineGroupDetail && initCheck && downlineGroupDetail.name && downlineGroupDetail.name === "Default" ? true : false} onClick={() => handleSubmit(submitForm)()}>{t("SUBMIT")}</button>
                            </div>
                        }
                    </div>
                </div>
            </form>
        )
    }

    const checkboxLabel = (i) => {
        if (downlineGroupDetail && initCheck && downlineGroupDetail.name && downlineGroupDetail.name !== "Default") {
            return (
                <label htmlFor={"check-for-member-" + i} className="downline-group-checkbox">
                    <span style={{ position: "relative" }}>
                        <img className="checked-icon" src={require("../../assets/img/icon/custom-checkbox-1-checked.svg")} />
                        <img className="unchecked-icon" src={require("../../assets/img/icon/custom-checkbox-1-unchecked.svg")} />
                    </span>
                </label>
            )
        }
    }

    /// <summary>
    /// Author : 
    /// </summary>
    return (
        <div>
            <Header history={_history} url={WebUrl._URL_DOWNLINE} title={t('DOWNLINE_GROUP_DETAIL')}/>
            <div id={_userData.selectedTheme === 0 ? "downline-group" : "downline-group-modern"} className="container mt-3" style={{ paddingBottom: '30px' }}>
                {DownlineGroupDetailForm()}
                <div><p className="f-w-800 text-brand">{t("DOWNLINES")}</p></div>
                {_location.state && _location.state.id >= 0 && initCheck ? <>
                    <ul className="ul-news-list">
                        <DynamicLoadTable pageSize={_savedPageSize} apiQuery={memberGroupQuery} searchEnabled={true} autoscrollEnabled={true} render={(downline, i) => {
                            return <li key={i}>
                                <div>
                                    <strong className="d-block" style={{ color: "#002E6C" }}>{downline['username']} - {downline['fullname']}</strong>
                                    {downline['nickname'] != '' && <small className="d-block" style={{ color: "#002E6C" }}>({downline['nickname']})</small>}
                                    <span style={{ fontSize: 'small', color: "#002E6C" }}>{downline['phoneNumber']}</span>
                                </div>
                                <input
                                    type="checkbox"
                                    id={"check-for-member-" + i}
                                    className="input-checkbox-custom"
                                    defaultChecked={downline["checkIfMemberIsInGroup"][i]['memberIsInGroup']}
                                    onChange={(e) => addMemberIntoGroup(downline, e)}
                                />
                                {!isObjectEmpty(downlineGroupDetail) && downlineGroupDetail.name !== "Default" && <label htmlFor={"check-for-member-" + i} className="downline-group-checkbox">
                                    <span style={{ position: "relative" }}>
                                        <img className="checked-icon" src={require("../../assets/img/icon/custom-checkbox-1-checked.svg")} />
                                        <img className="unchecked-icon" src={require("../../assets/img/icon/custom-checkbox-1-unchecked.svg")} />
                                    </span>
                                </label>}
                            </li>
                        }} />
                    </ul>
                </>
                    :
                <>
                    <div className="no-group-name">{t("SAVE_GROUP_NAME_FIRST")}</div>
                </>}
            </div>
            {showAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", width: "100%" }}>
                <div className="container">
                    <Alert color="green">{t(alertMsg)}</Alert>
                </div>
            </div>}
        </div>
    )

}

export default DownlineGroupDetail;